export const en = {
  message: {
    requiredField: 'Required field',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you wish to delete?',
    confirmApproval: 'Are you sure you wish to approve this occurrence?',
    noResultsFor: 'No results for'
  },
  login: {
    username: 'User',
    password: 'Password',
    login: 'Login',
    entrar: 'Log in',
    descricao: 'Enter your access data in the form below:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Back',
    outro: 'Other',
    filtros: 'Filters',
    acesseBaseDados: 'Access the database',
    conhecaNossaRede: 'Latin American network',
    baseDadosNacional: 'National Database',
    politicaDeDados: 'Data policy',
    comiteCientifico: 'Scientific committee',
    colabore: 'Collaborate',
    peixesAquicultura: 'Aquaculture fishes',
    pets: 'Pets',
    especiesExoticas: '42 Especies Exóticas Invasoras en Uruguay',
    especiesPrioritarias: 'Especies invasoras prioritarias de actuación',
    mascotas: 'Mascotas',
    acuarismo: 'Acuarismo',
    forrajeras: 'Especies forrajeras',
    plantasForrageiras: 'Forage plants',
    plantasOrnamentais: 'Ornamental plants',
    usoFlorestal: 'Forestry',
    tutorial: 'Tutorial',
    especies: 'Species',
    colaboradores: 'Collaborators',
    referencias: 'References',
    projetos: 'Projects',
    saibaMais: 'Find out more',
    contida: 'Contained',
    casual: 'Casual',
    estabelecida: 'Established',
    invasora: 'Invasive',
    exibirListagem: 'View List',
    concluir: 'Complete',
    fechar: 'Close',
    próximo: 'Next',
    anterior: 'Former',
    clickAqui: 'Click here',
    numRegistrosEncontrados: 'Number of records found:',
    exibir10Reg: 'Display 10 records',
    exibir25Reg: 'Display 25 records',
    exibir50Reg: 'Display 50 records',
    exibir100Reg: 'Display 100 records',
    limpar: 'Clear',
    buscar: 'Search',
    addColaborador: 'Add collaborator',
    nome: 'Name',
    sobrenome: 'Last name',
    aprovarColaborador: 'Approve collaborator',
    salvarColaborador: 'Save collaborator',
    titulo: 'Title',
    cargo: 'Position',
    especialidade: 'Expertise',
    instituicao: 'Institution',
    endereco: 'Address',
    estado: 'State',
    cidade: 'City',
    pais: 'Country',
    email: 'E-mail address',
    telefone: 'Telephone 1',
    telefone2: 'Telephone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Social media 1',
    midiaSocial2: 'Social media 2',
    midiaSocial3: 'Social media 3',
    observacoesAdicionais: 'Additional observations',
    adicionar: 'Add',
    editando: 'Editing',
    acoes: 'Actions',
    usuarixs: 'Users',
    adicionarUsuario: 'Add user',
    descricao: 'Description',
    ativo: 'Active',
    sim: 'Yes',
    nao: 'No',
    salvarUsuarix: 'Save user',
    senha: 'Password',
    observacoes: 'Observations',
    grupos: 'Groups',
    basico: 'Basic',
    administrator: 'Administrator',
    taxonomia: 'Taxonomy',
    nomeCientifico: 'Latin name',
    adicionarEspecie: 'Add species',
    genero: 'Genus',
    familia: 'Family',
    ordem: 'Order',
    classe: 'Class',
    phylumDivisao: 'Phylum / Division',
    reino: 'Kingdom',
    phylum: 'Phylum',
    origem: 'Origin',
    descricaoInvalida: 'Invalid description',
    selecioneFamilia: 'Please select a Family.',
    selecioneOrdem: 'Please select an Order.',
    selecioneClasse: 'Please select a Class.',
    selecioneDivisao: 'Please select a Phylum.',
    selectioneReino: 'Please select a Kingdom.',
    selectioneGenero: 'Please select a Genus.',
    addReferencia: 'Add reference',
    autoresEX: 'Authors (ex. ZILLER, S. R.; ZALBA, S. M.)',
    aprovarReferencia: 'Approve reference',
    salvarReferencia: 'Save reference',
    autorxsEX: 'Authors (ex. ZILLER, S. R.; ZALBA, S. M.)',
    autores: 'Authors',
    local: 'Location (Ex. Asunción /  África del Sur: Ciudad del Cabo)',
    localFiltro: 'Location',
    edicao: 'Edition (ex. 2 ed.)',
    editora: 'Editor',
    revista: 'Journal',
    anoPublicacao: 'Publication year',
    meioDivulgacao: 'Publication means',
    volume: 'Volume (ex. v. 3)',
    numero: 'Number (ex. n. 28)',
    pagina: 'Pages (ex. p. 25-34 / 245p.)',
    url: 'DOI / URL',
    palavraChave: 'Keywords',
    anoPublic: 'Publication year',
    addProjeto: 'Add project',
    aprovarProjeto: 'Approve project',
    salvarProjeto: 'Save project',
    contato: 'Contact',
    areaGeografica: 'Geographic range',
    objetivos: 'Objectives',
    breveDescricao: 'Brief description',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    comentarios: 'Comments',
    anoInicio: 'Start year',
    anoTermino: 'End year',
    ocorrencias: 'Occurrences',
    exportarDados: 'Export data',
    addOcorrencia: 'Add occurrence',
    visualizarOcorrencia: 'View occurrence',
    alterarOcorrencia: 'Change occurrence',
    ocorrenciasVinculadas: 'Linked occurrences',
    areaProtegida: 'Protected area',
    especie: 'Species',
    aprovarOcorrencia: 'Approve occurrence',
    salvarOcorrencia: 'Save occurrence',
    colaborador: 'Collaborator',
    municipio: 'Municipality',
    municipioSede: 'Municipality (city)',
    municipioCentroide: 'municipality (centroid)',
    referenciaLocal: 'Place reference',
    referencia: 'Reference',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'The coordinates correspond to',
    distanciaAproximada: 'Approximate distance',
    ambiente: 'Environment',
    baciaHidro: 'Basin',
    descricaoInvasao: 'Description of the invasion',
    abundancia: 'Abundance',
    situacaoPopulacional: 'Population status',
    manejo: 'Management',
    dataEntrada: 'Entry date',
    dataObservacao: 'Observation date (ex. 2020 ou 13/05/2020)',
    validado: 'Validated',
    especieMuseuHerbario: 'It is a museum or herbarium specimen',
    selecioneTipo: 'Select the type',
    herbario: 'Herbarium',
    museu: 'Museum',
    numeroExemplaInstituicao: 'Specimen number (Institution)',
    numeroExemplarColetor: 'Specimen number (Collector)',
    selecioneOpcao: 'Select an option',
    pointList1: 'A point of occurrence of one or more individuals of the species',
    pointList2: 'The center of a group of individuals of the species',
    pointList3: 'A point of occurrence at an approximate distance of:',
    coordinatesSourceList1: 'A - A political ou administrative unit',
    coordinatesSourceList2: 'B - The actual site of occurrence',
    ultimoRegistroBaseDados: 'Latest records in database',
    ultimosAcessos: 'Latest access',
    fichaTecnicaEspecie: 'Species factsheet',
    fichaTecnicaEspecieMMA: 'Species factsheet MMA version 1',
    fichaTecnicaEspecieMMA2: 'Species factsheet MMA version 2',
    invasoras: 'Invasive',
    contidasBrasil: 'Contained',
    ausentesBrasil: 'Absent',
    deficiencia: 'Data deficient (DD)',
    formatosDisponiveisExportacao: 'Available formats for export:',
    biologiaEcologia: 'Biology and ecology',
    usoEconomico: 'Economic use',
    usoEconomicoDescricao: 'Economic use - description',
    invasaoBiologica: 'Biological invasion',
    impactos: 'Impacts',
    habitat: 'Habitat',
    nomeCientificoSinonimos: 'Scientific name + synonyms',
    nomesPopulares: 'Common names',
    reproducao: 'Reproduction',
    dispersao: 'Dispersal',
    dieta: 'Diet',
    formaBiologica: 'Biological form',
    ambientesPreferenciaisInvasao: 'Habitats more susceptible to invasion',
    tipoIntroducao: 'Introduction type',
    causaIntroducao: 'Cause of introduction',
    localIntroducao: 'Place of introduction',
    anoIntroducao: 'Year of introduction',
    marinho: 'Marine',
    dulcicola: 'Freshwater',
    terrestre: 'Terrestrial',
    validarEspecie: 'Validate species',
    salvarEspecie: 'Save species',
    data: 'Date',
    confiabilidade: 'Reliability',
    resultadosAnalisesRisco: 'Risk analysis results',
    risco: 'Risk',
    areaOrigem: 'Area of origin',
    areaDistribuicaoNatural: 'Native range',
    ambientesNaturais: 'Natural habitats',
    descricaoEspecie: 'Species description',
    ambientesPreferenciaisInvasaoDescricao: 'Preferred habitat types for invasion - description',
    outrosLocaisOndeEspecieInvasora: 'Other places where the species is invasive',
    impactosEcologicos: 'Ecological impacts',
    impactosEconomicos: 'Economic impacts',
    impactosSociais: 'Social impacts',
    impactosSaude: 'Health impacts',
    categoriaEICAT: 'EICAT category',
    mecanismosImpactoEICAT: 'EICAT impact mechanisms',
    nivelConfiancaAvaliacaoEICAT: 'EICAT assessment confidence level',
    referenciaEICAT: 'EICAT reference',
    dataEICAT: 'EICAT date (e.g. 2021)',
    categoriaSEICAT: 'SEICAT category',
    mecanismosImpactoSEICAT: 'SEICAT impact mechanisms',
    nivelConfiancaAvaliacaoSEICAT: 'SEICAT assessment confidence level',
    referenciaSEICAT: 'SEICAT reference',
    dataSEICAT: 'SEICAT date (e.g. 2021)',
    digitarSomenteAno: 'Enter year only - e.g. 2021',
    viasCDBCategorias: 'CDB pathways - categories',
    viasIntroducao: 'Pathways of introducion and spread',
    viasVetoresCDBSubcategorias: 'CDB pathways - subcategories',
    vetoresIntroducao: 'Vectors of introducion and spread',
    introducao: 'Introduction',
    adicionarIntroducao: 'Add introduction',
    ano: 'Year',
    descricaoIntroducao: 'Introduction description',
    salvar: 'Save',
    cancelar: 'Cancel',
    excluir: 'Delete',
    medidasPreventivas: 'Prevention measures',
    medidasPreventivasMMA: 'Prevention measures MMA',
    medidasDeteccaoPrecoce: 'Early detection measures',
    controleMecanico: 'Mechanical control',
    controleQuimico: 'Chemical control',
    controleBiologico: 'Biological control',
    distribuicaoPais: 'Distribution in the country',
    vejaMapaOcorrencias: 'See occurrence map',
    ecologiaUso: 'Ecology and use',
    introducaoDispersao: 'Introduction and spread',
    analiseRisco: 'Risk analysis',
    nomeComum: 'Common name',
    foto: 'Photograph',
    credito: 'Credit',
    sinonimos: 'Synonyms',
    autor: 'Author',
    subespecie: 'Subspecies',
    autorSubespecie: 'Author of subspecies',
    pendenteAnalise: 'Analysis pending',
    cancelarVoltar: 'Cancel / Back',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    colaboradorxs: 'Collaborators',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomy - Complete list of species',
    consultaEspeciesOrigemAmbientes: 'Species, origin, habitats susceptible to invasion, and impacts',
    consultaEspeciesManejo: 'Species and management information',
    consultaParaGerarFichaTecnica: 'Species factsheet without places of occurrence',
    consultaEspeciesOcorrencias: 'Species and places of occurrence',
    exportarDadosConsultas: 'Export data',
    ocorrenciasApp: 'Occurrences app',
    dataCadastro: 'Registration date',
    criadoPor: 'Created by',
    nivelCerteza: 'Certainty level',
    byApp: 'From mobile app',
    verDados: 'View data',
    appuser: 'App user',
    profissao: 'Profession',
    colobadorJaCadastrado: 'Collaborator already registered',
    especialidadeApp: 'Expertise app',
    cep: 'Zip code',
    categoriaCDB: 'CBD Category',
    subCategoriaCDB: 'CDB Subcategory',
    saibaMas: 'Saiba mas',
    erradicada: 'Erradicada',
    mensagemEnviada: 'Message sent successfully.'
  },
  home: {
    destaque1: 'Paraguay National Invasive Alien Species Database',
    destaqueDescricao1: 'This database system receives contributions from people committed to the conservation of biodiversity. Please visit!',
    destaque2: 'Since 2007 documenting invasive alien species in Paraguay',
    destaqueDescricao2: 'This database has been developed and updated with funds from international projects. A Scientific Committee supports data management.',
    destaque3: 'How does it work?',
    destaqueDescricao3: 'This is an open access database. Please cite the database in your publications! Professionals may request a login to contribute data.',
    destaque4: 'Our purpose',
    destaqueDescricao4: 'Information on invasive alien species that impact the environment, places of occurrence in Paraguay, experts, projects, and references.',
    titulo: 'Find species, filter and export data',
    conteudo: 'Here you will find data on the native range of invasive alien species, ecological and biological characteristics, habitats invaded, places where species are invasive in the world and in Paraguay, including a map of places of occurrence, history of introduction and use, vectors and pathways of introduction and spread, information on management and references. Fileter and export for data to different formats.',
    conteudoEspecies: 'Species lists, ecological traits, native range, where the species are invasive in the world, vectors and pathways, risk assessment, uses, management options, places of occurrence in Paraguay, references.',
    conteudoColaboradores: 'Registry of people who have contributed data to the database. If you need to find an expert for more information on an occurrence, look for contacts here.',
    conteudoReferencias: 'Complete list of references used for the data fields on invasive alien species.',
    conteudoProjetos: 'Invasive alien species research and management projects in Paraguay. If you are working on a project, please let us know, so we can register it.',
    titulo1: 'Get to know invasive alien species in Paraguay',
    conteudo1: 'Invasive alien species occur in all groups of organisms and can be introduced intentionally or not for a variety of reasons.'
  },
  areaParceiros: {
    titulo: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    brasil: 'Brazil',
    jamaica: 'Jamaica'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Latin American Network',
    paragrafo1: 'This database network was established in 2005 as I3N (IABIN Invasives Information Network), a thematic network on invasive alien species then developed as part of the InterAmerican Biodiversity Information Network established by governments of countries in the Americas in 2001.',
    paragrafo2: 'Each country had a Lead for the network in charge of developing a national database and sharing information on invasive species. The people in charge of the national databases are:',
    paragrafo2_1: '',
    marcador1: 'Brazil: Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador2: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2003);',
    marcador3: 'Uruguay: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (since 2005), and M.Sc. Marcelo Iturburu, Coordinator of the Invasive Alien Species Committee of the Ministry of Environment (since 2018);',
    marcador4: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay Bird Conservation (since 2006);',
    marcador5: 'Jamaica: Dr. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (since 2007).',
    paragrafo3: 'IABIN received funding from a World Bank (GEF) project between 2004 and 2011, when it was terminated. Despite the closing of IABIN, some of the network Leads continued to develop and update the national databases (Brazil, Argentina, Uruguay, Paraguay and Jamaica).',
    paragrafo4: 'In 2021, the four South American countries received funding from the Bio-Bridge Initiative of the Convention on Biological Diversity to revitalize the Argentina, Brazil, Paraguay, and Uruguay databases. The interface was redesigned, new functions were added, and the programming was totally redone.',
    paragrafo5: 'The database developers are:',
    marcador6: 'The Programmer João Scucato, from Curitiba, Brazil, who developed the first version in Microsoft Access, 2004-2005;',
    marcador7: 'The Programmer Alejandro Moreno, from Argentina, who has provided support to the databases since 2006, and rescued data from Paraguay and Uruguay for this new version. He also developed the',
    marcador7_1: 'Common Platform',
    marcador7_2: 'for the South American countries in 2021.',
    marcador8: 'The Web Designer Rafael Moura and Programmer Thiago Lôbo, through',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'in Florianópolis, Brazil. ',
    marcador9: 'Leonardo Rotondano, who developed the cell phone application with Thiago Lôbo.',
    paragrafo6: 'We hope to expand the network once again to include more countries in Latin America and the Caribbean, making more data on invasive alien species available for management and policy, public knowledge and on-site field work.',
    paragrafo7: 'Support',
    paragrafo8: 'Year 2021',
    paragrafo9: '2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'National Database',
    titulo1: 'Paraguay National Invasive Alien Species Database',
    paragrafo1: 'This database was developed by Universidad Nacional del Sur (Argentina) in cooperation with the Horus Institute for Environmental Conservation and Development (Brazil) and support from the US Geological Survey (USGS) and the National Biological Information Infrastructure (NBII) as a component of I3N (IABIN Invasives Information Network) within IABIN (Interamerican Biodiversity Information Network).',
    paragrafo2: 'In 2020, renewed efforts to manage data on invasive alien species were made with other countries in the region. With support from the Biobridge Initiative of the Convention on Biological Diversity (CBD), this information system was updated and improved along with similar systems in Brazil, Paraguay, and Uruguay. A common platform was developed to facilitate unified data searches, representing one step further in consolidating joint efforts to face the challenges posed by invasive alien species in the region, and contribute to Goal 8 of the 2030 Agenda / SDGs, as well as of Aichi Target 9.',
    titulo5: 'Support'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Data policy',
    titulo: 'Concepts and references',
    criterio: 'Criteria for species inclusion',
    construccion: 'Collaborative construction',
    validacao: 'Data validation',
    paragrafo1: 'For the purposes of this data system, the following definitions are adopted, in accordance with the Convention on Biological Diversity:',
    marcador1: 'native species: found within its native range, where it evolved and is part of a biological community that is considered natural or semi-natural;',
    marcador2: 'alien species: found outside its past or present natural range;',
    marcador3: 'invasive alien species: any alien species that affects or threatens habitats, ecosystems or species, often leading to changes in the natural environment;',
    marcador3_1: 'cryptogenic species: species whose origin cannot be established with certainty and therefore cannot be classified as native or alien.',
    paragrafo2: 'The controlled vocabulary on habitats that are more susceptible to invasion, pathways and vectors, biological habit, impacts, and control methods were defined in accordance with the terms used in the',
    paragrafo2_1: 'Global Invasive Species Database',
    paragrafo2_2: 'managed by the Invasive Species Specialist Group linked to the IUCN.',
    paragrafo3: 'The main taxonomic references are taken from the systems proposed by',
    paragrafo3_1: 'the Missouri Botanical Garden',
    paragrafo3_2: 'and the Integrated Taxonomic Information System (ITIS).',
    paragrafo3_3: 'Supplementary references include the World Register of Marine Species',
    paragrafo3_4: 'Fishbase',
    paragrafo3_5: 'AmphibiaWeb',
    paragrafo3_6: 'Algaebase',
    paragrafo3_7: 'the Global Invasive Species Database',
    paragrafo3_8: 'and the CABI Invasive Species Compendium.',
    paragrafo6: 'At each point of occurrence, the species are classified as:',
    marcador4: ': presence is limited or restricted to cultivation or breeding systems or is directly dependent on people, for example in a laboratory, as a pet or aquarium animal, or a garden plant, without evidence of escape at the location;',
    marcador5: ': observed in natural or semi-natural environments, outside of cultivation or breeding systems, still without evidence of having established a population;',
    marcador6: ': the species reproduces effectively, forming a self-sustaining population, but remains in a limited area, close to the site of introduction;',
    marcador7: ': the species spreads, either on its own or assisted by anthropic vectors or pathways, to other sites beyond the point of introduction;',
    marcador7_1: ': when the species no longer exists at the site due to management interventions.',
    paragrafo7: 'It is important to note that invasion status is an attribute of the place of occurrence, not of the species. Therefore, the same species can behave as invasive in one place, be contained in another, and have been detected in the wild in a third place.',
    paragrafo11: 'The Paraguay Invasive Alien Species Database includes non-native and cryptogenic species detected in natural or semi-natural environments in the country, as well as species that are contained in cultivation or breeding systems but have a history of invasion in other countries. The database also includes species that are native to a region of the country and have been translocated to other regions, basins or ecosystems. All species are associated with at least one place of occurrence in the country. In the lack of post-import data, that location may be the airport or port of entry.',
    paragrafo11b: 'As the focus of this system is the conservation of biological diversity, non-native species that behave as agricultural weeds, pests or pathogens of people or domestic animals are only included when they have also been detected in natural areas, affecting native species, or have a history of invasion in natural areas elsewhere. Still, species that affect the structure, composition and functioning of natural and semi-natural ecosystems often also impact the economy, health or cultural values.',
    paragrafo13: 'The database is updated thanks to the contributions of volunteer collaborators distributed throughout the country. Our collaborators can contribute information on new species, new locations of occurrence of species already detected in the national territory, on projects, experts, or publications. The information is linked to the data providers as a recognition of their contributions to the state of the problem of biological invasions in the country and as a way to promote direct communication between people with knowledge and interest in invasive species.',
    paragrafo14_4: 'The database administrators are in charge of providing information on the biological characteristics of each species, the habitats that are more susceptible to invasion, vectors and pathways of introduction and spread, impacts and control techniques, among other data fields.',
    paragrafo14_5: 'The data are continually reviewed as new information is available. New species or places of occurrence that imply significant changes in the distribution of a species or the colonization of new environments are confirmed by consulting experts in the Scientific committee or other experts, as necessary.',
    paragrafo15: 'The reliability of the data is one of the main concerns of the system administrators. Validation is conducted at two main levels: for the correct identification of each taxon and to verify the accuracy of the location data. Standards and criteria have been defined in agreement with the other existing databases in the',
    paragrafo15_1: 'Latin American Invasive Alien Species Information Network.',
    paragrafo16: 'Criteria to validate taxonomic identity',
    marcador8: 'Can the data provider (author of the publication or collector of the specimen) be considered an expert in the group in question or does the person have equivalent experience?',
    marcador9: 'Is the occurrence documented by means of a photograph that allows for the reliable identification of the organism in question?',
    marcador10: 'Is the occurrence documented in an indexed and peer-reviewed publication or in a technical document prepared or reviewed by experts?',
    marcador11: 'Does the information correspond to a herbarium specimen or to a specimen deposited in a museum collection?',
    marcador12: 'Does the occurrence correspond to a species already registered in the country?',
    marcador13: 'Is the taxonomy of the group to which the species belongs stable and well-known?',
    marcador14: 'Do the characteristics of the species allow for a relatively simple identification?',
    paragrafo17: 'A positive answer to at least one of questions 1, 2, 3 or 4 indicates that the data should be considered validated. If these four questions have negative answers, the data will only be considered validated if the last three (5, 6 and 7) questions have positive answers.',
    paragrafo18: 'Criteria to validate location associated with the site where a species was detected',
    marcador15: 'Does the data include geographic coordinates? Both coordinates taken directly at the observation site and others that correspond to a nearby geographic reference are considered (as indicated).',
    marcador16: 'Is a description of the site included to allow for a reliable identification?',
    marcador17: 'Is the occurrence documented by a photograph that allows for a reliable identification of the site?',
    marcador18: 'Do the geographic coordinates provided coincide with the location of the described and / or photographed site?',
    marcador19: 'If the species is already cited for the country, is the locality included within its known distribution range or in a site compatible with the characteristics of other areas where the species is present, and with its spread capacity?',
    marcador20: 'Are the ecological conditions of the site, inferred from the indicated location, compatible with the general niche requirements of the species?',
    paragrafo19: 'The geographical location data are considered validated if:',
    marcador21: 'a- questions 1, 2 and / or 3, and 4 have positive answers (the data includes geographical coordinates, an unequivocal reference to the site is provided and / or a photograph is provided that allows reliable identification of the place and the coordinates provided match the location of the site described or photographed), or',
    marcador22: 'b- a positive or negative answer is assigned to question 1 (geographical coordinates are provided or not), a negative answer to questions 2 and 3 (the site is not described in detail or a photograph is attached to allow it to be located), but questions 5 and 6 are answered positively (the species has already been cited for the country and the locality is included within its known distribution range or in a site compatible with the characteristics of other areas where the species is present and with its spread capacity, and the ecological conditions of the site are compatible with the general niche requirements of the species).',
    paragrafo20: 'The location will be considered not validated if: ',
    marcador23: 'a- question 1 and questions 2 and / or 3 have positive answers, but question 4 is negative (the coordinates do not coincide with the site described or photographed), or',
    marcador24: 'b- questions 2 and 3 have negative answers, and at least one of questions 5 and 6 are negative (the data does not meet one or neither of these two conditions: 1- The species is already cited for the country and, in that case, the locality is included within its known range of distribution or in a site that is compatible with the characteristics of other areas where the species is present and with its spread capacity, 2- the site meets ecological conditions compatible with the general requirements niche of the species).',
    paragrafo21: 'Finally, the data are considered validated if the analyses of both criteria (taxonomy and location) are approved. ',
    paragrafo22: 'Validation is not proposed as a rule for inclusion of data, but as a descriptor that accompanies each location of occurrence. Each locality of occurrence of each species is associated with the descriptor “validated data” or “non-validated data”. It is important to note that this classification is temporary, since data can be confirmed and become validated once the criteria are met at some point after they have been appended to the system. The status of validation of species occurrences is shown on the respective distribution maps, with validated localities in green, and non-validated ones in grey.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Scientific Committee',
    paragrafo1: 'The National Invasive Alien Species Database is supported by a network of collaborators who help validate information, share new publications and data. The network includes experts in environmental sciences and taxonomy, members of the Natural History National of Paraguay (MNHNP) and the Ministry of the Environment and Sustainable Development (MADES) field practitioners and managers whose knowledge is invaluable for the maintenance of the database.',
    paragrafo2: 'As the database was renewed in 2021, contribution by experts will be formally acknowledged. A few experts have been invited to conform a Scientific Committee for the database. ',
    paragrafo3: 'Generally speaking, the role of the Scientific Committee is to provide support in data validation, in updating information, and helping to build and maintain the network of collaborators, which should lead to an increase in the amount and quality of data received for inclusion in the database.',
    paragrafo4: 'The Scientific Committee is formed by:',
    li1: 'Hugo del Castillo, Engineer, Ornithologist, Database Manager for Guyra Paraguay;',
    li2: 'José Luis Cartes, Biologist, M.Sc. Mastozoology, Executive Director for Guyra Paraguay;',
    li3: 'Pier Cacciali, Biologist, M.Sc., PhD in Herpetology, Coordinator of the Guyra Paraguay Species Program;',
    li4: 'Gloria Céspedes, Biologist, M.Sc. Botany;',
    li5: 'Juana de Egea, Biologist, M.Sc. Botany;',
    li6: 'John Kochalka, Biologist, M.Sc., expert on spiders;',
    li7: 'Héctor Vera, Biologist, PhD in Ichthyology;',
    li8: 'Bolívar Garcete, Biologist, PhD in invertebrates;',
    li9: 'Freddy Bauer, Biologist, Biodiversity Director of the Ministry of Environment and Sustainable Development (MADES).',
    titulo1: 'The role of the Scientific Committee',
    li10: 'Make decisions on the inclusion of new species in the database;',
    li11: 'make decisions on the exclusion of species for lack of data consistency or evidence of invasion;',
    li12: 'provide support for data validation;',
    li13: 'provide support on data reviews and for data searches;',
    li14: 'provide support to locate and consult with experts on data validation;',
    li15: 'make recommendations for improvement of the database;',
    li16: 'contribute to the continuous increase and improvement of database content.'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Contribute with data',
    paragrafo1: 'Our database is updated with support from experts and nature lovers throughout the national territory. Before being published, all information provided is validated according to criteria adopted at the regional level. You can provide data on species not yet listed in our database or on new places of occurrence by downloading our app (Invasoras PY) or sending an e-mail to ',
    paragrafo2: 'You can also send information on new publications or projects on biological invasions in Argentina. Data providers that contribute regularly may receive a username and password to upload information directly to the database. Help us face the challenge of invasive alien species based with complete, up-to-date and reliable information!',
    paragrafo3: 'Thank you!'
  },
  especiesInstitucional: {
    titulo: 'Species search',
    paragrafo1: 'On this page, you will find information on invasive invasive alien species present in Paraguay, characteristics, impacts, control options and other data.',
    paragrafo2: 'You can contribute information on new locations of occurrence or on species not yet included in this database, by',
    paragrafo2_1: 'clicking here!',
    verTutorial: 'Open tutorial',
    paragrafo3: 'How to cite this database:',
    paragrafo4_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Consultado DD/MM/AAA.',
    paragrafo4_2: ' Acess on __/__/____ (date)',
    tutorialTitulo1: 'How to cite this database',
    tutorialDescricao1: 'Access to data is free, but please cite the database in your publications so that more people learn about it and use the information available:',
    tutorialTitulo2: 'Use filters to find data',
    tutorialDescricao2: 'Type a latin name to find data on the species.',
    tutorialTitulo3: 'Use filters: search here',
    tutorialDescricao3: 'Select filters and combine data by place, biological group, life form, etc.',
    tutorialTitulo4: 'Number of records',
    tutorialDescricao4: 'This is the number of invasive species records in the database resulting from your search.',
    tutorialDescricao5: 'Here you can define the number or records to be viewed by page.',
    tutorialTitulo6: 'List of invasive alien species',
    tutorialDescricao6: 'List of all species or results of your search using filters. Click on the magnifying glass to the right of the species name to see the complete set of data.',
    tutorialTitulo6_1: 'Export data',
    tutorialDescricao6_1: 'Here you can filter and export data and select a format for exportation.',
    tutorialTitulo7: 'Taxonomy',
    tutorialDescricao7: 'Taxonomic classification of the species selected, common names, synonyms, and a picture whenever available!',
    tutorialTitulo8: 'Ecology and use',
    tutorialDescricao8: 'Description, biology and ecology of the species selected, native range, types of habitat invaded and economic use.',
    tutorialTitulo9: 'Introduction and spread',
    tutorialDescricao9: 'How the species arrived to the area it invades, reasons for introduction, and how it spreads to new areas.',
    tutorialTitulo10: 'Impacts',
    tutorialDescricao10: 'Environmental, economic, health, and cultural impacts. Impact classification of alien taxa based on the IUCN EICAT / SEICAT protocols.',
    tutorialTitulo11: 'Management',
    tutorialDescricao11: 'Prevention, early detection and rapid response measures, mechanical, chemical and biological control for the species selected.',
    tutorialTitulo12: 'Risk asssessment',
    tutorialDescricao12: 'Risk assessment results for the species selected.',
    tutorialTitulo13: 'Occurrences',
    tutorialDescricao13: 'Location, habitats, protected areas, municipalities where the species occurs in the country, including stage of invasion – see the map!',
    tutorialDescricao14: 'List of references used to compile data on the species and other publications.',
    tutorialTitulo15: 'Projects',
    tutorialDescricao15: 'List of research and management projects that include the species selected.'
  },
  colaboradoresInstitucional: {
    titulo: 'Collaborator search',
    paragrafo1: 'Collaborators are those who contribute data to this information system, as well as those who collect museum or herbarium specimens, autor publications used as data sources or provide support for species identification or to validate information on invasive alien species. ',
    paragrafo2: 'Your contribution is much appreciated.',
    paragrafo3: 'How to cite this database:',
    paragrafo4_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Consultado DD/MM/AAA.',
    paragrafo4_2: ' Access on __/__/____ (date)'
  },
  peixesAquarioInstitucional: {
    titulo: 'Aquarium fishes',
    paragrafo1: 'Aquarium fishes are currently the most numerous pets in the world. Brazil is a large provider of aquarium fishes due to the high species richness in the Amazon and Pantanal. Still, most fishes are non-native wherever they are sold. Such fishes must never be released in lakes, riverrs or the ocean, as they may prey on native species, change natural habitats or transmit diseases or parasites.',
    paragrafo2: 'Besides, they may become invasive, as in the case of lion fish, which escaped na aquarium in Florida, USA, and is now invasive throughout the Caribbean. ',
    paragrafo3: 'It might reach the coast of Brazil, where it ir a high risk for coral reefs in Abrolhos and Fernando de Noronha. ',
    paragrafo4: 'Learn more:',
    marcador1: 'Non-native ornamental fishes threaten biodiversity',
    marcador2: 'Non-native invaders',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - cichlid fish',
    paragrafo5b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenile)',
    paragrafo6b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae cichlid fish',
    paragrafo7b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - serpae tetra fish',
    paragrafo8b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - fat oscar',
    paragrafo9b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - zebra danio',
    paragrafo10b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - lion fish',
    paragrafo11b: 'Photo by: The Horus Institute'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Aquaculture fishes',
    paragrafo1: 'Aquaculture is the main pathway of introduction of freshwater fishes in Brazil. Many species are quite aggressive and have caused the extinction of native species in seveal countries, such as the Nile tilápia and the African catfish. Pangasius Is a fish native to Asia now being cultivated in Brazil in spite of not having been authorized by the federal environmental agency. It is highly invasive, and survives in low quality waters. Learn about fish products before you buy them to avoid contributing to environmental impacts. ',
    paragrafo2: 'The problem is that breeding facilities such as artificial ponds and net cages are not safe enough to avoid escapes, or fishes are releaseed on purpose by breeders. They may prey on native species, change the natural aquatic environment, or transmit diseases or parasites. ',
    paragrafo3: 'More information',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo3c: 'in this post',
    paragrafo4: 'Cichla kelberi - peacock bass',
    paragrafo5: 'Coptodon rendalli - tilapia',
    paragrafo6: 'Cyprinus carpio - common carp',
    paragrafo7: 'Micropterus salmoides - black bass',
    paragrafo7b: 'Photo by: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus - Nile tilapia'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Sport fishing',
    paragrafo1: 'Sport fishing is a leisure activity that has led to the introduction of many non-native species which are often varacious predators. Although the introduction of non-native species and the translocation of species between watersheds is prohibited in Brazil, (Portaria IBAMA 145-N/1998), many fishes have been taken from their watersheds of origin to others where they are not native, creating impacts on native species by predation, competition, and transmission of diseases and parasites, apart from ecological imbalance.',
    paragrafo2: 'The introduction of invasive species used as live bait is associated with sport fishing, as they may escape from fish hooks or even be discarded in natural water bodies. Fishes and other species used as live bait should only be used when native to the watershed where you go fishing to avoid introducing alien species and their impacts on native species.',
    paragrafo3: 'Never release non-native species in nature!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Photo by: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - peacock bass',
    paragrafo5b: 'Photo by: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - peacock bass',
    paragrafo6b: 'Photo by: LEPIB - UEL PR',
    paragrafo7: 'Learn more'
  },
  petsInstitucional: {
    titulo: 'Pets',
    paragrafo1_1: 'The word ',
    pet: 'pet',
    paragrafo1_2: ' refers to animals maintained for company, from aquarium fishes to dogs and cats. Some animals listed here, especially cats and dogs, are only considered a problem when present in natural areas where they can harm native animals.',
    paragrafo2: 'Do not allow your pet to wander in natural areas! ',
    paragrafo3: 'Trachemys scripta - red-eared slider',
    paragrafo3b: 'Photo by: Marcos Tortato',
    paragrafo4: 'Felis catus - domestic cat',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Canis familiaris - domestic dog',
    paragrafo5b: 'Photo by: Marcos Tortato',
    paragrafo6: 'Trachemys dorbigni - slider',
    paragrafo6b: 'Photo by: Marcos Tortato'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Ornamental plants',
    paragrafo1: 'Ornamental plants generally represent more than 50% of the invasive alien plants introduced into the countries. The choice of plants for gardening, shade and urban trees can contribute to the conservation of nature, which includes water supply, climatic stability and many other "services" that nature provides to humans.',
    paragrafo2_1: 'Grow plants native to your region! Know, avoid and replace invasive exotic plants in your garden.',
    paragrafo4: 'Tecoma stans - yellow bells',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Furcraea foetida - Mauritius hemp',
    paragrafo5b: 'Photo by: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - dumb cane',
    paragrafo6b: 'Photo by: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - asparagus fern',
    paragrafo7b: 'Photo by: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - tropical almond tree',
    paragrafo8b: 'Photo by: The Horus Institute'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Forage plants',
    paragrafo1: 'Many plants were introduced for use as fodder, that is, to feed livestock such as oxen, cows, goats, horses, and donkeys. They are mainly grasses, among which brachiaria, which is a very aggressive invasive in natural and degraded areas, even in wetlands and small streams. But there are also shrubs, such as the leucena, and trees such as the carob, whose pods are used as food for goats.',
    paragrafo2: 'These species were widely disseminated in the tropical world and cause environmental impacts by changing natural cycles such as fire, reducing water availability, and displacing native species by forming dense stands where nothing else can grow.',
    paragrafo4: 'Urochloa decumbens - brachiaria',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Prosopis juliflora - mesquite',
    paragrafo5b: 'Photo by: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - molassess grass',
    paragrafo6b: 'Photo by: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - jaragua grass',
    paragrafo7b: 'Photo by: The Horus Institute'
  },
  projetosInstitucional: {
    titulo: 'Project search',
    paragrafo1: 'This section contains information about current or finished projects focused on invasive species research or management in Paraguay. If you work or have worked in such a project, please help us by sending data to .',
    paragrafo2: 'Your contribution is much appreciated.',
    paragrafo3: 'How to cite this database:',
    paragrafo4_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Access on DD/MM/AAA.'
  },
  referenciasInstitucional: {
    titulo: 'Reference search',
    paragrafo1: 'The publications and reports listed here have been used as data sources on invasive alien species, their behavior and impacts. Some include information on managemet and on places of occurrence in the country. If you have published or know of publications that are not listed here, please contribute by sending publications or citations to  ',
    paragrafo2: 'Your contribution is much appreciated.',
    paragrafo4: 'How to cite this database:',
    paragrafo5_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Access on DD/MM/AAA.'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contact',
    contato: 'Contact',
    p1: 'Fill all the fields in the form below:',
    nome: 'Name',
    email: 'E-mail',
    instituicao: 'Institution',
    mensagem: 'Message',
    enviar: 'Send'
  }
}
