<template>
  <div>
    <div class="container home-busca-avancada">
      <div class="row">
        <div class="row ">
            <div class="col-12">
              <h2  class="align-middle text-center mb-0 laranja">{{ $t('home.titulo') }}</h2>
            </div>
        </div>
        <div class="row pb-4">
          <div class="col-12">
            <p class="mt-4 text-center">{{ $t('home.conteudo') }}</p>
          </div>
        </div>
        <div class="row mt-4 busca-avancada pagina-tematica align-items-center">
          <div class="col-md-3 pt-3">
            <router-link to="/especies">
              <h3 class="h4 laranja text-end mb-3">{{ $t('label.especies') }}</h3>
              <p class="text-end">{{ $t('home.conteudoEspecies') }}</p>
            </router-link>
          </div>
          <div class="col-md-3 p-4">
            <!-- galeria -->
            <router-link to="/especies">
              <div id="carousel-colaboradores" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/institucional/img/ilustra7.png" class="d-block w-100" alt="...">
                  </div>
                </div>
              </div><!--/ galeria -->
            </router-link>
          </div>
          <div class="col-md-3 p-4">
            <!-- galeria -->
            <router-link to="/colaboradores">
              <div id="carousel-colaboradores" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/institucional/img/ilustra6.png" class="d-block w-100" alt="...">
                  </div>
                </div>
              </div><!--/ galeria -->
            </router-link>
          </div>
          <div class="col-md-3 pt-3">
            <router-link to="/colaboradores">
              <h3 class="h4 laranja mb-3">{{ $t('label.colaboradores') }}</h3>
              <p>{{ $t('home.conteudoColaboradores') }}</p>
            </router-link>
          </div>
        </div>

        <div class="row pt-4 busca-avancada pagina-tematica align-items-center">
          <div class="col-md-3 p-4">
            <!-- galeria -->
            <router-link to="/referencias">
              <div id="carousel-colaboradores" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/institucional/img/ilustra8.png" class="d-block w-100" alt="...">
                  </div>
                </div>
              </div><!--/ galeria -->
            </router-link>
          </div>
          <div class="col-md-3 pt-3">
            <router-link to="/referencias">
              <h3 class="h4 laranja mb-3">{{ $t('label.referencias') }}</h3>
              <p>{{ $t('home.conteudoReferencias') }}</p>
            </router-link>
          </div>
          <div class="col-md-3 pt-3">
            <router-link to="/projetos">
              <h3 class="h4 laranja text-end  mb-3">{{ $t('label.projetos') }}</h3>
              <p class="text-end">{{ $t('home.conteudoProjetos') }}</p>
            </router-link>
          </div>
          <div class="col-md-3 p-4">
            <!-- galeria -->
            <router-link to="/projetos">
              <div id="carousel-colaboradores" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/institucional/img/ilustra5.png" class="d-block w-100" alt="...">
                  </div>
                </div>
              </div><!--/ galeria -->
            </router-link>
          </div>
        </div>

      </div>
    </div>
    <InstitucionalSliderTemas />
  </div>
</template>

<script>
import InstitucionalSliderTemas from '@/components/institucional/InstitucionalSliderTemas'
export default {
  name: 'Conteudo',
  components: { InstitucionalSliderTemas }
}
</script>
