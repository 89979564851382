<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===9 }" id="projetos" role="tabpanel" aria-labelledby="projetos-tab">
    <div class="container-fluid  mt-3">
      <!-- tabela -->
      <div class="row">
          <div class="col-12 d-flex">
            <div class="flex-fill ">
                <div class="card-body box-dashboard p-0 mb-3">
                  <div class="align-self-center">
                      <div class="table-responsive  full_table  mt-0 ">
                        <table class="table caption-top">
                            <thead class="bg_table_header">
                              <tr>
                                <th class="col-md-6 py-3 px-2">{{ $t('label.titulo') }}</th>
                                <th class="col-md-3 py-3 px-2">{{ $t('label.contato') }}</th>
                                <th class="col-md-1 py-3 px-2">{{ $t('label.areaGeografica') }}</th>
                                <th class="col-md-1 py-3 px-2 pr-4 text-right" v-if="!byInstitucional">{{ $t('label.acoes') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="!form.relProjects || form.relProjects.length === 0"><td colspan="4" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                              <tr v-else v-for="item in form.relProjects" :key="item.dataProjects.project_id">
                                <td class="py-3 px-2">{{item.dataProjects.title}}</td>
                                <td class="py-3 px-2">{{item.dataProjects.dataContacts.surname}}, {{item.dataProjects.dataContacts.name}}</td>
                                <td class="py-3 pl-4 pr-2">{{item.dataProjects.geo_area}}</td>
                                <td class="py-3 px-2" v-if="!byInstitucional">
                                  <ul class="acoes text-right pr-1">
                                    <li>
                                      <a @click="$router.push({ name: 'AdminProjetosForm', params: { id: item.dataProjects.project_id } })"><span class="editar"></span></a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <!-- /tabela -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColaboradoresInstitucional',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorProjetos: Boolean,
    onlyLoadValidator: Boolean
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorProjetos' (newValue) {
      if (newValue) {
        this.$v.$touch()
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v)
      }
    }
  }
}
</script>
