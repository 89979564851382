<template>
<div>
  <div class="home_destaque d-none">
    <div class="container">
      <div class="row align-items-center position-relative flex-md-row-reverse">
        <div class="col-sm-12 col-lg-5 col-xl-6 p-5">
          <div class="glider-home slide-one-item-alt">
            <img src="../../../assets/institucional/img/ilustra5.png" alt="Image" class="img-fluid">
           <img src="../../../assets/institucional/img/ilustra6.png" alt="Image" class="img-fluid">
            <img src="../../../assets/institucional/img/ilustra7.png" alt="Image" class="img-fluid">
            <img src="../../../assets/institucional/img/ilustra8.png" alt="Image" class="img-fluid">
          </div>
        </div>

        <div class="col-sm-12 col-lg-7 col-xl-6 p-5">

          <div class="glider-home slide-one-item-alt-text">
            <div class="slide-text">
              <h1 class="laranja h2">{{ $t('home.destaque1') }}</h1>
              <p class="mb-5 me-5">{{ $t('home.destaqueDescricao1') }}</p>
              <p><router-link class="btn btn-primary btn-1-horus mt-1" to="/base-dados-nacional">teste</router-link></p>
            </div>
           <div class="slide-text">
              <h2 class="laranja h2">{{ $t('home.destaque2') }}</h2>
              <p class="mb-5 me-5">{{ $t('home.destaqueDescricao2') }}</p>
              <p><router-link class="btn btn-primary btn-1-horus mt-1" to="/comite-cientifico">{{ $t('label.saibaMais') }}</router-link></p>
            </div>
            <div class="slide-text">
              <h2 class="laranja h2">{{ $t('home.destaque3') }}</h2>
              <p class="mb-5 me-5">{{ $t('home.destaqueDescricao3') }}</p>
              <p><router-link class="btn btn-primary btn-1-horus mt-1" to="/politica-de-dados">{{ $t('label.saibaMais') }}</router-link></p>
            </div>
            <div class="slide-text">
              <h2 class="laranja h2">{{ $t('home.destaque4') }}</h2>
              <p class="mb-5 me-5">{{ $t('home.destaqueDescricao4') }}</p>
              <p><a href="https://institutohorus.org.br/institucional/" target="_blank" class="btn btn-primary btn-1-horus mt-1">{{ $t('label.saibaMais') }}</a></p>
            </div>
          </div>

          <div class="owl-custom-direction">
            <a href="#" class="custom-prev shadow-sm"><span class="icon-keyboard_arrow_left"></span></a>
            <a href="#" class="custom-next shadow-sm"><span class="icon-keyboard_arrow_right"></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="homeSlider container">
      <div class="glide__track " data-glide-el="track">
        <ul class="glide__slides">

          <li class="glide__slide">
            <div class="row align-items-center position-relative flex-md-row-reverse">
              <div class="col-sm-12 col-lg-5 col-xl-6 p-5">
                <div class=" slide-one-item-alt">
                  <img src="../../../assets/institucional/img/ilustra5.png" alt="Image" class="img-fluid">
                </div>
              </div>

              <div class="col-sm-12 col-lg-7 col-xl-6 p-5">

                <div class=" slide-one-item-alt-text">
                  <div class="slide-text">
                    <h1 class="laranja h2">{{ $t('home.destaque1') }}</h1>
                    <p class="mb-5 me-5">{{ $t('home.destaqueDescricao1') }}</p>
                    <p><router-link class="btn btn-primary btn-1-horus mt-1" to="/base-dados-nacional">{{ $t('label.saibaMais') }}</router-link></p>
                  </div>
                </div>

              </div>
            </div>
          </li>

          <li class="glide__slide">
            <div class="row align-items-center position-relative flex-md-row-reverse">
              <div class="col-sm-12 col-lg-5 col-xl-6 p-5">
                <div class=" slide-one-item-alt">
                  <img src="../../../assets/institucional/img/ilustra6.png" alt="Image" class="img-fluid">
                </div>
              </div>

              <div class="col-sm-12 col-lg-7 col-xl-6 p-5">

                <div class=" slide-one-item-alt-text">
                  <div class="slide-text">
                    <h2 class="laranja h2">{{ $t('home.destaque2') }}</h2>
                    <p class="mb-5 me-5">{{ $t('home.destaqueDescricao2') }}</p>
                    <p><router-link class="btn btn-primary btn-1-horus mt-1" to="/comite-cientifico">{{ $t('label.saibaMais') }}</router-link></p>
                  </div>
                </div>

              </div>
            </div>
          </li>

          <li class="glide__slide">
            <div class="row align-items-center position-relative flex-md-row-reverse">
              <div class="col-sm-12 col-lg-5 col-xl-6 p-5">
                <div class=" slide-one-item-alt">
                  <img src="../../../assets/institucional/img/ilustra7.png" alt="Image" class="img-fluid">
                </div>
              </div>

              <div class="col-sm-12 col-lg-7 col-xl-6 p-5">

                <div class=" slide-one-item-alt-text">
                  <div class="slide-text">
                    <h2 class="laranja h2">{{ $t('home.destaque3') }}</h2>
                    <p class="mb-5 me-5">{{ $t('home.destaqueDescricao3') }}</p>
                    <p><router-link class="btn btn-primary btn-1-horus mt-1" to="/politica-de-dados">{{ $t('label.saibaMais') }}</router-link></p>
                  </div>
                </div>

              </div>
            </div>
          </li>

          <li class="glide__slide">
            <div class="row align-items-center position-relative flex-md-row-reverse">
              <div class="col-sm-12 col-lg-5 col-xl-6 p-5">
                <div class=" slide-one-item-alt">
                  <img src="../../../assets/institucional/img/ilustra8.png" alt="Image" class="img-fluid">
                </div>
              </div>

              <div class="col-sm-12 col-lg-7 col-xl-6 p-5">

                <div class=" slide-one-item-alt-text">
                  <div class="slide-text">
                    <h2 class="laranja h2">{{ $t('home.destaque4') }}</h2>
                    <p class="mb-5 me-5">{{ $t('home.destaqueDescricao4') }}</p>
                    <p><a href="https://institutohorus.org.br/institucional/" target="_blank" class="btn btn-primary btn-1-horus mt-1">{{ $t('label.saibaMais') }}</a></p>
                  </div>
                </div>

              </div>
            </div>
          </li>

        </ul>
      </div>

      <div class="slider__bullets glide__bullets" data-glide-el="controls[nav]">
        <button class="slider__bullet glide__bullet active" data-glide-dir="=0"></button>
        <button class="slider__bullet glide__bullet" data-glide-dir="=1"></button>
        <button class="slider__bullet glide__bullet" data-glide-dir="=2"></button>
        <button class="slider__bullet glide__bullet" data-glide-dir="=3"></button>
      </div>

      <div data-glide-el="controls" class="controls">
        <button data-glide-dir="<" class="prev"></button>
        <button data-glide-dir=">" class="next ml-1"></button>
      </div>
    </div>
</div>
</template>
<script>
import '@glidejs/glide/dist/css/glide.core.min.css'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Glide from '@glidejs/glide'
export default {
  name: 'Destaque',
  mounted () {
    const glideHomeSlider = new Glide('.homeSlider', {
      type: 'carousel',
      autoplay: 3500,
      perView: 1
    })
    glideHomeSlider.mount()
  }
}
</script>
