<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="!byInstitucional" :title="pageTitle" :titleSave="pendingAnalysis ? $t('label.aprovarProjeto') : $t('label.salvarProjeto')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit" :pendingAnalysis="pendingAnalysis"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
                <div class="align-self-center">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.title !== this.form.log_update.Title }">
                        <label for="referencias-titulo" class="form-label">{{ $t('label.titulo') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.title !== this.form.log_update.Title" class="btn" type="button" @click="analysis($t('label.titulo'), 'title')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea v-model="form.title" type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$dirty && $v.form.title.$error }" :disabled="byInstitucional" rows="2"></textarea>
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.contact_id !== this.form.log_update.Contact_id }">
                        <label for="projetos-contato" class="form-label">{{$t('label.contato')}}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.contact_id !== this.form.log_update.Contact_id" class="btn" type="button" @click="analysis($t('label.contato'), 'contact_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :filterable="false" @search="getContacts" :options="contactsList" v-model="form.contact_id" label="name" index="contact_id"
                          :reduce="r => r.contact_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>

                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.geo_area !== this.form.log_update.Geo_area }">
                        <label for="projetos-area-geografica" class="form-label">{{ $t('label.areaGeografica') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.geo_area !== this.form.log_update.Geo_area" class="btn" type="button" @click="analysis($t('label.areaGeografica'), 'geo_area')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea v-model="form.geo_area" id="projetos-area-geografica" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                      </div>

                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.main_obj !== this.form.log_update.Main_obj }">
                        <label for="projetos-objetivos" class="form-label">{{ $t('label.objetivos') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.main_obj !== this.form.log_update.Main_obj" class="btn" type="button" @click="analysis($t('label.objetivos'), 'main_obj')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea v-model="form.main_obj" id="projetos-objetivos" rows="4"  class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                      </div>

                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.brief_description !== this.form.log_update.Brief_description }">
                        <label for="projetos-breve-descricao" class="form-label">{{ $t('label.breveDescricao') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.brief_description !== this.form.log_update.Brief_description" class="btn" type="button" @click="analysis($t('label.breveDescricao'), 'brief_description')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea v-model="form.brief_description" id="projetos-breve-descricao" rows="4"  class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                      </div>
                    </div>
                    <div class="col-md-6 border-start">
                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.beginning_date !== this.form.log_update.Beginning_date }">
                        <label for="projetos-data-inicio" class="form-label">{{ $t('label.dataInicio') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.beginning_date !== this.form.log_update.Beginning_date" class="btn" type="button" @click="analysis($t('label.dataInicio'), 'beginning_date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input v-model="form.beginning_date" type="text" class="form-control" id="projetos-data-inicio" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.end_date !== this.form.log_update.End_date }">
                        <label for="projetos-data-termino" class="form-label">{{ $t('label.dataTermino') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.end_date !== this.form.log_update.End_date" class="btn" type="button" @click="analysis($t('label.dataTermino'), 'end_date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input v-model="form.end_date" type="text" class="form-control" id="projetos-data-termino" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && this.form.comments !== this.form.log_update.Comments }">
                        <label for="projetos-comentarios" class="form-label">{{ $t('label.comentarios') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && this.form.comments !== this.form.log_update.Comments" class="btn" type="button" @click="analysis($t('label.comentarios'), 'comments')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea v-model="form.comments" id="projetos-comentarios" rows="4"  class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':  !byInstitucional && pendingAnalysis && pendingSpecies }">
                        <label for="projetos-especies" class="form-label">{{ $t('label.especies') }}</label>
                        <button v-if=" !byInstitucional && pendingAnalysis && pendingSpecies" class="btn" type="button" @click="analysis(`${$t('label.especies') }`, 'specieSelected')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select multiple :appendToBody="true" :filterable="false" @search="getSpecies" :options="speciesList" v-model="specieSelected" label="scientific_name" index="species_id"
                          :reduce="r => r.species_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.scientific_name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.scientific_name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
    <!-- Modal -->
    <AdminModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ProjetosForm',
  props: {
    byInstitucional: Boolean,
    idItem: Number,
    viewItem: Boolean
  },
  components: {
    PageTitle,
    AdminModalReview
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.projetos')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      form: { title: '' },
      speciesList: [],
      specieSelected: [],
      oldSpecieSelected: [],
      contactsList: [],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      contact: {}
    }
  },
  validations: {
    form: {
      title: { required }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    },
    pendingSpecies: function () {
      let result = false
      if (this.specieSelected.length !== this.oldSpecieSelected.length) {
        return true
      }
      this.oldSpecieSelected.forEach(e => {
        if (!this.specieSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  watch: {
    'form.relProjects' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getSpecieSelected()
        this.getOldSpecieSelected()
      }
    },
    'form.contact_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getContacts()
      }
    }
  },
  methods: {
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/project/${this.form.project_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    getContactById: async function (id) {
      await axios.get(`/Contact/${id}`)
        .then(response => {
          this.contact = response.data
        })
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'title':
          this.modalValuesSimple(this.form.title, this.form.log_update.Title, label)
          break
        case 'geo_area':
          this.modalValuesSimple(this.form.geo_area, this.form.log_update.Geo_area, label)
          break
        case 'main_obj':
          this.modalValuesSimple(this.form.main_obj, this.form.log_update.Main_obj, label)
          break
        case 'brief_description':
          this.modalValuesSimple(this.form.brief_description, this.form.log_update.Brief_description, label)
          break
        case 'beginning_date':
          this.modalValuesSimple(this.form.beginning_date, this.form.log_update.Beginning_date, label)
          break
        case 'end_date':
          this.modalValuesSimple(this.form.end_date, this.form.log_update.End_date, label)
          break
        case 'comments':
          this.modalValuesSimple(this.form.comments, this.form.log_update.Comments, label)
          break
        case 'contact_id':
          newValue = this.contactsList.filter(l => l.contact_id === this.form.contact_id)[0]
          oldValue = this.contactsList.filter(l => l.contact_id === this.form.log_update.Contact_id)[0]
          if (!oldValue) {
            await this.getContactById(this.form.log_update.Contact_id)
            oldValue = this.contact
          }
          this.modalValuesSimple(newValue.name, oldValue.name, label)
          break
        case 'specieSelected':
          this.modalValues.label = label
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.specieSelected.forEach(e => {
            newValue = this.speciesList.filter(l => l.species_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.species_id, value: newValue.scientific_name })
          })
          this.modalValues.oldValue = []
          this.oldSpecieSelected.forEach(e => {
            oldValue = this.speciesList.filter(l => l.species_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.species_id, value: oldValue.scientific_name })
          })
          break
      }
    },
    getById: function (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/project/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = ` ${this.form.title}`
          this.form.log_update = JSON.parse(this.form.log_update)
        })
    },
    save: function () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.form.RelProjects = []
      this.specieSelected.forEach(element => {
        this.form.RelProjects.push({ project_id: this.form.project_id, species_id: element })
      })
      this.form.log_update = JSON.stringify(this.form.log_update)
      if (this.action === this.$actionEdit) {
        axios.put('/project', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/project', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/projetos')
      }
    },
    getSpecies: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.specieSelected.join(',')
        loading(true)
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.specieSelected.join(',')
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
          })
      }
    },
    getSpecieSelected: function () {
      if (this.form.relProjects.length > 0) {
        this.form.relProjects.forEach(e => {
          if (e) {
            if (!this.specieSelected.includes(Number(e.species_id))) {
              this.specieSelected.push(Number(e.species_id))
            }
          }
        })
      }
      this.getSpecies()
    },
    getOldSpecieSelected: function () {
      if (this.form.log_update && this.form.log_update.RelProjects.length > 0) {
        this.form.log_update.RelProjects.forEach(e => {
          if (e) {
            if (!this.oldSpecieSelected.includes(Number(e.Species_id))) {
              this.oldSpecieSelected.push(Number(e.Species_id))
            }
          }
        })
      }
    },
    getContacts: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.name = search
        params.contact_id = this.form.contact_id
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.contact_id = this.form.contact_id
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
          })
      }
    }
  },
  created: function () {
    this.getSpecies()
    this.getContacts()
    if (this.viewItem) {
      this.action = this.$actionEdit
      this.getById(this.idItem)
    } else {
      this.action =
        this.$route.params.id !== this.$actionNew
          ? this.$actionEdit
          : this.$actionNew

      if (this.action === this.$actionEdit) {
        this.pageTitle = `${this.$t('label.projetos')} > ${this.$t('label.editando')}:`
        this.getById(this.$route.params.id)
      }
    }
  }
}
</script>
