<template>
  <div>
    <InstitucionalHeaderSingle :title="`${ $t('label.tutorial') }`" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">

          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ornare quam sed metus vestibulum, at consectetur erat auctor. Pellentesque a vestibulum lorem. In et lacus quis turpis cursus commodo a a ex. Nulla a convallis velit. Fusce libero nisi, elementum eu quam eget, hendrerit commodo odio. Suspendisse quam velit, vehicula vitae enim pellentesque, efficitur tincidunt neque. Donec iaculis mi et maximus porta. Nunc dapibus massa eu velit mattis, eget mattis felis faucibus. Sed maximus pharetra diam, nec lacinia nulla gravida vitae. Aenean rhoncus massa vel volutpat efficitur. Duis id arcu eget urna fermentum convallis ac eget erat. Cras eu nulla eget nisi porta tincidunt. Sed fermentum et lacus sed sollicitudin. Etiam sem elit, lobortis et erat eget, sagittis condimentum ligula. Nullam ac nisi eleifend, aliquet est in, rhoncus neque. Pellentesque quis tristique ex.</p>

          <p>Suspendisse potenti. Vestibulum viverra a neque ut tincidunt. Etiam sodales, lorem nec vestibulum laoreet, nunc mi facilisis ligula, ac maximus dolor quam in tellus. Aenean suscipit elit nec rutrum porttitor. Etiam tincidunt imperdiet fermentum. Cras egestas ut ligula a porttitor. Quisque dignissim pulvinar euismod. Proin feugiat, enim ut volutpat lacinia, diam dui dictum metus, et pharetra lacus massa ut odio. Vestibulum scelerisque nec sapien ac faucibus. Sed condimentum diam eu tincidunt tristique. Sed id pharetra lorem. Maecenas tincidunt, quam et blandit tincidunt, ligula quam hendrerit dolor, vitae iaculis magna ex et massa.</p>

          <p>Morbi risus tellus, varius non porta et, efficitur at ante. Fusce auctor porttitor aliquam. Cras varius libero lobortis tristique vulputate. Vestibulum ultricies molestie congue. Donec rutrum, risus eu sodales convallis, erat dolor tincidunt leo, vel sollicitudin arcu arcu non lacus. Mauris tristique ante dolor, vitae sollicitudin nisl porta eu. Mauris ac diam neque. Sed non arcu nec purus malesuada pretium. Curabitur aliquet accumsan leo vitae feugiat.</p>

          <p>Sed eu tortor eget sem fringilla tempus. Phasellus neque tellus, commodo semper dui eget, placerat vehicula arcu. Integer et nisl urna. Nam nec suscipit lectus, eu laoreet ligula. Sed enim eros, lacinia eget ipsum ac, imperdiet finibus enim. Ut pharetra tincidunt metus, ac commodo tortor. Integer rutrum mollis quam, nec vehicula nisi scelerisque sed. Integer vel tristique risus. Nunc at venenatis velit.</p>

          <p>Integer vitae odio tortor. Curabitur facilisis rutrum elit sed dignissim. Nulla tempor tortor vel erat volutpat lobortis. In interdum elementum neque, at bibendum velit congue sed. Nulla scelerisque sem eu arcu luctus varius. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris lacinia, metus et maximus malesuada, libero odio lacinia mauris, sit amet scelerisque nunc metus nec risus. Proin quis velit dui. Etiam lorem augue, facilisis vel gravida eget, pellentesque sed dui. Vestibulum dictum ullamcorper efficitur. Phasellus euismod felis sed elit varius sollicitudin. Morbi volutpat aliquet dignissim. Curabitur justo nulla, efficitur ut est nec, pellentesque suscipit arcu.</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'Tutorial',
  components: { InstitucionalHeaderSingle }
}
</script>
