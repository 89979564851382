<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('comiteCientifico.cabecalhoTitulo') }`" :classProp="'header-single header-comite'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">

          <p>{{ $t('comiteCientifico.paragrafo1') }}</p>

          <p>{{ $t('comiteCientifico.paragrafo2') }}</p>
          <p>{{ $t('comiteCientifico.paragrafo3') }}</p>

          <p>{{ $t('comiteCientifico.paragrafo4') }}</p>

          <ul>
            <li>{{ $t('comiteCientifico.li1') }}</li>
            <li>{{ $t('comiteCientifico.li2') }}</li>
            <li>{{ $t('comiteCientifico.li3') }}</li>
            <li>{{ $t('comiteCientifico.li4') }}</li>
            <li>{{ $t('comiteCientifico.li5') }}</li>
            <li>{{ $t('comiteCientifico.li6') }}</li>
            <li>{{ $t('comiteCientifico.li7') }}</li>
            <li>{{ $t('comiteCientifico.li8') }}</li>
            <li>{{ $t('comiteCientifico.li9') }}</li>
          </ul>

          <h2 class="laranja h2 mt-5">{{ $t('comiteCientifico.titulo1') }}</h2>

          <ol>
            <li>{{ $t('comiteCientifico.li10') }}</li>
            <li>{{ $t('comiteCientifico.li11') }}</li>
            <li>{{ $t('comiteCientifico.li12') }}</li>
            <li>{{ $t('comiteCientifico.li13') }}</li>
            <li>{{ $t('comiteCientifico.li14') }}</li>
            <li>{{ $t('comiteCientifico.li15') }}</li>
            <li>{{ $t('comiteCientifico.li16') }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'ComiteCientifico',
  components: { InstitucionalHeaderSingle }
}
</script>
