import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
  }
})
Vue.filter('formatDateComponentCalender', function (value) {
  if (value) {
    return moment({ y: value.split('/')[2], M: value.split('/')[1] - 1, d: value.split('/')[0] }).format('YYYY-MM-DD')
  }
})
Vue.filter('invertFormatDateComponentCalender', function (value) {
  if (value) {
    return moment({ y: value.split('-')[0], M: value.split('-')[1] - 1, d: value.split('-')[2] }).format('DD/MM/YYYY')
  }
})
