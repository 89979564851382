<template>
  <span class="add2-label">
      <input type="text" class="add2-label-input" v-model="commonName.common_name" ref="common_name">
      <v-select :appendToBody="true" :options="languageList" v-model="commonName.language_id" label="valor" index="language_id"
        :reduce="lang => lang.language_id" :clearable="false" style="min-width: 150px;" class="add2-label-input">
        <template v-slot:selected-option="option">
          {{ `${option.valor}` }}
        </template>
        <template v-slot:option="option">
          {{ `${option.valor}` }}
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
          </template>
        </template>
      </v-select>
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar mt-1"></span></a></li>
        <li><a @click="cancel"><span class="cancelar mt-1"></span></a></li>
      </ul>
  </span>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AddDataCommonNames',
  data: function () {
    return {
      languageList: [],
      commonName: {
        common_name_id: 0,
        language_id: 1,
        common_name: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelAddCommonName')
    },
    salve: function () {
      this.$emit('salveAddCommonName', this.commonName)
    },
    getLanguage: function () {
      axios.get('/language/getall')
        .then(response => {
          this.languageList = response.data.items.map(i => {
            const item = {
              language_id: i.language_id,
              valor: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.valor = i.en
                break
              case 'es':
                item.valor = i.es
                break
              case 'pt':
                item.valor = i.pt
                break
            }
            return item
          })
        })
    }
  },
  mounted () {
    this.getLanguage()
    this.$refs.common_name.focus()
  }
}
</script>
