<template>
  <div>
    <Destaque/>
    <Conteudo/>
  </div>
</template>

<script>
import Destaque from './Destaque'
import Conteudo from './Conteudo'
export default {
  name: 'Home',
  components: { Destaque, Conteudo }
}
</script>
