export const ptBR = {
  message: {
    requiredField: 'Campo obrigatório',
    noRecords: 'Nenhum registro encontrado!',
    confirmDel: 'Tem certeza de que deseja excluir?',
    confirmApproval: 'Tem certeza de que deseja aprovar esta ocorrência?',
    noResultsFor: 'Nenhum resultado para'
  },
  login: {
    username: 'Usuário',
    password: 'Senha',
    login: 'Login',
    entrar: 'Entrar',
    descricao: 'Entre com seus dados de acesso no formulário abaixo:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Voltar',
    outro: 'Outro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acesse a base de dados',
    conhecaNossaRede: 'Rede Latino Americana',
    baseDadosNacional: 'Base de Dados Nacional',
    politicaDeDados: 'Política de dados',
    comiteCientifico: 'Comitê científico',
    colabore: 'Colabore',
    peixesAquicultura: 'Peixes de aquicultura',
    pets: 'Pets',
    especiesExoticas: '42 Especies Exóticas Invasoras en Uruguay',
    especiesPrioritarias: 'Especies invasoras prioritarias de actuación',
    mascotas: 'Mascotas',
    acuarismo: 'Acuarismo',
    forrajeras: 'Especies forrajeras',
    plantasForrageiras: 'Plantas forrageiras',
    plantasOrnamentais: 'Plantas ornamentais',
    usoFlorestal: 'Uso florestal',
    tutorial: 'Tutorial',
    especies: 'Espécies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referências',
    projetos: 'Projetos',
    saibaMais: 'Saiba mais',
    contida: 'Contida',
    casual: 'Casual',
    estabelecida: 'Estabelecida',
    invasora: 'Invasora',
    exibirListagem: 'Exibir listagem',
    concluir: 'Concluir',
    fechar: 'Fechar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clique aqui',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Exibir 10 registros',
    exibir25Reg: 'Exibir 25 registros',
    exibir50Reg: 'Exibir 50 registros',
    exibir100Reg: 'Exibir 100 registros',
    limpar: 'Limpar',
    buscar: 'Buscar',
    addColaborador: 'Adicionar colaborador/a',
    nome: 'Nome',
    sobrenome: 'Sobrenome',
    aprovarColaborador: 'Aprovar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidade',
    instituicao: 'Instituição',
    endereco: 'Endereço',
    estado: 'Estado',
    cidade: 'Cidade',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Telefone 1',
    telefone2: 'Telefone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Mídia social 1',
    midiaSocial2: 'Mídia social 2',
    midiaSocial3: 'Mídia social 3',
    observacoesAdicionais: 'Observações adicionais',
    adicionar: 'Adicionar',
    editando: 'Editando',
    acoes: 'Ações',
    usuarixs: 'Usuárixs',
    adicionarUsuario: 'Adicionar usuárix',
    descricao: 'Descrição',
    ativo: 'Ativo',
    sim: 'Sim',
    nao: 'Não',
    salvarUsuarix: 'Salvar usuárix',
    senha: 'Senha',
    observacoes: 'Observações',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrator',
    taxonomia: 'Taxonomia',
    nomeCientifico: 'Nome científico',
    adicionarEspecie: 'Adicionar espécie',
    genero: 'Gênero',
    familia: 'Família',
    ordem: 'Ordem',
    classe: 'Classe',
    phylumDivisao: 'Phylum / Divisão',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origem',
    descricaoInvalida: 'Descrição inválida',
    selecioneFamilia: 'Por favor, selecione uma Família.',
    selecioneOrdem: 'Por favor, selecione uma Ordem.',
    selecioneClasse: 'Por favor, selecione uma Classe.',
    selecioneDivisao: 'Por favor, selecione um Phylum / Divisão.',
    selectioneReino: 'Por favor, selecione um Reino.',
    selectioneGenero: 'Por favor, selecione um Gênero.',
    addReferencia: 'Adicionar referência',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; ZALBA, S. M.)',
    aprovarReferencia: 'Aprovar referência',
    salvarReferencia: 'Salvar referência',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; ZALBA, S. M.)',
    autores: 'Autorxs',
    local: 'Local (Ex. Asunción / África del Sur: Ciudad del Cabo)',
    localFiltro: 'Local',
    edicao: 'Edição (ex. 2 ed.)',
    editora: 'Editora',
    revista: 'Revista',
    anoPublicacao: 'Ano da publicação',
    meioDivulgacao: 'Meio de divulgação',
    volume: 'Volume (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palavra-chave',
    anoPublic: 'Ano publicação',
    addProjeto: 'Adicionar projeto',
    aprovarProjeto: 'Aprovar Projeto',
    salvarProjeto: 'Salvar projeto',
    contato: 'Contato',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descrição',
    dataInicio: 'Data início',
    dataTermino: 'Data término',
    comentarios: 'Comentários',
    anoInicio: 'Ano de início',
    anoTermino: 'Ano de término',
    ocorrencias: 'Ocorrências',
    exportarDados: 'Exportar dados',
    addOcorrencia: 'Adicionar ocorrência',
    visualizarOcorrencia: 'Visualizar ocorrência',
    alterarOcorrencia: 'Alterar ocorrência',
    ocorrenciasVinculadas: 'Ocorrências vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Espécie',
    aprovarOcorrencia: 'Aprovar ocorrência',
    salvarOcorrencia: 'Salvar ocorrência',
    colaborador: 'Colaborador/a',
    municipio: 'Município',
    municipioSede: 'Município (sede)',
    municipioCentroide: 'Município (centróide)',
    referenciaLocal: 'Referência local',
    referencia: 'Referência',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'As coordenadas correspondem',
    distanciaAproximada: 'Distância aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Bacia hidrográfica',
    descricaoInvasao: 'Descrição da invasão',
    abundancia: 'Abundância',
    situacaoPopulacional: 'Situação populacional',
    manejo: 'Manejo',
    dataEntrada: 'Data de entrada',
    dataObservacao: 'Data de observação (ex. 2020 ou 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'É um espécime de coleção zoológica ou herbário',
    selecioneTipo: 'Selecione o tipo',
    herbario: 'Herbário',
    museu: 'Coleção zoológica',
    numeroExemplaInstituicao: 'Número de exemplar (Instituição)',
    numeroExemplarColetor: 'Número de exemplar (Coletor)',
    selecioneOpcao: 'Selecione uma opção...',
    pointList1: 'A um ponto de ocorrência de um ou mais indivíduos da espécie',
    pointList2: 'Ao centro de um grupo de indivíduos da espécie',
    pointList3: 'A um ponto próximo da ocorrência, a uma distância aproximada de:',
    coordinatesSourceList1: 'A - Uma unidade política ou administrativa',
    coordinatesSourceList2: 'B - O local real da ocorrência',
    ultimoRegistroBaseDados: 'Último registros na base de dados',
    ultimosAcessos: 'Últimos acessos',
    fichaTecnicaEspecie: 'Ficha técnica das espécies',
    fichaTecnicaEspecieMMA: 'Ficha técnica das espécies MMA versão 1',
    fichaTecnicaEspecieMMA2: 'Ficha técnica das espécies MMA versão 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Dados insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponíveis para exportação:',
    biologiaEcologia: 'Biologia e ecologia',
    usoEconomico: 'Uso econômico',
    usoEconomicoDescricao: 'Uso econômico - descrição',
    invasaoBiologica: 'Invasão biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nome científico + sinônimos',
    nomesPopulares: 'Nomes populares',
    reproducao: 'Reprodução',
    dispersao: 'Dispersão',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes mais suscetíveis à invasão',
    tipoIntroducao: 'Tipo de introdução',
    causaIntroducao: 'Causa de introdução',
    localIntroducao: 'Local de introdução',
    anoIntroducao: 'Ano da introdução',
    marinho: 'Marinho',
    dulcicola: 'Água doce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar espécie',
    salvarEspecie: 'Salvar espécie',
    data: 'Data',
    confiabilidade: 'Confiabilidade',
    resultadosAnalisesRisco: 'Resultados de análises de risco',
    risco: 'Risco',
    areaOrigem: 'Área de origem',
    areaDistribuicaoNatural: 'Área de distribuição natural',
    ambientesNaturais: 'Ambientes naturais',
    descricaoEspecie: 'Descrição da espécie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes mais suscetíveis à invasão - descrição',
    outrosLocaisOndeEspecieInvasora: 'Outros locais onde a espécie é invasora',
    impactosEcologicos: 'Impactos ecológicos',
    impactosEconomicos: 'Impactos econômicos',
    impactosSociais: 'Impactos sociais',
    impactosSaude: 'Impactos à saúde',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nível de confiança da avaliação EICAT',
    referenciaEICAT: 'Referência EICAT',
    dataEICAT: 'Data EICAT (ex. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nível de confiança da avaliação SEICAT',
    referenciaSEICAT: 'Referência SEICAT',
    dataSEICAT: 'Data SEICAT (ex. 2021)',
    digitarSomenteAno: 'Digitar somente o ano - 2021',
    viasCDBCategorias: 'Vias/vetores CDB - categorias',
    viasIntroducao: 'Vias de introdução e dispersão',
    viasVetoresCDBSubcategorias: 'Vias/vetores CDB - subcategorias',
    vetoresIntroducao: 'Vetores de introdução e dispersão',
    introducao: 'Introdução',
    adicionarIntroducao: 'Adicionar introdução',
    ano: 'Ano',
    descricaoIntroducao: 'Descrição da introdução',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Excluir',
    medidasPreventivas: 'Medidas preventivas',
    medidasPreventivasMMA: 'Medidas preventivas MMA',
    medidasDeteccaoPrecoce: 'Medidas de detecção precoce',
    controleMecanico: 'Controle mecânico',
    controleQuimico: 'Controle químico',
    controleBiologico: 'Controle biológico',
    distribuicaoPais: 'Distribuição no país',
    vejaMapaOcorrencias: 'Veja o mapa de ocorrências',
    ecologiaUso: 'Ecologia e uso',
    introducaoDispersao: 'Introdução e dispersão',
    analiseRisco: 'Análise de risco',
    nomeComum: 'Nome comum',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinônimos',
    autor: 'Autor/a',
    subespecie: 'Subespécie',
    autorSubespecie: 'Autor/a da subespécie',
    pendenteAnalise: 'Pendente de análise',
    cancelarVoltar: 'Cancelar / Voltar',
    versaoAnterior: 'Versão anterior',
    versaoAtual: 'Versão atual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomia - Lista completa de espécies',
    consultaEspeciesOrigemAmbientes: 'Espécies, origem, ambientes de invasão e impactos',
    consultaEspeciesManejo: 'Espécies e alternativas de manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica da espécie sem ocorrências',
    consultaEspeciesOcorrencias: 'Espécies e locais de ocorrência, com coordenadas',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocorrências App',
    dataCadastro: 'Data de cadastro',
    criadoPor: 'Criado por',
    nivelCerteza: 'Nível de certeza',
    byApp: 'Por aplicativo móvel',
    verDados: 'Ver dados',
    appuser: 'App user',
    profissao: 'Profissão',
    colobadorJaCadastrado: 'Colaborador/a já cadastradx',
    especialidadeApp: 'Especialidade App',
    cep: 'CEP',
    categoriaCDB: 'Categoria CDB',
    subCategoriaCDB: 'Subcategoria CDB',
    saibaMas: 'Saiba mas',
    erradicada: 'Erradicada',
    mensagemEnviada: 'Mensagem enviada com sucesso.'
  },
  home: {
    destaque1: 'Banco de Dados Nacional de Espécies Exóticas Invasoras do Paraguai',
    destaqueDescricao1: 'Este sistema de banco de dados recebe contribuições de pessoas comprometidas com a conservação da biodiversidade. Convidamos você a visitar nossa página!',
    destaque2: 'Desde 2005 documentando espécies exóticas invasoras',
    destaqueDescricao2: 'Esta base de dados foi desenvolvida e atualizada com recursos de projetos internacionais. Atualmente conta com um Comitê Científico de apoio à gestão.',
    destaque3: 'Como funciona?',
    destaqueDescricao3: 'As consultas são de acesso livre, mas por favor cite a base de dados como referência no seu trabalho! Profissionais podem solicitar login para contribuir.',
    destaque4: 'Nosso propósito',
    destaqueDescricao4: 'Você pode fazer consultas sobre espécies exóticas invasoras que impactam o meio ambiente, pontos de ocorrência no Paraguai, especialistas, projetos e referências.',
    titulo: 'Consulte as espécies, filtre e exporte dados',
    conteudo: 'Aqui você encontra informações sobre a área de origem das espécies exóticas invasoras, características ecológicas e biológicas, ambientes invadidos, onde são invasoras no mundo e no Paraguai, incluindo um mapa de ocorrências já registradas, histórico de introdução e uso, vetores e vias de introdução e dispersão, informações sobre manejo e referências. Encontra filtros para seleção de informações e pode exportar os dados de interesse para diferentes formatos.',
    conteudoEspecies: 'Lista de espécies, características ecológicas, área de origem, onde é invasora mundo afora, vias e vetores de introdução, análise de risco, usos, opções de manejo, ocorrência no Paraguai, referências.',
    conteudoColaboradores: 'Cadastro de pessoas que já enviaram dados para a base de dados. Se você precisar falar com um especialista ou saber mais sobre uma ocorrência, busque contatos aqui.',
    conteudoReferencias: 'Listagem completa de referências utilizadas como base para o preenchimento das informações sobre espécies exóticas invasoras na base de dados.',
    conteudoProjetos: 'Projetos de pesquisa e de manejo desenvolvidos para espécies exóticas invasoras no Brasil. Se você tem um projeto em andamento, escreva-nos para que possamos fazer a inclusão.',
    titulo1: 'Sepa más sobre algunas de las especies exóticas invasoras del Paraguay',
    conteudo1: 'Las especies exóticas invasoras pertenecen a todos los grupos de organismos y son introducidas de forma accidental o voluntaria por una variedad de razones, sus efectos también son muy diversos.'
  },
  areaParceiros: {
    titulo: 'Rede de Bases de Dados de Espécies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai',
    brasil: 'Brasil',
    jamaica: 'Jamaica'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Rede de bases de dados',
    paragrafo1: 'Esta rede de bases de dados tem origem no ano de 2005, como parte da rede temática sobre espécies exóticas invasoras I3N (IABIN Invasives Information Network), que era parte da Rede Inter-Americana de Informação sobre Biodiversidade (IABIN), estabelecida pelos governos dos países das Américas no ano de 2001.',
    paragrafo2: 'Cada país tinha um/a Líder na rede, cuja função era desenvolver uma base de dados nacional a fim de compartilhar informação sobre espécies exóticas invasoras.',
    paragrafo2_1: 'As pessoas na liderança das bases de dados nacionais são:',
    marcador1: 'Brasil: Dra. Sílvia R. Ziller, Fundadora e Diretora Executiva do Instituto Hórus de Desenvolvimento e Conservação Ambiental (desde 2004);',
    marcador2: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (desde 2003);',
    marcador3: 'Uruguai: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (desde 2005), e o M.Sc. Marcelo Iturburu, Coordenador do Comitê de Espécies Exóticas Invasoras Ministério do Meio Ambiente (desde 2018);',
    marcador4: 'Paraguai: Hugo Fernando del Castillo, Guyra Paraguay Conservação de Aves (desde 2006);',
    marcador5: 'Jamaica: Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (desde 2007).',
    paragrafo3: 'A IABIN foi financiada por um projeto do Banco Mundial (GEF) entre os anos de 2004 e 2011, então extinta. Apesar do término da iniciativa, alguns dos Líderes da rede seguem mantendo as bases de dados nacionais (Brasil, Argentina, Uruguai, Paraguai e Jamaica).',
    paragrafo4: 'No ano de 2021, recebemos um recurso da Secretaria da Convenção sobre Diversidade Biológica, como parte da iniciativa BioBridge, para revitalizar e atualizar as bases de dados da Argentina, do Brasil, do Paraguai e do Uruguai. A interface foi atualizada e novas funcionalidades foram embutidas nas bases de dados.',
    paragrafo5: 'Os desenvolvedores da Base de Dados são:',
    marcador6: 'o programador João Scucato, de Curitiba – PR, que desenvolveu a primeira versão em Microsoft Access, 2004-2005;',
    marcador7: 'o programador Alejandro Moreno, da Argentina, está envolvido com a manutenção da rede desde 2006. Manteve cópias dos arquivos e resgatou conteúdos do Paraguai e Uruguai para esta nova edição, além de desenvolver a',
    marcador7_1: 'Plataforma Comum',
    marcador7_2: 'dos países da rede.',
    marcador8: 'o Designer Rafael Moura e pelo Programador Thiago Lôbo, vinculados à',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'em Florianópolis – SC, Brasil. ',
    marcador9: 'o programador Leonardo Rotondano, desenvolvedor do aplicativo para telefone celular junto com o programador backend Thiago Lôbo',
    paragrafo6: 'Esperamos voltar a ampliar a rede para mais países das Américas a fim de que mais dados sobre espécies exóticas invasoras fiquem disponíveis para fins de conhecimento público, gestão governamental e manejo aplicado.',
    paragrafo7: 'Apoio',
    paragrafo8: 'Período 2021',
    paragrafo9: 'Período 2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de dados nacional',
    titulo1: 'Base de Dados de Especies Exóticas Invasoras no Paraguai',
    paragrafo1: 'Esta base de dados e a interface web correspondente foram desenvolvidas pela Universidad Nacional del Sur (Argentina) em colaboração com o Instituto Hórus de Desenvolvimento e Conservação Ambiental (Brasil) com apoio do Serviço Geológico (USGS) e da Infraestrutura Nacional de Informação Biológica dos Estados Unidos (NBII), como parte da rede I3N (Rede de Informação sobre Espécies Invasoras) da IABIN (Rede Interamericana de Informação sobre Biodiversidade).',
    paragrafo2: 'Em 2020 foram retomados os esforços para coordenar a gestão da informação sobre espécies exóticas invasoras com outros países da região. Com o apoio da Iniciativa BioBridge da Convenção sobre Diversidade Biológica (CDB-PNUMA), este sistema de informação foi atualizado e melhorado em conjunto com iniciativas similares no Brasil, no Paraguai e no Uruguai. Foi desenvolvida uma',
    paragrafo2_1: 'plataforma de busca unificada',
    paragrafo2_2: 'de informações como um passo a mais para consolidar esforços conjuntos para enfrentar o desafio de invasões biológicas com base em conhecimentos sólidos e atualizados sobre espécies exóticas invasoras na região e contribuir asi para o alcance da Meta 8 da Agenda 2030 / ODS e da Meta 9 de Aichi.',
    titulo5: 'Apoio'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de dados',
    titulo: 'Conceitos e referências',
    criterio: 'Critérios para a inclusão de espécies',
    construccion: 'Construção colaborativa',
    validacao: 'Validação de dados',
    paragrafo1: 'Para efeitos deste sistema de dados, são adotadas as seguintes definições, de acordo com a Convenção sobre Diversidade Biológica:',
    marcador1: ' espécie nativa: aquela que se encontra dentro da área de distribuição geográfica onde evoluiu e faz parte de uma comunidade biológica considerada natural ou semi-natural;',
    marcador2: ' espécie exótica: aquela que está fora de sua área natural passada ou presente;',
    marcador3: ' espécie exótica invasora: qualquer espécie exótica que afeta ou ameaça habitats, ecossistemas ou espécies, causando alterações em ambientes naturais;',
    marcador3_1: ' espécie criptogênica: aquela espécie cuja origem não pode ser estabelecida com certeza e, portanto, não pode ser classificada como nativa ou exótica.',
    paragrafo2: 'Os termos de vocabulário controlado relacionados com ambientes de invasão preferencial, vetores e vias de dispersão, formas biológicas, impactos e métodos de controle foram definidos de acordo com os termos usados na',
    paragrafo2_1: 'Base Global de Espécies Invasoras',
    paragrafo2_2: 'do Grupo Especialista em Espécies Invasoras vinculado à IUCN',
    paragrafo3: 'As principais referências taxonômicas são consultadas nos sistemas propostos pelo',
    paragrafo3_1: 'Jardim Botânico de Missouri (Missouri Botanical Garden)',
    paragrafo3_2: 'e pelo Sistema Integrado de Informação Taxonômica (ITIS).',
    paragrafo3_3: 'As referências suplementares incluem o Registro Mundial de Espécies Marinhas',
    paragrafo3_4: 'Fishbase',
    paragrafo3_5: 'AmphibiaWeb',
    paragrafo3_6: 'Algaebase',
    paragrafo3_7: 'Base de Dados Global de Espécies Exóticas Invasoras',
    paragrafo3_8: 'e o Compêndio de Espécies Invasoras da CABI',
    paragrafo6: 'Para cada ponto de ocorrência as espécies são classificadas como:',
    marcador4: ': a presença é limitada ou restrita a sistemas de cultivo ou reprodução, ou vive dependente diretamente de pessoas, por exemplo como num laboratório, um animal de estimação ou de aquário ou uma planta de jardim, sem evidência de escape de indivíduos naquele local;',
    marcador5: ': quando a espécie foi avistada em ambientes naturais ou seminaturais, fora de sistemas de cultivo ou reprodução, ainda sem evidências de ter estabelecido um núcleo populacional;',
    marcador6: ': quando a espécie se reproduz efetivamente, formando uma população autossustentável, mas permanece em uma área limitada, próxima ao local de introdução local;',
    marcador7: ': quando a espécie avança, de forma autônoma ou assistida por vetores antrópicos, em direção a outras áreas além do ponto local de introdução.',
    marcador7_1: ': quando a espécie já não existe no local em função de intervenções de manejo.',
    paragrafo7: 'É importante notar que o status de invasão é um atributo da localidade de ocorrência, não da espécie. Dessa forma, a mesma espécie pode se comportar como invasora em uma localidade, ser contida em outro local e ter sido detectada na natureza em um terceiro local.',
    paragrafo11: 'A Base de Dados de Espécies Exóticas Invasoras do Paraguai inclui espécies exóticas e criptogênicas detectadas em ambientes naturais ou seminaturais do país, bem como aquelas que, embora permanecendo contidas em sistemas de cultivo ou de produção, têm histórico de invasão em outros países. Também estão incluídas espécies que, sendo nativas de alguma região do país, foram translocadas para outras regiões, bacias ou ecossistemas dentro do próprio país. Todas as espécies estão associadas a pelo menos uma localidade de ocorrência no país. Em alguns casos, esse local é o aeroporto ou porto de entrada, quando não há dados pós-importação.',
    paragrafo11b: 'O foco desse sistema é a conservação da diversidade biológica e, portanto, somente são incluídas espécies exóticas caracterizadas como ervas daninhas, pragas ou patógenos de pessoas ou animais domésticos quando também tenham sido detectadas em ambientes naturais ou impactando espécies nativas, ou com histórico de invasão nessas áreas. Ainda assim, é muito comum que espécies que afetam a estrutura, composição e funcionamento de ecossistemas naturais e seminaturais também tenham impactos na economia, na saúde e nos valores culturais.',
    paragrafo13: 'As bases de dados deste sistema são atualizadas graças à contribuição de informações por colaboradorxs voluntárixs distribuídxs por todo o país. Essxs colaboradorxs podem contribuir com informações sobre novas espécies, novos locais de ocorrência de espécies já detectadas no território nacional, projetos, especialistas ou publicações sobre o assunto. As informações de ocorrência ficam vinculadas aos dados de quem as forneceu, como um reconhecimento de suas contribuições para o conhecimento do estado do problema das invasões biológicas no país e como uma forma de promover a comunicação direta entre pessoas com conhecimento e interesse na o assunto.',
    paragrafo14_4: 'Os administradores da base de dados são responsáveis por preencher as informações sobre as características biológicas de cada espécie, os ambientes preferenciais de invasão, vetores e rotas de introdução e dispersão, impactos e técnicas de controle, entre outros.',
    paragrafo14_5: 'Os dados são revisados continuamente, a cada nova unidade de informação recebida. Novas espécies ou locais de ocorrência que impliquem em mudanças significativas na distribuição de uma espécie ou na colonização de novos ambientes são confirmados por consulta com especialistas do Comité científico ou outros especialmente convocados com base em sua especialidade.',
    paragrafo15: 'A confiabilidade dos dados é uma das principais preocupações dos administradores deste sistema. A validação da informação é avaliada em dois níveis principais: no que diz respeito à correta identificação de cada táxon e no que diz respeito à exatidão do sítio a que cada dado se refere. Para tal, utilizam-se as normas e critérios acordados com os restantes sistemas que integram a',
    paragrafo15_1: 'Rede Latino Americana de informação sobre Espécies Exóticas Invasoras',
    paragrafo16: 'Critérios para validar a identidade taxonômica de cada táxon',
    marcador8: 'O fornecedor dos dados (autor da publicação ou colecionador do espécime) pode ser considerado um especialista do grupo em questão ou possui experiência equivalente?',
    marcador9: 'A ocorrência é documentada por meio de fotografia que permita a identificação confiável do organismo em questão?',
    marcador10: 'A ocorrência é documentada em publicação indexada e revisada por pares ou em documento técnico elaborado ou revisado por especialistas?',
    marcador11: 'A informação corresponde a um espécime de herbário ou a um espécime depositado em coleção de museu?',
    marcador12: ' A ocorrência corresponde a uma espécie já registrada para o país?',
    marcador13: 'A taxonomia do grupo ao qual a espécie pertence é estável e conhecida?',
    marcador14: 'As características das espécies permitem uma identificação relativamente simples?',
    paragrafo17: 'Uma resposta positiva a pelo menos uma das questões 1, 2, 3 ou 4 leva diretamente à recomendação de considerar os dados como validados. Em caso de resposta negativa a estas quatro questões, a validação será obtida apenas com respostas positivas às três últimas (5, 6 e 7).',
    paragrafo18: 'Critérios para validar o local de ocorrência de espécies detectadas',
    marcador15: 'Os dados incluem coordenadas geográficas? São consideradas as coordenadas tomadas diretamente no local de observação e outras que correspondem a uma referência geográfica próxima (conforme indicado).',
    marcador16: 'Os dados incluem uma descrição do local que permita sua identificação confiável?',
    marcador17: 'A ocorrência é documentada por fotografia que permite a identificação confiável do local?',
    marcador18: 'As coordenadas geográficas fornecidas coincidem com a localização do local descrito e / ou fotografado?',
    marcador19: 'A espécie já é citada para o país e, nesse caso, a localidade está incluída em sua área de distribuição conhecida ou em local compatível com áreas de presença conhecida e com a capacidade de dispersão da espécie?',
    marcador20: 'As condições ecológicas do local, inferidas com base na localização indicada, são compatíveis com os requisitos gerais de nicho da espécie?',
    paragrafo19: 'Os dados são considerados validados, do ponto de vista da sua localização geográfica, se:',
    marcador21: 'as respostas às perguntas 1, 2 e / ou 3 e 4 são positivas (os dados incluem coordenadas geográficas, uma referência inequívoca ao local é fornecida e / ou uma fotografia é fornecida que permite a identificação confiável do local e as coordenadas fornecidas coincidem com a localização do local descrito ou fotografado, ou',
    marcador22: 'uma resposta positiva ou negativa é atribuída à questão 1 (coordenadas geográficas são fornecidas ou não), uma resposta negativa às questões 2 e 3 (o local não é descrito em detalhes ou uma fotografia é anexada para permitir sua localização), mas as perguntas 5 e 6 foram respondidas positivamente (a espécie já foi citada para o país e a localidade está incluída dentro de sua faixa de distribuição conhecida ou em um local que seja compatível com os locais de presença conhecida e com sua capacidade de dispersão, e as condições ecológicas do local são compatíveis com os requisitos gerais de nicho da espécie).',
    paragrafo20: 'O local de ocorrência não será validado se: ',
    marcador23: 'a resposta à questão 1 e às questões 2 e / ou 3 forem positivas, mas a resposta à questão 4 for negativa (as coordenadas não coincidem com o local descrito ou fotografado), ou',
    marcador24: 'as respostas às questões 2 e 3 forem negativas, assim como negativas para pelo menos uma das questões 5 ou 6 (os dados não atendem a uma ou nenhuma dessas duas condições: 1- A espécie já é citada para o país e, nesse caso, a localidade esteja incluída em sua faixa de distribuição conhecida ou em um local compatível com os locais de presença conhecida e com sua capacidade de dispersão, 2- o local atende a condições ecológicas compatíveis com os requisitos gerais de nicho da espécie).',
    paragrafo21: 'Por fim, os dados serão validados se a análise dos dois critérios (taxonomia e localização) resultar positiva. ',
    paragrafo22: 'A validação não se propõe como uma regra de inclusão ou não de um dado, mas como um descritor que acompanha cada local de ocorrência. Cada local de ocorrência de cada espécie está associada ao descritor “dados validados” ou “dados não validados”. É importante entender que essa classificação é temporária, pois os dados podem ser confirmados e validados caso atendam aos critérios definidos em algum momento após serem registrados no sistema. Para facilitar a visualização do estado dos dados, o sistema de mapeamento de distribuição de ocorrências mostra as localidades validadas em verde e as não validadas em cinza.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comitê Científico',
    paragrafo1: 'A Base de Dados Nacional de Espécies Exóticas Invasoras conto com o apoio de um grupo de especialistas em diversas áreas do conhecimento para sua criação. Nesse grupo estão taxonomistas ou especialistas em grupos específicos de espécies, membros do Museu Nacional de História Natural do Paraguai (MNHNP) e do Ministério do Ambiente e Desenvolvimento Sustentável (MADES), assim como profissionais com experiência de campo cujo conhecimento é de alta relevância para o aporte e a verificação de informações.',
    paragrafo2: 'Com a renovação da Base de Dados Nacional de Espécies Exóticas Invasoras em 2021, os profissionais que colaboraram na seleção das espécies e no aporte de dados na primeira edição, no ano de 2006, serão encarregados da manutenção e da atualização da base de dados.',
    paragrafo3: 'Para tanto, foi conformado um Comitê Científico cujo papel é prover apoio para a validação de dados, aportar dados de interesse para a base de dados e ajudar a construir e manter a rede de colaboradores para viabilizar a atualização das informações e aumentar o número de aportes de dados.',
    paragrafo4: 'O Comité Científico está formado por:',
    li1: 'Hugo del Castillo, Eng., Ornitólogo e oficial de bases de dados de biodiversidade de Guyra Paraguay, Administrador da Base de Dados;',
    li2: 'José Luis Cartes, Biólogo, M.Sc. Mastozoólogo e Diretor Executivo de Guyra Paraguay;',
    li3: 'Pier Cacciali, Biólogo, M.Sc., PhD Herpetólogo e Coordenador do Programa de Espécies de Guyra Paraguay;',
    li4: 'Gloria Céspedes. Bióloga, M.Sc. Botânica;',
    li5: 'Juana de Egea, Bióloga, M.Sc. Botânica;',
    li6: 'John Kochalka, Biólogo, especialista em Aracnídeos;',
    li7: 'Héctor Vera, Biólogo, PhD Ictiologia;',
    li8: 'Bolívar Garcete, Biológo, PhD em invertebrados;',
    li9: 'Freddy Bauer, Biólogo, Diretor de Biodiversidade do Ministério do Ambiente e Desenvolvimento Sustentável (MADES).',
    titulo1: 'Atribuições do Comitê Científico',
    li10: 'Decisão sobre a inclusão de espécies novas na base de dados;',
    li11: 'decisão sobre a eliminação de espécies por falta ou inconsistência de dados;',
    li12: 'apoio à validação de informações da base de dados;',
    li13: 'apoio na revisão e busca de dados;',
    li14: 'apoio à busca e consulta a colaboradores e especialistas para aporte e validação de dados;',
    li15: 'recomendações de melhorias na base de dados;',
    li16: 'melhoria contínua do nível de informação.'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Contribua com dados',
    paragrafo1: 'Nossa base de dados é atualizada graças a contribuições de especialistas e observadores da natureza distribuídos em todo o território nacional. Antes de serem publicadas, as informações fornecidas são validadas segundo critérios acordados em nível regional. Você pode enviar dados sobre espécies ainda não incluídas neste sistema ou sobre novas localidades de ocorrência baixando nosso aplicativo (Invasoras PY) ou enviando um e-mail para ',
    paragrafo2: 'Por e-mail você também pode enviar publicações ou informação sobre projetos que abordam invasões biológicas na Argentina. Os provedores de dados que contribuem regularmente podem receber um nome de usuário e senha para inserir informações diretamente na base de dados. Ajude-nos a enfrentar o desafio de espécies exóticas invasoras com base em informações completas, atualizadas e confiáveis!',
    paragrafo3: 'Muito obrigadx!'
  },
  especiesInstitucional: {
    titulo: 'Buscar espécies',
    paragrafo1: 'Busque aqui informação detalhada sobre espécies exóticas invasoras presentes no Paraguai, especialistas, projetos de pesquisa e manejo e publicações de referência.',
    paragrafo2: 'Se você tiver informações ou publicações, contribua para melhorar esta base de dados.',
    paragrafo2_1: 'Entre em contato conosco!',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Como citar esta base de datos:',
    paragrafo4_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Consultado DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)',
    tutorialTitulo1: 'Como citar a base de dados',
    tutorialDescricao1: 'Os dados são de acesso livre, mas por favor cite a base de dados em suas publicações para que mais pessoas possam conhecer e aproveitar as informações.',
    tutorialTitulo2: 'Use os filtros',
    tutorialDescricao2: 'Digite um nome científico e encontre informação sobre uma espécie.',
    tutorialTitulo3: 'Use os filtros: buscar aqui',
    tutorialDescricao3: 'Escolha filtros e faça combinações para selecionar dados por local, forma biológica, uso, grupo taxonômico e outros.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este é o número de espécies exóticas invasoras na base de dados que resultou da consulta com uso de filtros.',
    tutorialDescricao5: 'Aqui você pode definir o número de registros a serem exibidos por página.',
    tutorialTitulo6: 'Lista de espécies exóticas invasoras',
    tutorialDescricao6: 'Lista de todas as espécies ou resultados do uso de filtros. Clique na lupa à direita do nome da espécie para ver os dados completos.',
    tutorialTitulo6_1: 'Exportar dados',
    tutorialDescricao6_1: 'Aqui você pode filtrar dados e selecionar o formato para exportação.',
    tutorialTitulo7: 'Taxonomia',
    tutorialDescricao7: 'Classificação taxonômica da espécie selecionada, nomes comuns, sinônimos e uma foto quando for possível!',
    tutorialTitulo8: 'Ecologia e uso',
    tutorialDescricao8: 'Descrição, biologia e ecologia da espécie selecionada, de onde é originária, que ambientes invade e usos econômicos.',
    tutorialTitulo9: 'Introdução e dispersão',
    tutorialDescricao9: 'Como a espécie chegou ao local onde é invasora, por que foi introduzida e como se dispersa para outras áreas.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientais, econômicos, à saúde e culturais. Classificação de impactos ambientais com base no protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detecção precoce, controle mecânico, químico e biológico para a espécie selecionada.',
    tutorialTitulo12: 'Análise de risco',
    tutorialDescricao12: 'Resultados de análises de risco para invasão biológica realizados para a espécie selecionada.',
    tutorialTitulo13: 'Ocorrências',
    tutorialDescricao13: 'Locais, ambientes, áreas protegidas, provincias onde a espécie ocorre no país e estágio do processo de invasão – veja o mapa de distribuição!',
    tutorialDescricao14: 'Lista de referências usadas para compilar dados sobre a espécie selecionada e outras publicações sobre a espécie.',
    tutorialTitulo15: 'Projetos',
    tutorialDescricao15: 'Lista de projetos de pesquisa e manejo que incluem a espécie selecionada.'
  },
  colaboradoresInstitucional: {
    titulo: 'Busca por colaboradorxs',
    paragrafo1: 'Pessoas que enviam informações para a Base de Dados, coletam espécimes de museu ou herbário ou são autorxs de publicações utilizadas como fontes de dados. ',
    paragrafo2: '',
    paragrafo3: 'Como citar esta base de dados:',
    paragrafo4_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Consultado DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)'
  },
  peixesAquarioInstitucional: {
    titulo: 'Peixes de aquário',
    paragrafo1: 'Os peixes de aquário são os “animais de estimação”, ou “pets”, mais numerosos do mundo atualmente. O Brasil é um grande fornecedor de peixes ornamentais devido à riqueza  da bacia amazônica e do Pantanal. Ainda assim, a maior parte dos peixes comercializados é exótico aos locais de venda. Esses animais jamais devem ser liberados em lagos, rios, ou no mar porque podem predar espécies nativas, alterar o ambiente aquático ou transmitir doenças e parasitos.',
    paragrafo2: 'Além disso, podem se tornar invasores, como é o caso do peixe-leão, que escapou de um aquário na Flórida, EUA, e agora está em todo o mar do Caribe e há risco de chegar à costa brasileira.',
    paragrafo3: 'É um risco grande para recifes de coral, como em Abrolhos e Fernando de Noronha. Jamais libere animais de estimação na natureza!',
    paragrafo4: 'Saiba mais',
    marcador1: 'Peixes ornamentais exóticos ameaçam a biodiversidade',
    marcador2: 'Exóticos invasores',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - carazinho',
    paragrafo5b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenil)',
    paragrafo6b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae carazinho',
    paragrafo7b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - mato-grosso',
    paragrafo8b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - oscar, apaiari',
    paragrafo9b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - paulistinha',
    paragrafo10b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - peixe-leão',
    paragrafo11b: 'Foto: Instituto Hórus'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Peixes de aquicultura',
    paragrafo1: 'A aquicultura é a principal causa de introdução de peixes de água doce no Paraguai. Muitas espécies são bastante agressivas e têm histórico de extinguir espécies nativas de peixes em diversos países do mundo, como a tilápia-do-nilo e o bagre-africano. O panga é um peixe da Ásia que com forte histórico de invasão em países asiáticos. Procure se informar antes de decidir que peixes vai consumir, de modo a não contribuir para os impactos que podem causar. ',
    paragrafo2: 'O problema é que as estruturas de criação – açudes e tanques – não são seguras e os peixes escapam para o ambiente natural, ou são até mesmo soltos intencionalmente durante o manejo dos tanques. Podem predar espécies nativas, alterar o ambiente aquático ou transmitir doenças e parasitos.',
    paragrafo3: 'Mais informação',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo3c: 'neste post',
    paragrafo4: 'Cichla kelberi tucunaré',
    paragrafo5: 'Coptodon rendalli tilápia',
    paragrafo6: 'Cyprinus carpio carpa-comum',
    paragrafo7: 'Micropterus salmoides - black bass',
    paragrafo7b: 'Foto: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus tilápia-do-nilo'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Pesca desportiva',
    paragrafo1: 'A pesca desportiva é uma atividade de lazer que, com frequência, envolve a introdução de espécies exóticas geralmente predadoras vorazes. Embora a introdução de espécies e a transposição de peixes para bacias hidrográficas onde não ocorrem seja proibida sem autorização do IBAMA (Portaria IBAMA 145-N/1998), muitos exemplares de peixes têm sido retirados de suas bacias hidrográficas de origem e levados a outras onde não são nativos. Esse processo gera impactos a peixes nativos por predação, competição e transmissão de parasitas e doenças, além de desequilíbrios ambientais severos.',
    paragrafo2: 'Associada à pesca desportiva está a introdução de espécies exóticas usadas como iscas vivas, que podem escapar do anzol ou mesmo ser jogadas nos rios ao final da atividade de lazer. Peixes e outras espécies usadas como isca viva de- vem ser usadas apenas se forem nativas do rio onde se realiza a atividade de pesca para evitar a introdução de espécies exóticas e impactos a espécies nativas.',
    paragrafo3: 'Jamais libere animais exóticos na natureza!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Foto: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - tucunaré',
    paragrafo5b: 'Foto: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - tucunaré',
    paragrafo6b: 'Foto: LEPIB - UEL PR',
    paragrafo7: 'Saiba mais'
  },
  petsInstitucional: {
    titulo: 'Pets',
    paragrafo1_1: 'O termo ',
    pet: 'pet',
    paragrafo1_2: ' se refere a animais de companhia, desde peixes de aquário até cães e gatos. Alguns animais incluídos na lista, especialmente cães e gatos, somente são considerados problemáticos quando encontrados no interior de áreas naturais onde predam a fauna nativa e causam impactos severos à conservação da biodiversidade.',
    paragrafo2: 'Não deixe seu animal de estimação ficar solto em áreas naturais!',
    paragrafo3: 'Trachemys scripta - tigre-dágua americano',
    paragrafo3b: 'Foto: Marcos Tortato',
    paragrafo4: 'Felis catus - gato doméstico',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Canis familiaris - cachorro doméstico',
    paragrafo5b: 'Foto: Marcos Tortato',
    paragrafo6: 'Trachemys dorbignyi - tigre-d´água',
    paragrafo6b: 'Foto: Marcos Tortato'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentais',
    paragrafo1: 'As plantas ornamentais geralmente representam mais de 50% das plantas exóticas invasoras introduzidas nos países. A escolha de plantas para jardinagem, sombra e arborização urbana pode contribuir para a conservação da natureza, o que inclui o abastecimento de água, estabilidade climática e muitos outros “serviços” que a natureza presta ao homem.',
    paragrafo2_1: 'Cultive plantas nativas da sua região! Conheça, evite e substitua plantas exóticas invasoras em seu jardim.',
    paragrafo4: 'Tecoma stans - ipê-de-jardim',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Furcraea foetida - piteira',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - comigo-ninguém-pode',
    paragrafo6b: 'Foto: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - aspargo',
    paragrafo7b: 'Foto: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - castanheira, amendoeira',
    paragrafo8b: 'Foto: Instituto Hórus'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Plantas forrageiras',
    paragrafo1: 'Muitas plantas foram introduzidas para uso como forragem, ou seja, para alimentar animais como bois, vacas, cabras, cavalos e burros. São principalmente gramíneas, entre elas a braquiária, que é um invasor muito agressivo em áreas naturais e degradadas, mesmo em áreas úmidas e pequenos riachos. Mas também há arbustos, como a leucena, e árvores, como a alfarroba, cujas vagens servem de alimento para cabras.',
    paragrafo2: 'Essas espécies foram disseminadas amplamente no mundo tropical e causam impactos importantes ao ambiente por modificar ciclos naturais de fogo, reduzir o volume de água e deslocar espécies nativas de plantas, formando agrupamentos densos e dominantes.',
    paragrafo4: 'Urochloa decumbens - braquiária',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Prosopis juliflora - algaroba',
    paragrafo5b: 'Foto: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - capim-gordura',
    paragrafo6b: 'Foto: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - capim-jaraguá',
    paragrafo7b: 'Foto: Instituto Hórus'
  },
  projetosInstitucional: {
    titulo: 'Buscar projetos',
    paragrafo1: 'Nesta seção estão disponíveis informações sobre projetos atuais e anteriores com foco em pesquisa e manejo de especies exóticas invasoras no Paraguai. Se você participa ou participou de algum projeto sobre alguma dessas espécies, por favor ajude-nos a incluir essas informações. Mande um email para',
    paragrafo2: 'Sua contribuição é muito apreciada.',
    paragrafo3: 'Como citar esta base de datos:',
    paragrafo4_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Consultado DD/MM/AAA.'
  },
  referenciasInstitucional: {
    titulo: 'Buscar referências',
    paragrafo1: 'As publicações e informes técnicos listados aqui foram utilizados para compilar informação sobre espécies e suas características, comportamento, impactos, alternativas de manejo e áreas de ocorrência no país. Se você é autor/a ou conhece publicações que não estão listadas aqui, por favor contribua com dados enviando referências para ',
    paragrafo2: 'Sua contribuição é muito apreciada.',
    paragrafo4: 'Como citar esta base de datos:',
    paragrafo5_1: 'Base de Datos de Especies Exóticas Invasoras en Paraguay. Asociación Guyra Paraguay – Museo Nacional de Historia Natural del Paraguay, Ministerio del Ambiente y Desarrollo Sustentable. Consultado DD/MM/AAA.'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contato',
    contato: 'Contato',
    p1: 'Preencha todos os campos do formulário abaixo:',
    nome: 'Nome',
    email: 'E-mail',
    instituicao: 'Instituição',
    mensagem: 'Mensagem',
    enviar: 'Enviar'
  }
}
