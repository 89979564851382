<template>
  <div class="container-fluid p-0">
    <!-- title -->
    <div class="row mb-2 mb-xl-3">
      <div class="col-auto d-none d-sm-block">
        <h3 class="laranja h4">Dashboard</h3>
      </div>
    </div>
    <!-- /title -->

    <!-- dashboard cards -->
    <div class="row">
      <div class="col-sm d-flex cards-home-admin">
        <div class="w-100">
          <div class="row">
            <div class="col-sm-4">
              <div class="card text-center">
                <router-link class="text-decoration-none" to="/admin/especies">
                  <div
                    class="card-body align-middle py-md-3 py-lg-4 px-2 box-dashboard"
                  >
                    <img
                      src="../../../assets/institucional/img/icon/card-especies.svg"
                      width="36"
                      height="36"
                      class="mb-3"
                    />
                    <h4 class="h5">{{ $t('label.especies') }}</h4>
                  </div>
                </router-link>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="card text-center">
                <router-link class="text-decoration-none" to="/admin/referencias">
                  <div
                    class="card-body align-middle py-md-3 py-lg-4 px-2 box-dashboard"
                  >
                    <img
                      src="../../../assets/institucional/img/icon/card-referencias.svg"
                      width="36"
                      height="36"
                      class="mb-3"
                    />
                    <h4 class="h5">{{ $t('label.referencias') }}</h4>
                  </div>
                </router-link>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="card text-center">
                <router-link class="text-decoration-none" to="/admin/colaboradores">
                  <div
                    class="card-body align-middle py-md-3 py-lg-4 px-2 box-dashboard"
                  >
                    <img
                      src="../../../assets/institucional/img/icon/card-colaboradores.svg"
                      width="36"
                      height="36"
                      class="mb-3"
                    />
                    <h4 class="h5">{{ $t('label.colaboradores') }}</h4>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="card text-center">
                <router-link class="text-decoration-none" to="/admin/projetos">
                  <div
                    class="card-body align-middle py-md-3 py-lg-4 px-2 box-dashboard"
                  >
                    <img
                      src="../../../assets/institucional/img/icon/card-projeto.svg"
                      width="36"
                      height="36"
                      class="mb-3"
                    />
                    <h4 class="h5">{{ $t('label.projetos') }}</h4>
                  </div>
                </router-link>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="card text-center">
                <router-link class="text-decoration-none" to="/admin/ocorrencias">
                  <div
                    class="card-body align-middle py-md-3 py-lg-4 px-2 box-dashboard"
                  >
                    <img
                      src="../../../assets/institucional/img/icon/icon-ocorrencia-lja.svg"
                      width="36"
                      height="36"
                      class="mb-3"
                    />
                    <h4 class="h5">{{ $t('label.ocorrencias') }}</h4>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /dashboard cards -->
    <div class="row">
      <div class="col-12 d-flex order-1 order-xxl-1" :class="user.perfilUser === 'administrator' ? 'col-md-9 col-xxl-9' : 'col-md-12 col-xxl-12'">
        <div class="card flex-fill box-dashboard">
          <div class="card-header border-bottom">
            <h5 class="card-title mb-0">{{ $t('label.ultimoRegistroBaseDados') }}</h5>
          </div>

          <div class="card-body">
            <div class="align-self-center">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link" :class="$store.state.dashboard.aba === 1 ? 'active' : ''"
                    id="especies-tab"
                    data-bs-toggle="tab"
                    href="#especies"
                    @click="setAba(1)"
                    role="tab"
                    aria-controls="especies"
                    :aria-selected="$store.state.dashboard.aba === 1"
                    >{{ $t('label.especies') }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link" :class="$store.state.dashboard.aba === 2 ? 'active' : ''"
                    id="referencias-tab"
                    data-bs-toggle="tab"
                    href="#referencias"
                    @click="setAba(2)"
                    role="tab"
                    aria-controls="referencias"
                    :aria-selected="$store.state.dashboard.aba === 2"
                    >{{ $t('label.referencias') }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link" :class="$store.state.dashboard.aba === 3 ? 'active' : ''"
                    id="colaboradores-tab"
                    data-bs-toggle="tab"
                    href="#colaboradores"
                    @click="setAba(3)"
                    role="tab"
                    aria-controls="colaboradores"
                    :aria-selected="$store.state.dashboard.aba === 3"
                    >{{ $t('label.colaboradores') }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link" :class="$store.state.dashboard.aba === 4 ? 'active' : ''"
                    id="projetos-tab"
                    data-bs-toggle="tab"
                    href="#projetos"
                    @click="setAba(4)"
                    role="tab"
                    aria-controls="projetos"
                    :aria-selected="$store.state.dashboard.aba === 4"
                    >{{ $t('label.projetos') }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link" :class="$store.state.dashboard.aba === 5 ? 'active' : ''"
                    id="ocorrencias-tab"
                    data-bs-toggle="tab"
                    href="#ocorrencias"
                    @click="setAba(5)"
                    role="tab"
                    aria-controls="ocorrencias"
                    :aria-selected="$store.state.dashboard.aba === 5"
                    >{{ $t('label.ocorrencias') }}</a
                  >
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <!-- conteudo aba especies -->
                <div class="tab-pane fade" :class="$store.state.dashboard.aba === 1 ? 'active show' : ''" id="especies" role="tabpanel" aria-labelledby="especies-tab">
                  <div class="box-dashboard mt-3">
                    <EspeciesTable @orderBy="orderBy" @search="searchEspecies" :items="itemsEspecies"/>
                  </div>
                </div>
                <!-- /conteudo aba especies -->
                <!-- conteudo aba referencias -->
                <div class="tab-pane fade" :class="$store.state.dashboard.aba === 2 ? 'active show' : ''" id="referencias" role="tabpanel" aria-labelledby="referencias-tab" >
                  <div class="box-dashboard mt-3">
                    <ReferenciasTable @orderBy="orderByReferencias" @search="searchReferencias" :items="itemsReferencias"/>
                  </div>
                </div>
                <!-- /conteudo aba referencias -->

                <!-- conteudo aba colaboradores -->
                <div class="tab-pane fade" :class="$store.state.dashboard.aba === 3 ? 'active show' : ''" id="colaboradores" role="tabpanel" aria-labelledby="colaboradores-tab" >
                  <div class="box-dashboard mt-3">
                    <ColaboradoresTable @orderBy="orderByColaboradores" @search="searchColaboradores" :items="itemsColaboradores"/>
                  </div>
                </div>
                <!-- /conteudo aba colaboradores -->

                <!-- conteudo aba projetos -->
                <div class="tab-pane fade" :class="$store.state.dashboard.aba === 4 ? 'active show' : ''" id="projetos" role="tabpanel" aria-labelledby="projetos-tab" >
                  <div class="box-dashboard mt-3">
                    <ProjetosTable @orderBy="orderByProjetos" @search="searchProjetos" :items="itemsProjetos"/>
                  </div>
                </div>
                <!-- /conteudo aba projetos -->

                <!-- conteudo aba projetos -->
                <div class="tab-pane fade" :class="$store.state.dashboard.aba === 5 ? 'active show' : ''" id="ocorrencias" role="tabpanel" aria-labelledby="ocorrencias-tab" >
                  <div class="box-dashboard mt-3">
                    <OcorrenciasTable @orderBy="orderByOcorrencias" @search="searchOcorrencias" :items="itemsOcorrencias"/>
                  </div>
                </div>
                <!-- /conteudo aba projetos -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 col-xxl-3 d-flex order-2 order-xxl-2" v-if="user.perfilUser === 'administrator'">
        <div class="card flex-fill box-dashboard w-100">
          <div class="card-header border-bottom">
            <h5 class="card-title mb-0">{{ $t('label.ultimosAcessos') }}</h5>
          </div>

          <div class="card-body px-4 pb-5">
            <ul class="ult-acessos mt-3">
              <li class="d-flex flex-row" v-for="item in itemsAccess" :key="item.name">
                <div class="avatar d-flex justify-content-start">
                  <img src="../../../assets/admin/img/avatars/avatar.jpg" />
                </div>
                <div class="acessos-dados justify-content-end">
                  <p><strong>{{item.description}}</strong></p>
                  <p>{{item.access_date | formatDateTime}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EspeciesTable from '@/views/admin/especies/EspeciesTable.vue'
import ReferenciasTable from '@/views/admin/referencias/ReferenciasTable.vue'
import ColaboradoresTable from '@/views/admin/colaboradores/ColaboradoresTable.vue'
import ProjetosTable from '@/views/admin/projetos/ProjetosTable.vue'
import OcorrenciasTable from '@/views/admin/ocorrencias/OcorrenciasTable.vue'
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: { EspeciesTable, ReferenciasTable, ColaboradoresTable, ProjetosTable, OcorrenciasTable },
  computed: mapState(['user']),
  data () {
    return {
      itemsEspecies: [],
      itemsReferencias: [],
      itemsColaboradores: [],
      itemsProjetos: [],
      itemsOcorrencias: [],
      formSearch: {},
      dataOrderBy: {},
      itemsAccess: []
    }
  },
  methods: {
    orderBy: function (dataOrderBy) {
      this.formSearch.OrderByDateUpdateDesc = false
      this.$store.state.dataOrderBy = dataOrderBy
      this.searchEspecies()
    },
    setFormOrderBy: function () {
      this.formSearch.OrderByKingdomAcs = this.$store.state.dataOrderBy.OrderByKingdomAcs
      this.formSearch.OrderByKingdomDesc = this.$store.state.dataOrderBy.OrderByKingdomDesc
      this.formSearch.OrderByKingdomDesc = this.$store.state.dataOrderBy.OrderByKingdomDesc
      this.formSearch.OrderByKingdomAcs = this.$store.state.dataOrderBy.OrderByKingdomAcs
      this.formSearch.OrderByFamilyAcs = this.$store.state.dataOrderBy.OrderByFamilyAcs
      this.formSearch.OrderByFamilyDesc = this.$store.state.dataOrderBy.OrderByFamilyDesc
      this.formSearch.OrderByFamilyDesc = this.$store.state.dataOrderBy.OrderByFamilyDesc
      this.formSearch.OrderByFamilyAcs = this.$store.state.dataOrderBy.OrderByFamilyAcs
      this.formSearch.OrderByScientificNameAcs = this.$store.state.dataOrderBy.OrderByScientificNameAcs
      this.formSearch.OrderByScientificNameDesc = this.$store.state.dataOrderBy.OrderByScientificNameDesc
      this.formSearch.OrderByScientificNameDesc = this.$store.state.dataOrderBy.OrderByScientificNameDesc
      this.formSearch.OrderByScientificNameAcs = this.$store.state.dataOrderBy.OrderByScientificNameAcs
    },
    searchEspecies: function () {
      this.$store.dispatch('showPreload')
      // this.formSearch.species_type = 1
      this.setFormOrderBy()
      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Specie/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.itemsEspecies = response.data.items
        })
    },
    orderByReferencias: function (dataOrderBy) {
      this.formSearch.OrderByDateUpdateDesc = false
      this.$store.state.dataOrderBy = dataOrderBy
      this.searchReferencias()
    },
    setFormOrderByReferencias: function () {
      this.formSearch.OrderByTitleAcs = this.$store.state.dataOrderBy.OrderByTitleAcs
      this.formSearch.OrderByTitleDesc = this.$store.state.dataOrderBy.OrderByTitleDesc
      this.formSearch.OrderByAuthorAcs = this.$store.state.dataOrderBy.OrderByAuthorAcs
      this.formSearch.OrderByAuthorDesc = this.$store.state.dataOrderBy.OrderByAuthorDesc
    },
    searchReferencias: function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderByReferencias()
      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Bibliography/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.itemsReferencias = response.data.items
        })
    },
    orderByColaboradores: function (dataOrderBy) {
      this.formSearch.OrderByDateUpdateDesc = false
      this.$store.state.dataOrderBy = dataOrderBy
      this.searchColaboradores()
    },
    setFormOrderByColaboradores: function () {
      this.formSearch.OrderByNameAcs = this.$store.state.dataOrderBy.OrderByNameAcs
      this.formSearch.OrderByNameDesc = this.$store.state.dataOrderBy.OrderByNameDesc
      this.formSearch.OrderBySurnameAcs = this.$store.state.dataOrderBy.OrderBySurnameAcs
      this.formSearch.OrderBySurnameDesc = this.$store.state.dataOrderBy.OrderBySurnameDesc
    },
    searchColaboradores: function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderByColaboradores()
      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Contact/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.itemsColaboradores = response.data.items
        })
    },
    orderByProjetos: function (dataOrderBy) {
      this.formSearch.OrderByDateUpdateDesc = false
      this.$store.state.dataOrderBy = dataOrderBy
      this.searchProjetos()
    },
    setFormOrderByProjetos: function () {
      this.formSearch.OrderByTitleAcs = this.$store.state.dataOrderBy.OrderByTitleAcs
      this.formSearch.OrderByTitleDesc = this.$store.state.dataOrderBy.OrderByTitleDesc
      this.formSearch.OrderBySurnameAcs = this.$store.state.dataOrderBy.OrderBySurnameAcs
      this.formSearch.OrderBySurnameDesc = this.$store.state.dataOrderBy.OrderBySurnameDesc
      this.formSearch.OrderByGeoAreaAcs = this.$store.state.dataOrderBy.OrderByGeoAreaAcs
      this.formSearch.OrderByGeoAreaDesc = this.$store.state.dataOrderBy.OrderByGeoAreaDesc
    },
    searchProjetos: function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderByProjetos()
      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Project/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.itemsProjetos = response.data.items
        })
    },
    orderByOcorrencias: function (dataOrderBy) {
      this.formSearch.OrderByDateUpdateDesc = false
      this.$store.state.dataOrderBy = dataOrderBy
      this.searchOcorrencias()
    },
    setFormOrderByOcorrencias: function () {
      this.formSearch.OrderBySpecieScientificNameAcs = this.$store.state.dataOrderBy.OrderBySpecieScientificNameAcs
      this.formSearch.OrderBySpecieScientificNameDesc = this.$store.state.dataOrderBy.OrderBySpecieScientificNameDesc
      this.formSearch.OrderByStateAcs = this.$store.state.dataOrderBy.OrderByStateAcs
      this.formSearch.OrderByStateDesc = this.$store.state.dataOrderBy.OrderByStateDesc
      this.formSearch.OrderByMunicipioAcs = this.$store.state.dataOrderBy.OrderByMunicipioAcs
      this.formSearch.OrderByMunicipioDesc = this.$store.state.dataOrderBy.OrderByMunicipioDesc
      this.formSearch.OrderByProtectedAreaAcs = this.$store.state.dataOrderBy.OrderByProtectedAreaAcs
      this.formSearch.OrderByProtectedAreaDesc = this.$store.state.dataOrderBy.OrderByProtectedAreaDesc
    },
    searchOcorrencias: function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderByOcorrencias()
      const params = this.formSearch
      params.pending_analysis = true
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/DataPlaces/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.itemsOcorrencias = response.data.items
        })
    },
    setAba: function (aba) {
      this.$store.state.dashboard.aba = aba
      this.$store.state.currentPage = 1
      this.$store.state.perPage = 10
      this.formSearch.OrderByDateUpdateDesc = true
      if (aba === 1) {
        this.searchEspecies()
      }
      if (aba === 2) {
        this.searchReferencias()
      }
      if (aba === 3) {
        this.searchColaboradores()
      }
      if (aba === 4) {
        this.searchProjetos()
      }
      if (aba === 5) {
        this.searchOcorrencias()
      }
    },
    getAccess: function () {
      this.$store.dispatch('showPreload')

      const params = this.formSearch
      params.Page = 1
      params.PageSize = 11
      axios.get('/User/GetAccess', { params })
        .then(response => {
          this.itemsAccess = response.data.items
        })
    },
    updateAccessDate: function () {
      this.$store.dispatch('showPreload')
      axios.post(`/User/updateaccessdate/${this.user.sysUser.name}`)
    }
  },
  created: function () {
    this.updateAccessDate()
    this.$store.state.currentPage = this.$store.state.dashboard.currentPage
    this.formSearch.OrderByDateUpdateDesc = true
    if (this.$store.state.dashboard.aba === 1) {
      this.searchEspecies()
    }
    if (this.$store.state.dashboard.aba === 2) {
      this.searchReferencias()
    }
    if (this.$store.state.dashboard.aba === 3) {
      this.searchColaboradores()
    }
    if (this.$store.state.dashboard.aba === 4) {
      this.searchProjetos()
    }
    if (this.$store.state.dashboard.aba === 5) {
      this.searchOcorrencias()
    }
    if (this.user.perfilUser === 'administrator') {
      this.getAccess()
    }
  }
}
</script>
