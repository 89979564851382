<template>
  <div class="temas">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides">
        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/peixes-aquicultura">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/peixes-aquicultura"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-peixes-aquicultura-2.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/peixes-aquicultura">Aquaculture fishes</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>
        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/pets">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/pets"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-pets-2.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/pets">Pets</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>
        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/plantas-forrageiras">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/plantas-forrageiras"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-plantas-forrageiras-2.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/plantas-forrageiras">Forage plants</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/plantas-ornamentais">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/plantas-ornamentais"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-plantas-ornamentais-2.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/plantas-ornamentais">Ornamental plants</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>
      </ul>
    </div>

    <div class="slider__bullets glide__bullets" data-glide-el="controls[nav]">
      <button class="slider__bullet glide__bullet active" data-glide-dir="=0"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=1"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=2"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=3"></button>
    </div>
  </div>
</template>

<script>
import '@glidejs/glide/dist/css/glide.core.min.css'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Glide from '@glidejs/glide'
export default {
  name: 'InstitucionalSliderTemasGlideEn',
  mounted () {
    const glideTemas = new Glide('.temas', {
      type: 'carousel',
      autoplay: 3500,
      perView: 4,
      gap: 10,
      breakpoints: {
        1440: {
          perView: 4
        },
        1200: {
          perView: 4
        },
        992: {
          perView: 4
        },
        768: {
          perView: 3
        },
        480: {
          perView: 2
        },
        0: {
          perView: 1
        }
      }
    })
    glideTemas.mount()
  }
}
</script>
