<template>
    <div class="login ">
      <div class="container ">
        <div class="row align-items-center">
          <div class="col-md-6 align-middle py-5 ">
            <h1 class="laranja">{{$t('login.login')}}</h1>
            <p>{{$t('login.descricao')}}</p>
            <form class="mt-5 col-md-10" @submit.prevent="submit">
              <div class="mb-3">
                <label for="username" class="form-label">{{ $t('login.username') }}</label>
                <input type="text" class="form-control" id="username" v-model="form.username" :class="{ 'is-invalid': $v.form.username.$dirty && $v.form.username.$error }">
                <div class="invalid-feedback">
                  {{ $t('message.requiredField') }}
                </div>
              </div>
              <div class="mb-3">
                <label for="loginPass" class="form-label">{{ $t('login.password') }}</label>
                <input type="password" class="form-control" id="loginPass" v-model="form.password" :class="{ 'is-invalid': $v.form.password.$dirty && $v.form.password.$error }">
                <div class="invalid-feedback">
                  {{ $t('message.requiredField') }}
                </div>
              </div>
              <button type="submit" class="btn btn-primary btn-1-horus mt-4">{{$t('login.entrar')}}</button>
            </form>
          </div>
          <div class="col-md-6 ">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  validations: {
    form: {
      username: { required },
      password: { required }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.form.$invalid === false) {
        axios.post('/login', {
          login: this.form.username,
          password: this.form.password
        }).then(response => {
          if (response.data.token) {
            this.$store.commit('setUser', response.data)
            this.$router.push('/admin')
          }
        })
      }
    }
  }
}
</script>
