<template>
  <InstitucionalContent/>
</template>

<script>
import InstitucionalContent from '@/components/institucional/InstitucionalContent'
import '../../util/bootstrap'
export default {
  name: 'Institucional',
  components: { InstitucionalContent }
}
</script>

<style scoped>
@import url("../../assets/institucional/css/bd-institucional.css");
</style>
