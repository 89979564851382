<template>
  <div class="modal fade" id="modalAnalysis" refs="modalAnalysis" tabindex="-1" aria-labelledby="modalAnalysis" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review - {{modalValues.label}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body d-flex box_scroll_y">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <h5 class="card-title">{{ $t('label.versaoAnterior') }}</h5>
              <div class="pt-2 pb-2 pr-1 pl-2" style="border: 1px solid #BDBDBD;border-radius: 8px;">
                <ul v-if="modalValues.isList">
                  <li v-for="item in modalValues.oldValue" :key="item.id">{{item.value}}</li>
                </ul>
                <p v-else class="card-text">{{modalValues.oldValue}}</p>
              </div>
              <h3 class="card-title">{{ $t('login.username') }}: {{form.log_update ? form.log_update.User_update : ''}}</h3>
            </div>
            <div class="col-sm-6 col-md-6">
              <h5 class="card-title">{{ $t('label.versaoAtual') }}</h5>
              <div class="pt-2 pb-2 pr-1 pl-2" style="border: 1px solid #BDBDBD;border-radius: 8px;">
                <ul v-if="modalValues.isList">
                  <li v-for="item in modalValues.newValue" :key="item.id">{{item.value}}</li>
                </ul>
                <p v-else class="card-text">{{modalValues.newValue}}</p>
              </div>
              <h3 class="card-title">{{ $t('login.username') }}: {{form.user_update}}</h3>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('label.fechar') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminModalReview',
  props: {
    form: {},
    modalValues: {}
  }
}
</script>
