<template>
  <div class="container logo-parceiros pb-5">
    <div class="row">
      <div class="col-sm">
        <h2 class="h5 laranja">{{ $t('areaParceiros.titulo') }}</h2>

        <div class="row mt-5">
          <div class="col-sm col-md-12 col-lg-6 col-xxl-6 pb-2">
            <h3 class="h5">{{ $t('areaParceiros.argentina') }}</h3>

            <ul>
              <li><img src="../../assets/institucional/img/logo/apoio-dbbyf.png" ></li>
              <li><img src="../../assets/institucional/img/logo/apoio-gekko.png" ></li>
              <li><img src="../../assets/institucional/img/logo/apoio-uns.png" ></li>
            </ul>

          </div>
          <div class="col-sm col-md-12 col-lg-2 col-xxl-2 pb-2">
            <h3 class="h5">{{ $t('areaParceiros.brasil') }}</h3>
            <ul>
              <li><img src="../../assets/institucional/img/logo/apoio-horus.png" ></li>
            </ul>
          </div>
          <div class="col-sm col-md-12 col-lg-2 col-xxl-2 pb-2">
            <h3 class="h5">{{ $t('areaParceiros.jamaica') }}</h3>
            <ul>
              <li><img src="../../assets/institucional/img/logo/logo_jamaica.png" ></li>
            </ul>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm col-md-12 col-lg-2 col-xxl-2 pb-2">
            <h3 class="h5">{{ $t('areaParceiros.paraguai') }}</h3>
            <ul>
              <li><img src="../../assets/institucional/img/logo/apoio-guyra.png" ></li>
            </ul>
          </div>
          <div class="col-sm col-md-12 col-lg-10 col-xxl-10 pb-2">
            <h3 class="h5">{{ $t('areaParceiros.uruguai') }}</h3>
            <ul>
              <li><img src="../../assets/institucional/img/logo/apoio-comite.png" ></li>
              <li><img src="../../assets/institucional/img/logo/apoio-faculdad-del-ciencia.png" ></li>
              <li><img src="../../assets/institucional/img/logo/apoio-ministerio.png" ></li>
              <li><img src="../../assets/institucional/img/logo/apoio-universidad-de-la-republica.png" ></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstitucionalParceiros'
}
</script>
<style>
  .logo-parceiros h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1.15rem;
  }

  .logo-parceiros ul {
    padding-left: 0;
  }

  .logo-parceiros ul li {
    display: inline;
    list-style: none;
    margin: 5px 20px 5px 0;
  }
</style>
