<template>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation" @click="setAba(1)">
      <a class="nav-link" :class="{ 'active': aba===1 }" id="taxonomia-tab" :style="tabTaxonomiaPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.taxonomia') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(2)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===2 }" id="ecologia-tab" :style="tabEcologiaEUsoPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.ecologiaUso') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(3)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===3 }" id="dispersao-tab" :style="tabIntroducaoDispersaoPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.introducaoDispersao') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(4)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===4 }" id="impacto-tab" :style="tabImpactoPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.impactos') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(5)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===5 }" id="manejo-tab" :style="tabManejoPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.manejo') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(6)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===6 }" id="analiserisco-tab" :style="tabAnaliseDeRiscoPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.analiseRisco') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(7)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===7 }" id="ocorrencias-tab" :style="tabOcorrenciasPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.ocorrencias') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(8)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===8 }" id="referencias-tab" :style="tabReferenciasPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.referencias') }}</a>
    </li>
    <li class="nav-item" role="presentation" @click="setAba(9)" v-if="action !== $actionNew">
        <a class="nav-link" :class="{ 'active': aba===9 }" id="projetos-tab" :style="tabProjetosPending ? 'color: #FE9900 !important;' : ''">{{ $t('label.projetos') }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'EspeciesTabs',
  props: {
    aba: Number,
    action: String,
    tabManejoPending: Boolean,
    tabTaxonomiaPending: Boolean,
    tabEcologiaEUsoPending: Boolean,
    tabIntroducaoDispersaoPending: Boolean,
    tabImpactoPending: Boolean,
    tabAnaliseDeRiscoPending: Boolean,
    tabOcorrenciasPending: Boolean,
    tabReferenciasPending: Boolean,
    tabProjetosPending: Boolean
  },
  methods: {
    setAba: function (num) {
      this.$emit('setAba', num)
    }
  }
}
</script>
