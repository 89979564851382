<template>
  <Admin v-if="$route.meta.appAdmin"/>
  <Institucional v-else/>
</template>

<script>
import Institucional from '@/components/institucional/Institucional'
import Admin from '@/components/admin/Admin'
import feather from 'feather-icons'

export default {
  name: 'App',
  components: { Institucional, Admin },
  mounted () {
    feather.replace()
  }
}
</script>

<style>
@import url("assets/institucional/css/bd-estilo.css");
</style>
