<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===8 }" id="referencias" role="tabpanel" aria-labelledby="referencias-tab">
    <div class="container-fluid  mt-3">
      <!-- tabela -->
      <div class="row ">
          <div class="col-12 d-flex">
            <div class="card flex-fill ">
                <div class="card-body box-dashboard p-0">
                  <div class="align-self-center">
                      <div class="table-responsive  full_table  mt-0">
                        <table class="table caption-top">
                            <thead class="bg_table_header">
                              <tr>
                                  <th class="col-md-2 py-3 pl-4">{{ $t('label.anoPublic') }}</th>
                                  <th class="col-md-4 py-3">{{ $t('label.titulo') }}</th>
                                  <th class="col-md-5 py-3">{{ $t('label.autores') }}</th>
                                  <th class="col-md-1 py-3 pr-4 text-right" v-if="!byInstitucional">{{ $t('label.acoes') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="!form.relBibliography || form.relBibliography.length === 0"><td colspan="4" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                              <tr v-else v-for="item in form.relBibliography" :key="item.biblio_id">
                                <td class="py-3 pl-4">{{item.dataBibliography ? item.dataBibliography.year : ''}}</td>
                                <td class="py-3">{{item.dataBibliography ? item.dataBibliography.title : ''}}</td>
                                <td class="py-3">{{item.dataBibliography ? item.dataBibliography.author : ''}}</td>
                                <td class="py-3" v-if="!byInstitucional">
                                  <ul class="acoes text-right pr-1">
                                    <li>
                                      <a @click="$router.push({ name: 'AdminReferenciasForm', params: { id: item.biblio_id } })"><span class="editar"></span></a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <!-- /tabela -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'EspeciesTabReferencias',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorReferencias: Boolean,
    onlyLoadValidator: Boolean
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorReferencias' (newValue) {
      if (newValue) {
        this.$v.$touch()
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v)
      }
    }
  }
}
</script>
