<template>
  <div>
    <InstitucionalHeaderSingle :classProp="'header-single header-rede'" :title="`${$t('redeBaseDeDados.cabecalhoTitulo') }`"/>
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">

          <p>{{ $t('redeBaseDeDados.paragrafo1') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo2') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo2_1') }}</p>
          <ul>
            <li>{{ $t('redeBaseDeDados.marcador1') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador2') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador3') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador4') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador5') }}</li>
          </ul>
          <p>{{ $t('redeBaseDeDados.paragrafo3') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo4') }}</p>

          <p>{{ $t('redeBaseDeDados.paragrafo5') }}</p>
          <ul>
            <li>{{ $t('redeBaseDeDados.marcador6') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador7') }} {{ $t('redeBaseDeDados.marcador7_1') }} {{ $t('redeBaseDeDados.marcador7_2') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador8') }} <a href="http://www.vibbra.com.br" target="_blank">{{ $t('redeBaseDeDados.marcador8_1') }}</a> {{ $t('redeBaseDeDados.marcador8_2') }}</li>
            <li v-if="$t('redeBaseDeDados.marcador9')">{{ $t('redeBaseDeDados.marcador9') }}</li>
          </ul>
          <p>{{ $t('redeBaseDeDados.paragrafo6') }}</p>

        </div>
      </div>
    </div>
    <InstitucionalParceiros />
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
import InstitucionalParceiros from '@/components/institucional/InstitucionalParceiros'
export default {
  name: 'RedeBaseDeDados',
  components: { InstitucionalHeaderSingle, InstitucionalParceiros }
}
</script>
