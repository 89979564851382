<template>
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="col-auto d-none d-sm-block">
        <h3 class="laranja h4">{{ $t('label.fichaTecnicaEspecieMMA2') }}</h3>
      </div>
    </div>
    <FichasFiltroMMA2 />
  </div>
</template>

<script>
import FichasFiltroMMA2 from './FichasFiltroMMA2.vue'
export default {
  name: 'FichasMMA2',
  components: { FichasFiltroMMA2 }
}
</script>
