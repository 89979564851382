<template>
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3 ">
      <div class="col-auto d-none d-sm-block">
        <h3 class="laranja h4">{{ $t('label.taxonomia') }}</h3>
      </div>
    </div>
    <div class="row mb-5 justify-content-md-center">
      <div class="col-sm-9 mb-3">
        <label for="nome-cientifico-taxonomia" class="form-label">{{ $t('label.nomeCientifico') }}</label>
        <input type="text" class="form-control" v-model="specie.scientific_name" :class="{ 'is-invalid': $v.specie.scientific_name.$dirty && $v.specie.scientific_name.$error }">
        <div class="invalid-feedback">
          {{ $t('message.requiredField') }}
        </div>
      </div>
      <div class="col-sm-3 mb-3 d-flex align-items-end">
        <a @click="addSpecie()" class="bt-add-registro mb-2" style="width: 100%;text-align: center;">{{ $t('label.adicionarEspecie') }}</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="row  bg_table_header p-4">
                <div class="col-md-12 ">
                  <h4 class=" h4 mb-0">{{ $t('label.genero') }}</h4>
                </div>
              </div>
              <div class="row  bg_table_header2 p-3">
                <div class="col-md-6 ">
                  <div class="input-group input-group-navbar" style="margin-left: 0%!important">
                    <input type="text" class="form-control" aria-label="Search" v-model="search.genus"/>
                    <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
                  </div>
                </div>
                <div class="col-md-2 ">
                </div>
                <div class="col-md-4 text-end">
                  <span class="add-label2">
                    <input type="text" v-model="genus.genus" v-on:keyup.enter="addGenus()">
                    <button id="bt-add-label" class="bt-add-label" @click="addGenus()" ></button>
                  </span>
                </div>
              </div>
              <div class="row mb-2 mb-xl-3 p-3">
                <div class="col-md-12 ">
                  <div class="box_scroll_y box_scroll_x" style="max-height: 300px;">
                    <template v-for="item in taxonomyGenusList">
                      <EditTaxonomyGenus :key="item.genus_id" :genus="item" v-if="viewEdit.genus===`edit-${item.genus_id}`"
                        @cancelEditGenus="cancelEditGenus" @salveEditGenus="salveEditGenus" :list="taxonomyFamilySelect"/>
                      <span :key="item.genus_id" class="edit-label" :class="{ 'filtro-selected': selected.genus===`selected.genus-${item.genus_id}` }" v-else>
                        <p class="d-flex justify-content-start" @click="filterGenusSelected(item)">{{item.genus}}</p>
                        <ul class="acoes d-flex justify-content-end">
                          <li><a @click="viewEdit.genus=`edit-${item.genus_id}`"><span class="editar"></span></a></li>
                          <li><a @click="removeGenus(item)"><span class="apagar"></span></a></li>
                        </ul>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="row  bg_table_header p-4">
                <div class="col-md-12 ">
                  <h4 class=" h4 mb-0">{{ $t('label.familia') }}</h4>
                </div>
              </div>
              <div class="row  bg_table_header2 p-3">
                <div class="col-md-6 ">
                  <div class="input-group input-group-navbar" style="margin-left: 0%!important">
                    <input type="text" class="form-control" aria-label="Search" v-model="search.family"/>
                    <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
                  </div>
                </div>
                <div class="col-md-2 ">
                </div>
                <div class="col-md-4 text-end">
                  <span class="add-label2">
                    <input type="text" v-model="family.family" v-on:keyup.enter="addFamily()">
                    <button id="bt-add-label" class="bt-add-label" @click="addFamily()" ></button>
                  </span>
                </div>
              </div>
              <div class="row mb-2 mb-xl-3 p-3">
                <div class="col-md-12 ">
                  <div class="box_scroll_y box_scroll_x" style="max-height: 300px;">
                    <template v-for="item in taxonomyFamilyList">
                      <EditTaxonomyFamily :key="item.family_id" :family="item" v-if="viewEdit.family===`edit-${item.family_id}`"
                        @cancelEdit="cancelEditFamily" @salveEdit="salveEditFamily" :list="taxonomyOrderSelect"/>
                      <span :key="item.family_id" class="edit-label" :class="{ 'filtro-selected': selected.family===`selected.family-${item.family_id}` }" v-else>
                        <p class="d-flex justify-content-start" @click="filterFamilySelected(item)">{{item.family}}</p>
                        <ul class="acoes d-flex justify-content-end">
                          <li><a @click="viewEdit.family=`edit-${item.family_id}`"><span class="editar"></span></a></li>
                          <li><a @click="removeFamily(item)"><span class="apagar"></span></a></li>
                        </ul>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="row  bg_table_header p-4">
                <div class="col-md-12 ">
                  <h4 class=" h4 mb-0">{{ $t('label.ordem') }}</h4>
                </div>
              </div>
              <div class="row  bg_table_header2 p-3">
                <div class="col-md-6 ">
                  <div class="input-group input-group-navbar" style="margin-left: 0%!important">
                    <input type="text" class="form-control" aria-label="Search" v-model="search.order"/>
                    <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
                  </div>
                </div>
                <div class="col-md-2 ">
                </div>
                <div class="col-md-4 text-end">
                  <span class="add-label2">
                    <input type="text" v-model="order.oorder" v-on:keyup.enter="addOrder()">
                    <button id="bt-add-label" class="bt-add-label" @click="addOrder()" ></button>
                  </span>
                </div>
              </div>
              <div class="row mb-2 mb-xl-3 p-3">
                <div class="col-md-12 ">
                  <div class="box_scroll_y box_scroll_x" style="max-height: 300px;">
                    <template v-for="item in taxonomyOrderList">
                      <EditTaxonomyOrder :key="item.order_id" :order="item" v-if="viewEdit.order===`edit-${item.order_id}`"
                        @cancelEdit="cancelEditOrder" @salveEdit="salveEditOrder" :list="taxonomyClassSelect"/>
                      <span :key="item.order_id" class="edit-label" :class="{ 'filtro-selected': selected.order===`selected.order-${item.order_id}` }" v-else>
                        <p class="d-flex justify-content-start" @click="filterOrderSelected(item)">{{item.oorder}}</p>
                        <ul class="acoes d-flex justify-content-end">
                          <li><a @click="viewEdit.order=`edit-${item.order_id}`"><span class="editar"></span></a></li>
                          <li><a @click="removeOrder(item)"><span class="apagar"></span></a></li>
                        </ul>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="row  bg_table_header p-4">
                <div class="col-md-12 ">
                  <h4 class=" h4 mb-0">{{ $t('label.classe') }}</h4>
                </div>
              </div>
              <div class="row  bg_table_header2 p-3">
                <div class="col-md-6 ">
                  <div class="input-group input-group-navbar" style="margin-left: 0%!important">
                    <input type="text" class="form-control" aria-label="Search" v-model="search.class"/>
                    <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
                  </div>
                </div>
                <div class="col-md-2 ">
                </div>
                <div class="col-md-4 text-end">
                  <span class="add-label2">
                    <input type="text" v-model="objClass.class" v-on:keyup.enter="addClass()">
                    <button id="bt-add-label" class="bt-add-label" @click="addClass()" ></button>
                  </span>
                </div>
              </div>
              <div class="row mb-2 mb-xl-3 p-3">
                <div class="col-md-12 ">
                  <div class="box_scroll_y box_scroll_x" style="max-height: 300px;">
                    <template v-for="item in taxonomyClassList">
                      <EditTaxonomyClass :key="item.class_id" :objClass="item" v-if="viewEdit.class===`edit-${item.class_id}`"
                        @cancelEdit="cancelEditClass" @salveEdit="salveEditClass" :list="taxonomyPhyllumSelect"/>
                      <span :key="item.class_id" class="edit-label" :class="{ 'filtro-selected': selected.class===`selected.class-${item.class_id}` }" v-else>
                        <p class="d-flex justify-content-start" @click="filterClassSelected(item)">{{item.class}}</p>
                        <ul class="acoes d-flex justify-content-end">
                          <li><a @click="viewEdit.class=`edit-${item.class_id}`"><span class="editar"></span></a></li>
                          <li><a @click="removeClass(item)"><span class="apagar"></span></a></li>
                        </ul>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="row  bg_table_header p-4">
                <div class="col-md-12 ">
                  <h4 class=" h4 mb-0">{{ $t('label.phylumDivisao') }}</h4>
                </div>
              </div>
              <div class="row  bg_table_header2 p-3">
                <div class="col-md-6 ">
                  <div class="input-group input-group-navbar" style="margin-left: 0%!important">
                    <input type="text" class="form-control" aria-label="Search" v-model="search.phyllum"/>
                    <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
                  </div>
                </div>
                <div class="col-md-2 ">
                </div>
                <div class="col-md-4 text-end">
                  <span class="add-label2">
                    <input type="text" v-model="phyllum.phyllum" v-on:keyup.enter="addPhyllum()">
                    <button id="bt-add-label" class="bt-add-label" @click="addPhyllum()" ></button>
                  </span>
                </div>
              </div>
              <div class="row mb-2 mb-xl-3 p-3">
                <div class="col-md-12 ">
                  <div class="box_scroll_y box_scroll_x" style="max-height: 300px;">
                    <template v-for="item in taxonomyPhyllumList">
                      <EditTaxonomyPhyllum :key="item.phyllum_id" :objPhyllum="item" v-if="viewEdit.phyllum===`edit-${item.phyllum_id}`"
                        @cancelEdit="cancelEditPhyllum" @salveEdit="salveEditPhyllum" :list="taxonomyKingdomSelect"/>
                      <span :key="item.phyllum_id" class="edit-label" :class="{ 'filtro-selected': selected.phyllum===`selected.phyllum-${item.phyllum_id}` }" v-else>
                        <p class="d-flex justify-content-start" @click="filterPhyllumSelected(item)">{{item.phyllum}}</p>
                        <ul class="acoes d-flex justify-content-end">
                          <li><a @click="viewEdit.phyllum=`edit-${item.phyllum_id}`"><span class="editar"></span></a></li>
                          <li><a @click="removePhyllum(item)"><span class="apagar"></span></a></li>
                        </ul>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="row  bg_table_header p-4">
                <div class="col-md-12 ">
                  <h4 class=" h4 mb-0">{{ $t('label.reino') }}</h4>
                </div>
              </div>
              <div class="row  bg_table_header2 p-3">
                <div class="col-md-6 ">
                  <div class="input-group input-group-navbar" style="margin-left: 0%!important">
                    <input type="text" class="form-control" aria-label="Search" v-model="search.kingdom"/>
                    <button class="btn" type="button"><i class="align-middle" data-feather="search"></i></button>
                  </div>
                </div>
                <div class="col-md-2 ">
                </div>
                <div class="col-md-4 text-end">
                  <span class="add-label2">
                    <input type="text" v-model="kingdom.kingdom" v-on:keyup.enter="addKingdom()">
                    <button id="bt-add-label" class="bt-add-label" @click="addKingdom()" ></button>
                  </span>
                </div>
              </div>
              <div class="row mb-2 mb-xl-3 p-3">
                <div class="col-md-12 ">
                  <div class="box_scroll_y box_scroll_x" style="max-height: 300px;">
                    <template v-for="item in taxonomyKingdomList">
                      <EditTaxonomyKingdom :key="item.kingdom_id" :objKingdom="item" v-if="viewEdit.kingdom===`edit-${item.kingdom_id}`"
                        @cancelEdit="cancelEditKingdom" @salveEdit="salveEditKingdom" />
                      <span :key="item.kingdom_id" class="edit-label" :class="{ 'filtro-selected': selected.kingdom===`selected.kingdom-${item.kingdom_id}` }" v-else>
                        <p class="d-flex justify-content-start" @click="filterKingdomSelected(item)">{{item.kingdom}}</p>
                        <ul class="acoes d-flex justify-content-end">
                          <li><a @click="viewEdit.kingdom=`edit-${item.kingdom_id}`"><span class="editar"></span></a></li>
                          <li><a @click="removeKingdom(item)"><span class="apagar"></span></a></li>
                        </ul>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import EditTaxonomyGenus from './EditTaxonomyGenus'
import EditTaxonomyFamily from './EditTaxonomyFamily'
import EditTaxonomyOrder from './EditTaxonomyOrder'
import EditTaxonomyClass from './EditTaxonomyClass'
import EditTaxonomyPhyllum from './EditTaxonomyPhyllum'
import EditTaxonomyKingdom from './EditTaxonomyKingdom'
export default {
  name: 'Taxonomia',
  components: {
    EditTaxonomyGenus, EditTaxonomyFamily, EditTaxonomyOrder, EditTaxonomyClass, EditTaxonomyPhyllum, EditTaxonomyKingdom
  },
  data: function () {
    return {
      specie: {},
      genus: {},
      family: {},
      order: {},
      objClass: {},
      phyllum: {},
      kingdom: {},
      taxonomyGenus: {
        taxonomyFamily: { taxonomyOrder: { taxonomyClass: { taxonomyPhyllum: { taxonomyKingdom: {} } } } }
      },
      search: {
        genus: '',
        family: '',
        order: '',
        class: '',
        phyllum: '',
        kingdom: ''
      },
      selected: {
        genus: '',
        family: '',
        order: '',
        class: '',
        phyllum: '',
        kingdom: ''
      },
      viewEdit: {
        genus: '',
        family: '',
        order: '',
        class: '',
        phyllum: '',
        kingdom: ''
      },
      taxonomyGenusList: [],
      taxonomyFamilyList: [],
      taxonomyFamilySelect: [],
      taxonomyOrderList: [],
      taxonomyOrderSelect: [],
      taxonomyClassList: [],
      taxonomyClassSelect: [],
      taxonomyPhyllumList: [],
      taxonomyPhyllumSelect: [],
      taxonomyKingdomList: [],
      taxonomyKingdomSelect: []
    }
  },
  validations: {
    specie: {
      scientific_name: { required }
    }
  },
  watch: {
    'search.genus' () {
      this.getTaxonomyGenus()
    },
    'search.family' () {
      this.getTaxonomyFamily()
    },
    'search.order' () {
      this.getTaxonomyOrder()
    },
    'search.class' () {
      this.getTaxonomyClass()
    },
    'search.phyllum' () {
      this.getTaxonomyPhyllum()
    },
    'search.kingdom' () {
      this.getTaxonomyKingdom()
    }
  },
  methods: {
    addGenus: function () {
      if (!this.genus.genus) {
        this.$toasted.global.defaultError({ msg: this.$t('label.descricaoInvalida') })
        return false
      }
      if (!this.genus.family_id) {
        this.$toasted.global.defaultError({ msg: this.$t('label.selecioneFamilia') })
        return false
      }
      axios.post('/TaxonomyGenus', this.genus)
        .then(response => {
          this.genus.genus = ''
          this.$toasted.global.saved()
          this.getTaxonomyGenus()
        })
    },
    addFamily: function () {
      if (!this.family.family) {
        this.$toasted.global.defaultError({ msg: this.$t('label.descricaoInvalida') })
        return false
      }
      if (!this.family.order_id) {
        this.$toasted.global.defaultError({ msg: this.$t('label.selecioneOrdem') })
        return false
      }
      axios.post('/TaxonomyFamily', this.family)
        .then(response => {
          this.family.family = ''
          this.$toasted.global.saved()
          this.getTaxonomyFamily()
        })
    },
    addOrder: function () {
      if (!this.order.oorder) {
        this.$toasted.global.defaultError({ msg: this.$t('label.descricaoInvalida') })
        return false
      }
      if (!this.order.class_id) {
        this.$toasted.global.defaultError({ msg: this.$t('label.selecioneClasse') })
        return false
      }
      axios.post('/Taxonomyorder', this.order)
        .then(response => {
          this.order.oorder = ''
          this.$toasted.global.saved()
          this.getTaxonomyOrder()
        })
    },
    addClass: function () {
      if (!this.objClass.class) {
        this.$toasted.global.defaultError({ msg: this.$t('label.descricaoInvalida') })
        return false
      }
      if (!this.objClass.phyllum_id) {
        this.$toasted.global.defaultError({ msg: this.$t('label.selecioneDivisao') })
        return false
      }
      axios.post('/TaxonomyClass', this.objClass)
        .then(response => {
          this.objClass.class = ''
          this.$toasted.global.saved()
          this.getTaxonomyClass()
        })
    },
    addPhyllum: function () {
      if (!this.phyllum.phyllum) {
        this.$toasted.global.defaultError({ msg: this.$t('label.descricaoInvalida') })
        return false
      }
      if (!this.phyllum.kingdom_id) {
        this.$toasted.global.defaultError({ msg: this.$t('label.selectioneReino') })
        return false
      }
      axios.post('/Taxonomyphyllum', this.phyllum)
        .then(response => {
          this.phyllum.phyllum = ''
          this.$toasted.global.saved()
          this.getTaxonomyPhyllum()
        })
    },
    addKingdom: function () {
      if (!this.kingdom.kingdom) {
        this.$toasted.global.defaultError({ msg: this.$t('label.descricaoInvalida') })
        return false
      }
      axios.post('/Taxonomykingdom', this.kingdom)
        .then(response => {
          this.kingdom.kingdom = ''
          this.$toasted.global.saved()
          this.getTaxonomyKingdom()
        })
    },
    addSpecie: function () {
      this.$v.$touch()
      if (!this.specie.genus_id) {
        this.$toasted.global.defaultError({ msg: this.$t('label.selectioneGenero') })
        return false
      }
      if (!this.$v.specie.$invalid) {
        axios.post('/specie', this.specie)
          .then(response => {
            this.$router.push({ name: 'AdminEspeciesForm', params: { id: response.data.species_id } })
            this.$toasted.global.saved()
          })
      }
    },
    clearSelected: function () {
      this.taxonomyGenus = {
        taxonomyFamily: { taxonomyOrder: { taxonomyClass: { taxonomyPhyllum: { taxonomyKingdom: {} } } } }
      }
      this.selected.genus = ''
      this.selected.family = ''
      this.selected.order = ''
      this.selected.class = ''
      this.selected.phyllum = ''
      this.selected.kingdom = ''
      this.specie.genus_id = null
    },
    filterGenusSelected: async function (item) {
      const valor = `selected.genus-${item.genus_id}`
      if (this.selected.genus === valor) {
        this.clearSelected()
        this.getTaxonomyGenus()
      } else {
        this.specie.genus_id = item.genus_id
        this.selected.genus = valor
        await this.getTaxonomiaGenusById(item.genus_id)
        this.selected.family = `selected.family-${this.taxonomyGenus.family_id}`
        this.selected.order = `selected.order-${this.taxonomyGenus.taxonomyFamily.order_id}`
        this.selected.class = `selected.class-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id}`
        this.selected.phyllum = `selected.phyllum-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id}`
        this.selected.kingdom = `selected.kingdom-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id}`
      }
      this.getTaxonomyFamily()
      this.getTaxonomyOrder()
      this.getTaxonomyClass()
      this.getTaxonomyPhyllum()
      this.getTaxonomyKingdom()
    },
    salveEditGenus: function (value) {
      axios.put('/TaxonomyGenus', value)
        .then(() => {
          this.getTaxonomyGenus()
          this.$toasted.global.saved()
        })
    },
    cancelEditGenus: function () {
      this.getTaxonomyGenus()
    },
    removeGenus: function (item) {
      axios.delete(`/TaxonomyGenus/${item.genus_id}`)
        .then(() => {
          this.getTaxonomyGenus()
          this.$toasted.global.saved()
        })
    },

    filterFamilySelected: async function (item) {
      const valor = `selected.family-${item.family_id}`
      if (this.selected.family === valor) {
        this.clearSelected()
        this.getTaxonomyFamily()
        this.getTaxonomyOrder()
        this.getTaxonomyClass()
        this.getTaxonomyPhyllum()
        this.getTaxonomyKingdom()
        this.genus.family_id = null
      } else {
        this.selected.family = valor
        this.genus.family_id = item.family_id
        this.taxonomyGenus.taxonomyFamily.order_id = item.order_id
        await this.getTaxonomyOrder()
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id = this.taxonomyOrderList[0].class_id
        await this.getTaxonomyClass()
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id = this.taxonomyClassList[0].phyllum_id
        await this.getTaxonomyPhyllum()
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id = this.taxonomyPhyllumList[0].kingdom_id
        await this.getTaxonomyKingdom()
        this.selected.order = `selected.order-${this.taxonomyGenus.taxonomyFamily.order_id}`
        this.selected.class = `selected.class-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id}`
        this.selected.phyllum = `selected.phyllum-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id}`
        this.selected.kingdom = `selected.kingdom-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id}`
        this.taxonomyGenus = {
          family_id: item.family_id,
          taxonomyFamily: { taxonomyOrder: { taxonomyClass: { taxonomyPhyllum: { taxonomyKingdom: {} } } } }
        }
      }
      this.getTaxonomyGenus()
    },
    salveEditFamily: function (value) {
      axios.put('/TaxonomyFamily', value)
        .then(() => {
          this.getTaxonomyGenus()
          this.getTaxonomyFamily()
          this.$toasted.global.saved()
        })
    },
    cancelEditFamily: function () {
      this.getTaxonomyFamily()
    },
    removeFamily: function (item) {
      axios.delete(`/TaxonomyFamily/${item.family_id}`)
        .then(() => {
          this.getTaxonomyFamily()
          this.$toasted.global.saved()
        })
    },

    filterOrderSelected: async function (item) {
      const valor = `selected.order-${item.order_id}`
      if (this.selected.order === valor) {
        this.clearSelected()
        this.getTaxonomyOrder()
        this.getTaxonomyClass()
        this.getTaxonomyPhyllum()
        this.getTaxonomyKingdom()
        this.family.order_id = null
      } else {
        this.selected.order = valor
        this.family.order_id = item.order_id
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id = item.class_id
        await this.getTaxonomyClass()
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id = this.taxonomyClassList[0].phyllum_id
        await this.getTaxonomyPhyllum()
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id = this.taxonomyPhyllumList[0].kingdom_id
        await this.getTaxonomyKingdom()
        this.selected.class = `selected.class-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id}`
        this.selected.phyllum = `selected.phyllum-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id}`
        this.selected.kingdom = `selected.kingdom-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id}`
        this.taxonomyGenus = {
          taxonomyFamily: {
            order_id: item.order_id,
            taxonomyOrder: { taxonomyClass: { taxonomyPhyllum: { taxonomyKingdom: {} } } }
          }
        }
      }
      this.getTaxonomyGenus()
      this.getTaxonomyFamily()
    },
    salveEditOrder: function (value) {
      axios.put('/TaxonomyOrder', value)
        .then(() => {
          this.getTaxonomyGenus()
          this.getTaxonomyFamily()
          this.getTaxonomyOrder()
          this.$toasted.global.saved()
        })
    },
    cancelEditOrder: function () {
      this.getTaxonomyOrder()
    },
    removeOrder: function (item) {
      axios.delete(`/TaxonomyOrder/${item.order_id}`)
        .then(() => {
          this.getTaxonomyOrder()
          this.$toasted.global.saved()
        })
    },

    filterClassSelected: async function (item) {
      const valor = `selected.class-${item.class_id}`
      if (this.selected.class === valor) {
        this.clearSelected()
        this.getTaxonomyClass()
        this.getTaxonomyPhyllum()
        this.getTaxonomyKingdom()
        this.order.class_id = null
      } else {
        this.selected.class = valor
        this.order.class_id = item.class_id
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id = item.phyllum_id
        await this.getTaxonomyPhyllum()
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id = this.taxonomyPhyllumList[0].kingdom_id
        await this.getTaxonomyKingdom()
        this.selected.phyllum = `selected.phyllum-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id}`
        this.selected.kingdom = `selected.kingdom-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id}`
        this.taxonomyGenus = {
          taxonomyFamily: {
            taxonomyOrder: {
              class_id: item.class_id,
              taxonomyClass: { taxonomyPhyllum: { taxonomyKingdom: {} } }
            }
          }
        }
      }
      this.getTaxonomyGenus()
      this.getTaxonomyFamily()
      this.getTaxonomyOrder()
    },
    salveEditClass: function (value) {
      axios.put('/TaxonomyClass', value)
        .then(() => {
          this.getTaxonomyGenus()
          this.getTaxonomyFamily()
          this.getTaxonomyOrder()
          this.getTaxonomyClass()
          this.$toasted.global.saved()
        })
    },
    cancelEditClass: function () {
      this.getTaxonomyClass()
    },
    removeClass: function (item) {
      axios.delete(`/TaxonomyClass/${item.class_id}`)
        .then(() => {
          this.getTaxonomyClass()
          this.$toasted.global.saved()
        })
    },

    filterPhyllumSelected: async function (item) {
      const valor = `selected.phyllum-${item.phyllum_id}`
      if (this.selected.phyllum === valor) {
        this.clearSelected()
        this.getTaxonomyPhyllum()
        this.getTaxonomyKingdom()
        this.objClass.phyllum_id = null
      } else {
        this.selected.phyllum = valor
        this.objClass.phyllum_id = item.phyllum_id
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id = item.kingdom_id
        await this.getTaxonomyKingdom()
        this.selected.kingdom = `selected.kingdom-${this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id}`
        this.taxonomyGenus = {
          taxonomyFamily: {
            taxonomyOrder: {
              taxonomyClass: {
                phyllum_id: item.phyllum_id,
                taxonomyPhyllum: { taxonomyKingdom: {} }
              }
            }
          }
        }
      }
      this.getTaxonomyGenus()
      this.getTaxonomyFamily()
      this.getTaxonomyOrder()
      this.getTaxonomyClass()
    },
    salveEditPhyllum: function (value) {
      axios.put('/TaxonomyPhyllum', value)
        .then(() => {
          this.getTaxonomyGenus()
          this.getTaxonomyFamily()
          this.getTaxonomyOrder()
          this.getTaxonomyClass()
          this.getTaxonomyPhyllum()
          this.$toasted.global.saved()
        })
    },
    cancelEditPhyllum: function () {
      this.getTaxonomyPhyllum()
    },
    removePhyllum: function (item) {
      axios.delete(`/TaxonomyPhyllum/${item.phyllum_id}`)
        .then(() => {
          this.getTaxonomyPhyllum()
          this.$toasted.global.saved()
        })
    },

    filterKingdomSelected: function (item) {
      const valor = `selected.kingdom-${item.kingdom_id}`
      if (this.selected.kingdom === valor) {
        this.clearSelected()
        this.phyllum.kingdom_id = null
      } else {
        this.selected.kingdom = valor
        this.phyllum.kingdom_id = item.kingdom_id
        this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id = item.kingdom_id
        this.taxonomyGenus = {
          taxonomyFamily: {
            taxonomyOrder: {
              taxonomyClass: {
                taxonomyPhyllum: {
                  kingdom_id: item.kingdom_id,
                  taxonomyKingdom: {}
                }
              }
            }
          }
        }
      }
      this.getTaxonomyGenus()
      this.getTaxonomyFamily()
      this.getTaxonomyOrder()
      this.getTaxonomyClass()
      this.getTaxonomyPhyllum()
    },
    salveEditKingdom: function (value) {
      axios.put('/TaxonomyKingdom', value)
        .then(() => {
          this.getAllTaxonomy()
          this.$toasted.global.saved()
        })
    },
    cancelEditKingdom: function () {
      this.getTaxonomyKingdom()
    },
    removeKingdom: function (item) {
      axios.delete(`/TaxonomyKingdom/${item.kingdom_id}`)
        .then(() => {
          this.getTaxonomyKingdom()
          this.$toasted.global.saved()
        })
    },

    getTaxonomyGenus: function () {
      this.viewEdit.genus = ''
      const params = { Page: 1, PageSize: 9999 }
      params.genus = this.search.genus
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum) {
        params.kingdom_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass) {
        params.phyllum_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder) {
        params.class_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id
      }
      if (this.taxonomyGenus.taxonomyFamily) {
        params.order_id = this.taxonomyGenus.taxonomyFamily.order_id
      }
      if (this.taxonomyGenus.family_id) {
        params.family_id = this.taxonomyGenus.family_id
      }
      axios.get('/TaxonomyGenus/getall', { params })
        .then(response => {
          this.taxonomyGenusList = response.data.items
        })
    },
    getTaxonomyFamily: async function () {
      this.viewEdit.family = ''
      const params = { Page: 1, PageSize: 9999 }
      params.family = this.search.family
      if (this.taxonomyGenus.family_id) {
        params.family_id = this.taxonomyGenus.family_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum) {
        params.kingdom_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass) {
        params.phyllum_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder) {
        params.class_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id
      }
      if (this.taxonomyGenus.taxonomyFamily) {
        params.order_id = this.taxonomyGenus.taxonomyFamily.order_id
      }
      await axios.get('/TaxonomyFamily/getall', { params })
        .then(response => {
          this.taxonomyFamilyList = response.data.items
          if (!this.search.family && !params.family_id && !params.kingdom_id && !params.phyllum_id && !params.class_id && !params.order_id) {
            this.taxonomyFamilySelect = response.data.items
          }
        })
    },
    getTaxonomyOrder: async function () {
      this.viewEdit.order = ''
      const params = { Page: 1, PageSize: 9999 }
      params.oorder = this.search.order
      if (this.taxonomyGenus.taxonomyFamily) {
        params.order_id = this.taxonomyGenus.taxonomyFamily.order_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum) {
        params.kingdom_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass) {
        params.phyllum_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder) {
        params.class_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id
      }
      await axios.get('/TaxonomyOrder/getall', { params })
        .then(response => {
          this.taxonomyOrderList = response.data.items
          if (!this.search.order && !params.order_id && !params.kingdom_id && !params.phyllum_id && !params.class_id) {
            this.taxonomyOrderSelect = response.data.items
          }
        })
    },
    getTaxonomyClass: async function () {
      this.viewEdit.class = ''
      const params = { Page: 1, PageSize: 9999 }
      params.class = this.search.class
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder) {
        params.class_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.class_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum) {
        params.kingdom_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass) {
        params.phyllum_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id
      }
      await axios.get('/TaxonomyClass/getall', { params })
        .then(response => {
          this.taxonomyClassList = response.data.items
          if (!this.search.class && !params.class_id && !params.kingdom_id && !params.phyllum_id) {
            this.taxonomyClassSelect = response.data.items
          }
        })
    },
    getTaxonomyPhyllum: async function () {
      this.viewEdit.phyllum = ''
      const params = { Page: 1, PageSize: 9999 }
      params.phyllum = this.search.phyllum
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass) {
        params.phyllum_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.phyllum_id
      }
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum) {
        params.kingdom_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id
      }
      await axios.get('/TaxonomyPhyllum/getall', { params })
        .then(response => {
          this.taxonomyPhyllumList = response.data.items
          if (!this.search.phyllum && !params.phyllum_id && !params.kingdom_id) {
            this.taxonomyPhyllumSelect = response.data.items
          }
        })
    },
    getTaxonomyKingdom: async function () {
      this.viewEdit.kingdom = ''
      const params = { Page: 1, PageSize: 9999 }
      params.kingdom = this.search.kingdom
      if (this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum) {
        params.kingdom_id = this.taxonomyGenus.taxonomyFamily.taxonomyOrder.taxonomyClass.taxonomyPhyllum.kingdom_id
      }
      await axios.get('/TaxonomyKingdom/getall', { params })
        .then(response => {
          this.taxonomyKingdomList = response.data.items
          if (!this.search.kingdom && !params.kingdom_id) {
            this.taxonomyKingdomSelect = response.data.items
          }
        })
    },
    getTaxonomiaGenusById: async function (id) {
      await axios.get(`/TaxonomyGenus/${id}`)
        .then(response => {
          this.taxonomyGenus = response.data
        })
    },
    getAllTaxonomy: function () {
      this.getTaxonomyGenus()
      this.getTaxonomyFamily()
      this.getTaxonomyOrder()
      this.getTaxonomyClass()
      this.getTaxonomyPhyllum()
      this.getTaxonomyKingdom()
    }
  },
  created () {
    this.getAllTaxonomy()
  }
}
</script>
