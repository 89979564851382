<template>
  <div>
    <div class="table-responsive full_table mt-0">
      <table class="table caption-top">
        <thead class="bg_table_header">
          <tr>
            <th v-if="!byInstitucional" class="col-md-1 py-3">ID<button v-if="withOrderBy" class="bt_order_asc bt_order" @click="orderBy(4)"></button><button v-if="withOrderBy" class="bt_order_desc bt_order" @click="orderBy(-4)"></button></th>
            <th class="col-md-4 py-3 px-2">{{ $t('label.titulo') }}<button v-if="withOrderBy" class="bt_order_asc bt_order" @click="orderBy(1)"></button><button v-if="withOrderBy" class="bt_order_desc bt_order" @click="orderBy(-1)"></button></th>
            <th class="col-md-2 py-3 px-2">{{ $t('label.contato') }}<button v-if="withOrderBy" class="bt_order_asc bt_order" @click="orderBy(2)"></button><button v-if="withOrderBy" class="bt_order_desc bt_order" @click="orderBy(-2)"></button></th>
            <th class="col-md-3 py-3 px-2">{{ $t('label.areaGeografica') }}<button v-if="withOrderBy" class="bt_order_asc bt_order" @click="orderBy(3)"></button><button v-if="withOrderBy" class="bt_order_desc bt_order" @click="orderBy(-3)"></button></th>
            <th class="col-md-2 py-3 px-2 pr-4 text-right">{{ $t('label.acoes') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$store.state.countList === 0"><td :colspan="byInstitucional ? 5 : 4" class="text-center">{{ $t('message.noRecords') }}</td></tr>
          <tr v-else v-for="item in items" :key="item.project_id">
            <td v-if="!byInstitucional" class="py-3">{{item.project_id}}</td>
            <td class="py-3 px-2">{{item.title}}</td>
            <td class="py-3 px-2">{{item.dataContacts ? item.dataContacts.surname : ''}}, {{item.dataContacts ? item.dataContacts.name : ''}}</td>
            <td class="py-3 pl-4 pr-2">{{item.geo_area}}</td>
            <td class="py-3 px-2">
              <ul class="acoes text-right pr-1">
                <template v-if="byInstitucional">
                  <li>
                    <a @click="actionByInstitucional(item)"><span class="visualizar"></span></a>
                  </li>
                </template>
                <template v-else>
                  <li>
                    <a @click="action($actionEdit, item)" v-if="item.pending_analysis"><span class="nao-validado"></span></a>
                    <a @click="action($actionEdit, item)" v-else><span class="validar"></span></a>
                  </li>
                  <li>
                    <a @click="action($actionEdit, item)"><span class="editar"></span></a>
                  </li>
                  <li>
                    <a @click="action($actionDelete, item)" v-if="$store.state.user && $store.state.user.perfilUser === 'administrator'"><span class="apagar"></span></a>
                  </li>
                </template>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TablePagination v-on:searchTable="search()"/>
  </div>
</template>

<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
export default {
  name: 'ProjetosTable',
  components: { TablePagination },
  props: {
    items: Array,
    byInstitucional: {
      type: Boolean,
      default: false
    },
    withOrderBy: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      dataOrderBy: {}
    }
  },
  methods: {
    action: function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        if (this.$route.name === 'AdminHome') {
          this.$store.state.dashboard.currentPage = this.$store.state.currentPage
        }
        this.$router.push({ name: 'AdminProjetosForm', params: { id: item.project_id } })
      }
    },
    delete: function (item) {
      axios.delete(`/project/${item.project_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      this.$emit('search')
    },
    actionByInstitucional: function (item) {
      this.$emit('actionByInstitucional', item)
    },
    orderBy: function (num) {
      if (num === 1) {
        this.$store.state.dataOrderBy.OrderByTitleAcs = true
        this.$store.state.dataOrderBy.OrderByTitleDesc = !this.$store.state.dataOrderBy.OrderByTitleAcs
      }
      if (num === -1) {
        this.$store.state.dataOrderBy.OrderByTitleDesc = true
        this.$store.state.dataOrderBy.OrderByTitleAcs = !this.$store.state.dataOrderBy.OrderByTitleDesc
      }
      if (num === 2) {
        this.$store.state.dataOrderBy.OrderBySurnameAcs = true
        this.$store.state.dataOrderBy.OrderBySurnameDesc = !this.$store.state.dataOrderBy.OrderBySurnameAcs
      }
      if (num === -2) {
        this.$store.state.dataOrderBy.OrderBySurnameDesc = true
        this.$store.state.dataOrderBy.OrderBySurnameAcs = !this.$store.state.dataOrderBy.OrderBySurnameDesc
      }
      if (num === 3) {
        this.$store.state.dataOrderBy.OrderByGeoAreaAcs = true
        this.$store.state.dataOrderBy.OrderByGeoAreaDesc = !this.$store.state.dataOrderBy.OrderByGeoAreaAcs
      }
      if (num === -3) {
        this.$store.state.dataOrderBy.OrderByGeoAreaDesc = true
        this.$store.state.dataOrderBy.OrderByGeoAreaAcs = !this.$store.state.dataOrderBy.OrderByGeoAreaDesc
      }
      if (num === 4) {
        this.$store.state.dataOrderBy.OrderByIDAcs = true
        this.$store.state.dataOrderBy.OrderByIDDesc = !this.$store.state.dataOrderBy.OrderByIDAcs
      }
      if (num === -4) {
        this.$store.state.dataOrderBy.OrderByIDDesc = true
        this.$store.state.dataOrderBy.OrderByIDAcs = !this.$store.state.dataOrderBy.OrderByIDDesc
      }
      this.$emit('orderBy', this.$store.state.dataOrderBy)
    }
  }
}
</script>
