<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('baseDadosNacional.cabecalhoTitulo') }`" :classProp="'header-single header-rede-nacional'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 pt-5 ">

          <h2 class="laranja">{{ $t('baseDadosNacional.titulo1') }}</h2>
          <p>{{ $t('baseDadosNacional.paragrafo1') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo2') }}</p>
        </div>
      </div>
    </div>

    <div class="container logo-parceiros logos-apoio-argentina pb-5">
      <div class="row">
        <div class="col-sm">
          <h2 class="h5 laranja">{{ $t('baseDadosNacional.titulo5') }}</h2>

          <div class="row mt-5">
            <div class="col-sm col-md-12">
              <ul>
                <li><img src="../../../assets/institucional/img/logo/apoio-guyra.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-made-py.png" ></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'BaseDadosNacional',
  components: { InstitucionalHeaderSingle }
}
</script>
