<template>
  <div class="container-fluid p-0">
    <PageTitle :title="$t('label.ocorrenciasApp')" :isAdd="true"/>
    <!-- filtro -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body p-0 box-dashboard">
            <div class="align-self-center">
              <form class="filtro_especie" @submit.prevent="search">
                <div class="row g-3 p-3 pb-0">
                  <div class="col-12 col-lg-4">
                    <v-select :appendToBody="true" :options="statesList" v-model="$store.state.formSearch.state_id" label="state" index="state_id"
                      :reduce="r => r.state_id" :clearable="false" class="bg-laranja-select vue-select">
                      <template v-slot:selected-option="option">
                        {{ `${option.state}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.state}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-lg-4">
                    <v-select :appendToBody="true" @search="getVocMunicipios" :options="vocMunicipiosList" v-model="$store.state.formSearch.municipio_id" label="municipio" index="municipio_id"
                      :reduce="r => r.municipio_id" :clearable="false" class="bg-laranja-select vue-select">
                      <template v-slot:selected-option="option">
                        {{ `${option.municipio}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.municipio}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-lg-4">
                    <input type="text" class="form-control bg-laranja" :placeholder="$t('label.referenciaLocal')" v-model="$store.state.formSearch.location"/>
                  </div>

                  <div class="col-12 col-lg-4 ml-auto text-right pt-1">
                    <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2 mr-1">
                      {{ $t('label.limpar') }}
                    </button>
                    <button type="submit" class="btn bt-filtrar mt-1 mb-2">
                      {{ $t('label.buscar') }}
                    </button>
                  </div>
                </div>
              </form>

              <div class="row mt-1 mt-xl-2 p-3 bg_table_header">
                <div class="col-auto d-none d-sm-block">
                  <p class="mb-0 mt-2">
                    {{ $t('label.numRegistrosEncontrados') }}
                    <span class="laranja">{{$store.state.countList}}</span>
                  </p>
                </div>

                <div class="col-auto ml-auto text-right select_registro">
                  <select class="selectpicker" ref="select" v-model="$store.state.perPage" @change="search">
                    <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                    <option value="25">{{ $t('label.exibir25Reg') }}</option>
                    <option value="50">{{ $t('label.exibir50Reg') }}</option>
                    <option value="100">{{ $t('label.exibir100Reg') }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /filtro -->

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
              <OcorrenciasAppTable @orderBy="orderBy" @search="search" :items="items"/>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import OcorrenciasAppTable from './OcorrenciasAppTable.vue'
export default {
  name: 'OcorrenciasApp',
  components: { OcorrenciasAppTable, PageTitle },
  data: function () {
    return {
      items: [],
      speciesList: [{ species_id: '', scientific_name: this.$t('label.nomeCientifico') }],
      statesList: [{ state_id: '', state: this.$t('label.estado') }],
      vocMunicipiosList: [{ municipio_id: '', municipio: this.$t('label.municipio') }],
      dataOrderBy: {}
    }
  },
  watch: {
    '$store.state.formSearch.state_id' (newValue, oldValue) {
      if (oldValue !== undefined) {
        this.$store.state.formSearch.municipio_id = ''
      }
      this.getVocMunicipios()
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.$store.state.formSearch.OrderByStateAcs = this.$store.state.dataOrderBy.OrderByStateAcs
      this.$store.state.formSearch.OrderByStateDesc = this.$store.state.dataOrderBy.OrderByStateDesc
      this.$store.state.formSearch.OrderByMunicipioAcs = this.$store.state.dataOrderBy.OrderByMunicipioAcs
      this.$store.state.formSearch.OrderByMunicipioDesc = this.$store.state.dataOrderBy.OrderByMunicipioDesc
      this.$store.state.formSearch.OrderByLocationAcs = this.$store.state.dataOrderBy.OrderByLocationAcs
      this.$store.state.formSearch.OrderByLocationDesc = this.$store.state.dataOrderBy.OrderByLocationDesc
    },
    getSpecies: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.$store.state.formSearch.species_id
        loading(true)
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ species_id: '', scientific_name: this.$t('label.nomeCientifico') })
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.$store.state.formSearch.species_id
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ species_id: '', scientific_name: this.$t('label.nomeCientifico') })
          })
      }
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
          this.statesList.unshift({ state_id: '', state: this.$t('label.estado') })
        })
    },
    getVocMunicipios: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 30,
        State_id: this.$store.state.formSearch.state_id ? this.$store.state.formSearch.state_id : null
      }
      if (search && search.length > 2) {
        params.municipio = search
        params.municipio_id = this.$store.state.formSearch.municipio_id === '' ? null : this.$store.state.formSearch.municipio_id
        loading(true)
        axios.get('/VocMunicipios/getall', { params })
          .then(response => {
            this.vocMunicipiosList = response.data.items
            this.vocMunicipiosList.unshift({ municipio_id: '', municipio: this.$t('label.municipio') })
            loading(false)
          })
      } else if (!search) {
        params.municipio_id = this.$store.state.formSearch.municipio_id === '' ? null : this.$store.state.formSearch.municipio_id
        axios.get('/VocMunicipios/getall', { params })
          .then(response => {
            this.vocMunicipiosList = response.data.items
            this.vocMunicipiosList.unshift({ municipio_id: '', municipio: this.$t('label.municipio') })
          })
      }
    },
    search: async function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.pending_analysis = true
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      await axios.get('/DataPlacesForApproval/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    }
  },
  created: async function () {
    await this.search()
    this.getStates()
    this.getVocMunicipios()
  }
}
</script>
