<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===3 }" id="dispersao" role="tabpanel" aria-labelledby="dispersao-tab">
    <div class="container-fluid  mt-3">
      <div class="row">
          <div class="col-md-6">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisOrigin }">
              <label for="introducao-referencias" class="form-label">{{ $t('label.viasCDBCategorias') }}</label>
              <button v-if="isPendingAnalysisOrigin" class="btn" type="button" @click="$emit('analysis', $t('label.viasCDBCategorias'), 'relPathwaysCdbSelected', vocPathwaysCdbList, relPathwaysCdbSelected, oldRelPathwaysCdbSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocPathwaysCdbList" v-model="relPathwaysCdbSelected" label="value" index="pathways_cdb_id"
                :reduce="r => r.pathways_cdb_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelRoute }">
              <label for="introducao-referencias" class="form-label">{{ $t('label.viasIntroducao') }}</label>
              <button v-if="isPendingAnalysisRelRoute" class="btn" type="button" @click="$emit('analysis', $t('label.viasIntroducao'), 'relRouteSelected', vocDispersalRoutesList, relRouteSelected, oldRelRouteSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocDispersalRoutesList" v-model="relRouteSelected" label="value" index="route_id"
                :reduce="r => r.route_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
          </div>
          <div class="col-md-6 border-left">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelVectorsCdb }">
              <label for="introducao-referencias" class="form-label">{{ $t('label.viasVetoresCDBSubcategorias') }}</label>
              <button v-if="isPendingAnalysisRelVectorsCdb" class="btn" type="button" @click="$emit('analysis', $t('label.viasVetoresCDBSubcategorias'), 'relVectorsCdbSelected', vocVectorsCdbList, relVectorsCdbSelected, oldRelVectorsCdbSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocVectorsCdbList" v-model="relVectorsCdbSelected" label="value" index="vectors_cdb_id"
                :reduce="r => r.vectors_cdb_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelVector }">
              <label for="introducao-referencias" class="form-label">{{ $t('label.vetoresIntroducao') }}</label>
              <button v-if="isPendingAnalysisRelVector" class="btn" type="button" @click="$emit('analysis', $t('label.vetoresIntroducao'), 'relVectorSelected', vocDispersionVectorsList, relVectorSelected, oldRelVectorSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocDispersionVectorsList" v-model="relVectorSelected" label="value" index="vector_id"
                :reduce="r => r.vector_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
          </div>
      </div>
      <div class="row border-top">
        <div class="col-md-12 mb-3 mt-3">
          <div class="mb-3">
            <div class="d-flex">
              <div>
                <label for="analise-Introduction" class="form-label">{{ $t('label.introducao') }}</label>
              </div>
              <div class="ml-auto text-right" v-if="!byInstitucional">
                <a class="bt-add-registro" @click="add">{{ $t('label.adicionarIntroducao') }}</a>
              </div>
            </div>
          </div>
          <div class="card-body box-dashboard p-0 mb-3">
            <div class="align-self-center">
                <div class="table-responsive full_table mt-0 ">
                  <table class="table caption-top">
                      <thead class="bg_table_header">
                        <tr>
                            <th class="py-3 col-md-2 pl-4">{{ $t('label.tipoIntroducao') }}</th>
                            <th class="py-3 col-md-2 pl-4">{{ $t('label.causaIntroducao') }}</th>
                            <th class="py-3 col-md-2 pl-4">{{ $t('label.localIntroducao') }}</th>
                            <th class="py-3 col-md-1 pl-4">{{ $t('label.ano') }}</th>
                            <th class="py-3 col-md-3 px-2">{{ $t('label.referencias') }}</th>
                            <th class="py-3 col-md-2 px-2 pr-4 text-right">{{ $t('label.acoes') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="addIntroduction || editIntroduction" key="add=edit">
                          <td class="py-3" colspan="6">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('label.tipoIntroducao') }}</label>
                                    <v-select :appendToBody="true" :options="vocIntroductionTypeList" v-model="newIntroduction.introduction_type_id" label="value" index="introduction_type_id"
                                      :reduce="lang => lang.introduction_type_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                                      <template v-slot:selected-option="option">
                                        {{ `${option.value}` }}
                                      </template>
                                      <template v-slot:option="option">
                                        {{ `${option.value}` }}
                                      </template>
                                      <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                        </template>
                                      </template>
                                    </v-select>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('label.causaIntroducao') }}</label>
                                    <v-select :appendToBody="true" :options="vocCauseIntroductionList" v-model="newIntroduction.cause_introduction_id" label="value" index="cause_introduction_id"
                                      :reduce="lang => lang.cause_introduction_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                                      <template v-slot:selected-option="option">
                                        {{ `${option.value}` }}
                                      </template>
                                      <template v-slot:option="option">
                                        {{ `${option.value}` }}
                                      </template>
                                      <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                        </template>
                                      </template>
                                    </v-select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="mb-3">
                                  <label class="form-label">{{ $t('label.localIntroducao') }}</label>
                                  <input type="text" class="form-control" v-model="newIntroduction.location" :disabled="byInstitucional">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="mb-3">
                                  <label class="form-label">{{ $t('label.ano') }}</label>
                                  <input v-model="newIntroduction.intro_data" v-mask="'####'" class="form-control" type="text" maxLength="4" :disabled="byInstitucional">
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('label.referencias') }}</label>
                                    <v-select :appendToBody="true" :options="dataBibliographyList" v-model="newIntroduction.biblio_id" label="title" index="bibliography_id"
                                      :reduce="lang => lang.bibliography_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                                      <template v-slot:selected-option="option">
                                        {{ `${option.title}` }}
                                      </template>
                                      <template v-slot:option="option">
                                        {{ `${option.title}` }}
                                      </template>
                                      <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                        </template>
                                      </template>
                                    </v-select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mb-3">
                                  <label class="form-label">{{ $t('label.descricaoIntroducao') }}</label>
                                  <textarea v-model="newIntroduction.intro_description" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 text-right">
                                <div class="mb-3">
                                  <a class="bt-add-registro" @click="salve" v-if="!byInstitucional">{{ $t('label.salvar') }}</a>
                                  <a class="bt-add-registro bg-danger ml-1" @click="cancel">{{ $t('label.cancelar') }}</a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="form.dataIntroduction && form.dataIntroduction.length === 0"><td colspan="7" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                        <tr v-else v-for="item in form.dataIntroduction" :key="item.introduction_id" v-show="item.introduction_id !== editing" :class="{ 'pending_analysis': checkDataIntroduction(item) }">
                          <td class="py-3 pl-4 pr-2">{{getVocIntroductionTypeGrid(item)}}<br/></td>
                          <td class="py-3  px-2">{{getVocCauseIntroductionGrid(item)}}</td>
                          <td class="py-3  px-2">{{item.location}}</td>
                          <td class="py-3  px-2">{{item.intro_data}}</td>
                          <td class="py-3  px-2">{{getBibliographyGrid(item)}}</td>
                          <td class="py-3 px-2 ">
                            <ul class="acoes text-right pr-1" v-if="!byInstitucional">
                              <li>
                                <a @click="edit(item)"><span class="editar"></span></a>
                              </li>
                              <li>
                                <a @click="remove(item)"><span class="apagar"></span></a>
                              </li>
                            </ul>
                            <ul class="acoes text-right pr-1" v-else>
                              <li>
                                <a @click="edit(item)"><span class="visualizar"></span></a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EspeciesTabIntroducaoDispersao',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorIntroducaoDispersao: Boolean,
    onlyLoadValidator: Boolean,
    dataBibliographyList: Array,
    pendingAnalysis: Boolean
  },
  data () {
    return {
      vocDispersalRoutesList: [],
      relRouteSelected: [],
      oldRelRouteSelected: [],
      relPathwaysCdbSelected: [],
      oldRelPathwaysCdbSelected: [],
      relVectorsCdbSelected: [],
      oldRelVectorsCdbSelected: [],
      vocDispersionVectorsList: [],
      relVectorSelected: [],
      oldRelVectorSelected: [],
      addIntroduction: false,
      editIntroduction: false,
      introductionOld: {},
      newIntroduction: {
        introduction_id: new Date().getTime(),
        species_id: this.form.species_id,
        intro_data: '',
        biblio_id: null,
        introduction_type_id: 0,
        cause_introduction_id: 0,
        location: '',
        intro_description: '',
        vocIntroductionType: {
          introduction_type_id: 0,
          en: '',
          pt: '',
          es: ''
        },
        vocCauseIntroduction: {
          cause_introduction_id: 0,
          en: '',
          pt: '',
          es: ''
        }
      },
      editing: -1,
      vocIntroductionTypeList: [],
      vocCauseIntroductionList: [],
      vocPathwaysCdbList: [],
      vocVectorsCdbList: []
    }
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorIntroducaoDispersao' (newValue) {
      if (newValue) {
        this.$v.$touch()
        const obj = {
          relRouteSelected: this.relRouteSelected,
          relPathwaysCdbSelected: this.relPathwaysCdbSelected,
          relVectorsCdbSelected: this.relVectorsCdbSelected,
          relVectorSelected: this.relVectorSelected
        }
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v, obj)
      }
    },
    'form.relRoute' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelRouteSelected()
        this.getOldRelRouteSelected()
      }
    },
    'form.relPathwaysCdb' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelPathwaysCdbSelected()
        this.getOldRelPathwaysCdbSelected()
      }
    },
    'form.relVectorsCdb' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelVectorsCdbSelected()
        this.getOldRelVectorsCdbSelected()
      }
    },
    'form.relVector' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelVectorSelected()
        this.getOldRelVectorSelected()
      }
    },
    '$i18n.locale' () {
      this.getVocDispersalRoutes()
      this.getVocDispersionVectors()
      this.getVocIntroductionType()
      this.getVocCauseIntroduction()
      this.getVocPathwaysCdb()
      this.getVocVectorsCdb()
    }
  },
  computed: {
    isPendingAnalysis: function () {
      return !this.byInstitucional && this.pendingAnalysis
    },
    isPendingAnalysisOrigin: function () {
      const result = this.isPendingAnalysis && this.pendingRelPathwaysCdb
      this.$emit('especiesTabIntroducaoDispersaoPending', result)
      return result
    },
    isPendingAnalysisRelRoute: function () {
      const result = this.isPendingAnalysis && this.pendingRelRoute
      this.$emit('especiesTabIntroducaoDispersaoPending', result)
      return result
    },
    isPendingAnalysisRelVectorsCdb: function () {
      const result = this.isPendingAnalysis && this.pendingRelVectorsCdb
      this.$emit('especiesTabIntroducaoDispersaoPending', result)
      return result
    },
    isPendingAnalysisRelVector: function () {
      const result = this.isPendingAnalysis && this.pendingRelVector
      this.$emit('especiesTabIntroducaoDispersaoPending', result)
      return result
    },
    pendingRelRoute: function () {
      let result = false
      if (this.relRouteSelected.length !== this.oldRelRouteSelected.length) {
        return true
      }
      this.oldRelRouteSelected.forEach(e => {
        if (!this.relRouteSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelVector: function () {
      let result = false
      if (this.relVectorSelected.length !== this.oldRelVectorSelected.length) {
        return true
      }
      this.oldRelVectorSelected.forEach(e => {
        if (!this.relVectorSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelPathwaysCdb: function () {
      let result = false
      if (this.relPathwaysCdbSelected.length !== this.oldRelPathwaysCdbSelected.length) {
        return true
      }
      this.oldRelPathwaysCdbSelected.forEach(e => {
        if (!this.relPathwaysCdbSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelVectorsCdb: function () {
      let result = false
      if (this.relVectorsCdbSelected.length !== this.oldRelVectorsCdbSelected.length) {
        return true
      }
      this.oldRelVectorsCdbSelected.forEach(e => {
        if (!this.relVectorsCdbSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  methods: {
    checkDataIntroduction: function (obj) {
      if (this.form.log_update && this.form.log_update.DataIntroduction) {
        const item = this.form.log_update.DataIntroduction.filter(f =>
          f.Biblio_id === obj.biblio_id &&
          f.Cause_introduction_id === obj.cause_introduction_id &&
          f.Intro_data === obj.intro_data &&
          f.Intro_description === obj.intro_description &&
          f.Introduction_type_id === obj.introduction_type_id &&
          f.Location === obj.location &&
          f.Species_id === obj.species_id
        )
        const result = !this.byInstitucional && this.pendingAnalysis && item.length === 0
        if (result) {
          this.$emit('especiesTabIntroducaoDispersaoPending', result)
        }
        return result
      }
      return false
    },
    getBibliographyGrid: function (value) {
      const item = this.dataBibliographyList.filter(f => f.bibliography_id === value.biblio_id)[0]
      return item ? item.title : ''
    },
    getVocIntroductionTypeGrid: function (value) {
      const item = this.vocIntroductionTypeList.filter(f => f.introduction_type_id === value.introduction_type_id)[0]
      return item ? item.value : ''
    },
    getVocCauseIntroductionGrid: function (value) {
      const item = this.vocCauseIntroductionList.filter(f => f.cause_introduction_id === value.cause_introduction_id)[0]
      return item ? item.value : ''
    },
    remove: function (item) {
      if (confirm(this.$t('message.confirmDel'))) {
        this.form.dataIntroduction = this.form.dataIntroduction.filter(f => f.introduction_id !== item.introduction_id)
      }
    },
    add: function () {
      this.addIntroduction = true
      this.newIntroduction = {
        introduction_id: new Date().getTime(),
        species_id: this.form.species_id,
        intro_data: '',
        biblio_id: null,
        introduction_type_id: 0,
        cause_introduction_id: 0,
        location: '',
        intro_description: '',
        vocIntroductionType: {
          introduction_type_id: 0,
          en: '',
          pt: '',
          es: ''
        },
        vocCauseIntroduction: {
          cause_introduction_id: 0,
          en: '',
          pt: '',
          es: ''
        }
      }
    },
    edit: function (item) {
      this.addIntroduction = false
      this.editIntroduction = true
      this.introductionOld = {
        introduction_id: item.introduction_id,
        species_id: item.species_id,
        intro_data: item.intro_data,
        biblio_id: item.biblio_id,
        introduction_type_id: item.introduction_type_id,
        cause_introduction_id: item.cause_introduction_id,
        location: item.location,
        intro_description: item.intro_description,
        vocIntroductionType: item.vocIntroductionType,
        vocCauseIntroduction: item.vocCauseIntroduction,
        dataBibliography: item.dataBibliography
      }
      this.newIntroduction = item
      this.editing = item.introduction_id
    },
    cancel: function () {
      this.addIntroduction = false
      this.editIntroduction = false
      this.editing = ''
      this.form.dataIntroduction.forEach(i => {
        if (i.introduction_id === this.newIntroduction.introduction_id) {
          i.introduction_id = this.introductionOld.introduction_id
          i.species_id = this.introductionOld.species_id
          i.intro_data = this.introductionOld.intro_data
          i.biblio_id = this.introductionOld.biblio_id
          i.introduction_type_id = this.introductionOld.introduction_type_id
          i.cause_introduction_id = this.introductionOld.cause_introduction_id
          i.location = this.introductionOld.location
          i.intro_description = this.introductionOld.intro_description
          i.vocIntroductionType = this.introductionOld.vocIntroductionType
          i.vocCauseIntroduction = this.introductionOld.vocCauseIntroduction
          i.dataBibliography = this.introductionOld.dataBibliography
        }
      })
    },
    salve: function () {
      if (this.addIntroduction) {
        this.form.dataIntroduction.push(this.newIntroduction)
        this.newIntroduction = {
          introduction_id: new Date().getTime(),
          species_id: this.form.species_id,
          intro_data: '',
          biblio_id: null,
          introduction_type_id: 0,
          cause_introduction_id: 0,
          location: '',
          intro_description: '',
          vocIntroductionType: {
            introduction_type_id: 0,
            en: '',
            pt: '',
            es: ''
          },
          vocCauseIntroduction: {
            cause_introduction_id: 0,
            en: '',
            pt: '',
            es: ''
          }
        }
      }
      this.addIntroduction = false
      this.editIntroduction = false
      this.editing = ''
    },
    getRelRouteSelected: function () {
      this.form.relRoute.forEach(e => {
        if (!this.relRouteSelected.includes(Number(e.route_id))) {
          this.relRouteSelected.push(Number(e.route_id))
        }
      })
    },
    getOldRelRouteSelected: function () {
      if (this.form.log_update && this.form.log_update.RelRoute) {
        this.form.log_update.RelRoute.forEach(e => {
          if (!this.oldRelRouteSelected.includes(Number(e.Route_id))) {
            this.oldRelRouteSelected.push(Number(e.Route_id))
          }
        })
      }
    },
    getRelPathwaysCdbSelected: function () {
      this.form.relPathwaysCdb.forEach(e => {
        if (!this.relPathwaysCdbSelected.includes(Number(e.pathways_cdb_id))) {
          this.relPathwaysCdbSelected.push(Number(e.pathways_cdb_id))
        }
      })
    },
    getOldRelPathwaysCdbSelected: function () {
      if (this.form.log_update && this.form.log_update.RelPathwaysCdb) {
        this.form.log_update.RelPathwaysCdb.forEach(e => {
          if (!this.oldRelPathwaysCdbSelected.includes(Number(e.Pathways_cdb_id))) {
            this.oldRelPathwaysCdbSelected.push(Number(e.Pathways_cdb_id))
          }
        })
      }
    },
    getRelVectorsCdbSelected: function () {
      this.form.relVectorsCdb.forEach(e => {
        if (!this.relVectorsCdbSelected.includes(Number(e.vectors_cdb_id))) {
          this.relVectorsCdbSelected.push(Number(e.vectors_cdb_id))
        }
      })
    },
    getOldRelVectorsCdbSelected: function () {
      if (this.form.log_update && this.form.log_update.RelVectorsCdb) {
        this.form.log_update.RelVectorsCdb.forEach(e => {
          if (!this.oldRelVectorsCdbSelected.includes(Number(e.Vectors_cdb_id))) {
            this.oldRelVectorsCdbSelected.push(Number(e.Vectors_cdb_id))
          }
        })
      }
    },
    getOldRelVectorSelected: function () {
      if (this.form.log_update && this.form.log_update.RelVector) {
        this.form.log_update.RelVector.forEach(e => {
          if (!this.oldRelVectorSelected.includes(Number(e.Vector_id))) {
            this.oldRelVectorSelected.push(Number(e.Vector_id))
          }
        })
      }
    },
    getRelVectorSelected: function () {
      this.form.relVector.forEach(e => {
        if (!this.relVectorSelected.includes(Number(e.vector_id))) {
          this.relVectorSelected.push(Number(e.vector_id))
        }
      })
    },
    getVocDispersalRoutes: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocdispersalroutes/getall', { params })
        .then(response => {
          this.vocDispersalRoutesList = response.data.items.map(i => {
            const item = {
              route_id: i.route_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDispersionVectors: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocdispersionvectors/getall', { params })
        .then(response => {
          this.vocDispersionVectorsList = response.data.items.map(i => {
            const item = {
              vector_id: i.vector_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocIntroductionType: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocIntroductionType/getall', { params })
        .then(response => {
          this.vocIntroductionTypeList = response.data.items.map(i => {
            const item = {
              introduction_type_id: i.introduction_type_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocCauseIntroduction: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocCauseIntroduction/getall', { params })
        .then(response => {
          this.vocCauseIntroductionList = response.data.items.map(i => {
            const item = {
              cause_introduction_id: i.cause_introduction_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPathwaysCdb: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocPathwaysCdb/getall', { params })
        .then(response => {
          this.vocPathwaysCdbList = response.data.items.map(i => {
            const item = {
              pathways_cdb_id: i.pathways_cdb_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocVectorsCdb: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocVectorsCdb/getall', { params })
        .then(response => {
          this.vocVectorsCdbList = response.data.items.map(i => {
            const item = {
              vectors_cdb_id: i.vectors_cdb_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    }
  },
  created () {
    this.getVocPathwaysCdb()
    this.getVocVectorsCdb()
    this.getVocDispersalRoutes()
    this.getVocDispersionVectors()
    this.getVocIntroductionType()
    this.getVocCauseIntroduction()
  }
}
</script>
