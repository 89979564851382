import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/institucional/home/Home.vue'
import RedeBaseDeDados from '../views/institucional/rede/RedeBaseDeDados.vue'
import BaseDadosNacional from '../views/institucional/base-dados-nacional/BaseDadosNacional.vue'
import PoliticaDeDados from '../views/institucional/politica-dados/PoliticaDeDados.vue'
import ComiteCientifico from '../views/institucional/comite-cientifico/ComiteCientifico.vue'
import Tutorial from '../views/institucional/tutorial/Tutorial.vue'
import EspeciesInstitucional from '../views/institucional/especies/EspeciesInstitucional.vue'
import PetsInstitucional from '../views/institucional/pets/PetsInstitucional.vue'
import PeixesAquiculturaInstitucional from '../views/institucional/peixes-aquicultura/PeixesAquiculturaInstitucional.vue'
import PlantasForrageirasInstitucional from '../views/institucional/plantas-forrageiras/PlantasForrageirasInstitucional.vue'
import PlantasOrnamentaisInstitucional from '../views/institucional/plantas-ornamentais/PlantasOrnamentaisInstitucional.vue'
import ColaboradoresInstitucional from '../views/institucional/colaboradores/ColaboradoresInstitucional.vue'
import ReferenciasInstitucional from '../views/institucional/referencias/ReferenciasInstitucional.vue'
import ProjetosInstitucional from '../views/institucional/projetos/ProjetosInstitucional.vue'
import ContatoInstitucional from '../views/institucional/contato/ContatoInstitucional.vue'
import ColaboreDados from '../views/institucional/colabore/ColaboreDados.vue'
import Login from '../views/institucional/login/Login.vue'
import AdminHome from '../views/admin/home/Home.vue'
import AdminColaboradores from '../views/admin/colaboradores/Colaboradores.vue'
import AdminColaboradoresForm from '../views/admin/colaboradores/ColaboradoresForm.vue'
import AdminEspecies from '../views/admin/especies/Especies.vue'
import AdminEspeciesForm from '../views/admin/especies/EspeciesForm.vue'
import AdminProjetos from '../views/admin/projetos/Projetos.vue'
import AdminProjetosForm from '../views/admin/projetos/ProjetosForm.vue'
import AdminReferencias from '../views/admin/referencias/Referencias.vue'
import AdminReferenciasForm from '../views/admin/referencias/ReferenciasForm.vue'
import AdminTaxonomia from '../views/admin/taxonomia/Taxonomia.vue'
import AdminUsuarios from '../views/admin/usuarios/Usuarios.vue'
import AdminUsuariosForm from '../views/admin/usuarios/UsuariosForm.vue'
import AdminOcorrencias from '../views/admin/ocorrencias/Ocorrencias.vue'
import AdminOcorrenciasForm from '../views/admin/ocorrencias/OcorrenciasForm.vue'
import FichasMMA from '../views/admin/exportarDados/fichasMMA/FichasMMA.vue'
import FichasMMA2 from '../views/admin/exportarDados/fichasMMA2/FichasMMA2.vue'
import Fichas from '../views/admin/exportarDados/fichas/Fichas.vue'
import Consultas from '../views/admin/exportarDados/consultas/Consultas.vue'
import AdminOcorrenciasApp from '../views/admin/ocorrenciasApp/OcorrenciasApp.vue'
import AdminOcorrenciasAppForm from '../views/admin/ocorrenciasApp/OcorrenciasAppForm.vue'

import store from '@/store'

Vue.use(VueRouter)
const basic = 'basic'
const admin = 'administrator'
const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/base-dados-nacional',
    name: 'BaseDadosNacional',
    component: BaseDadosNacional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/rede-base-de-dados',
    name: 'RedeBaseDeDados',
    component: RedeBaseDeDados,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/politica-de-dados',
    name: 'PoliticaDeDados',
    component: PoliticaDeDados,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/comite-cientifico',
    name: 'ComiteCientifico',
    component: ComiteCientifico,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: Tutorial,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/especies',
    name: 'EspeciesInstitucional',
    component: EspeciesInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/pets',
    name: 'PetsInstitucional',
    component: PetsInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/peixes-aquicultura',
    name: 'PeixesAquiculturaInstitucional',
    component: PeixesAquiculturaInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/plantas-forrageiras',
    name: 'PlantasForrageirasInstitucional',
    component: PlantasForrageirasInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/plantas-ornamentais',
    name: 'PlantasOrnamentaisInstitucional',
    component: PlantasOrnamentaisInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/colabore',
    name: 'ColaboreDados',
    component: ColaboreDados,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/colaboradores',
    name: 'ColaboradoresInstitucional',
    component: ColaboradoresInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/referencias',
    name: 'ReferenciasInstitucional',
    component: ReferenciasInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/projetos',
    name: 'ProjetosInstitucional',
    component: ProjetosInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/contato',
    name: 'ContatoInstitucional',
    component: ContatoInstitucional,
    meta: {
      appAdmin: false
    }
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: AdminHome,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/colaboradores',
    name: 'AdminColaboradores',
    component: AdminColaboradores,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/colaboradores/:id',
    name: 'AdminColaboradoresForm',
    component: AdminColaboradoresForm,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/especies',
    name: 'AdminEspecies',
    component: AdminEspecies,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/especies/:id',
    name: 'AdminEspeciesForm',
    component: AdminEspeciesForm,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/projetos',
    name: 'AdminProjetos',
    component: AdminProjetos,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/projetos/:id',
    name: 'AdminProjetosForm',
    component: AdminProjetosForm,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/ocorrencias',
    name: 'AdminOcorrencias',
    component: AdminOcorrencias,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/ocorrencias/:id',
    name: 'AdminOcorrenciasForm',
    component: AdminOcorrenciasForm,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/referencias',
    name: 'AdminReferencias',
    component: AdminReferencias,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/referencias/:id',
    name: 'AdminReferenciasForm',
    component: AdminReferenciasForm,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/taxonomia',
    name: 'AdminTaxonomia',
    component: AdminTaxonomia,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/usuarios',
    name: 'AdminUsuarios',
    component: AdminUsuarios,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/usuarios/:id',
    name: 'AdminUsuariosForm',
    component: AdminUsuariosForm,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/exportar/fichas-mma',
    name: 'FichasMMA',
    component: FichasMMA,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/exportar/fichas-mma-v2',
    name: 'FichasMMA2',
    component: FichasMMA2,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/exportar/fichas',
    name: 'Fichas',
    component: Fichas,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/exportar/consultas',
    name: 'Consultas',
    component: Consultas,
    meta: {
      appAdmin: true,
      role: admin
    }
  },
  {
    path: '/admin/ocorrencias-app',
    name: 'AdminOcorrenciasApp',
    component: AdminOcorrenciasApp,
    meta: {
      appAdmin: true,
      role: basic
    }
  },
  {
    path: '/admin/ocorrencias-app/:id',
    name: 'AdminOcorrenciasAppForm',
    component: AdminOcorrenciasAppForm,
    meta: {
      appAdmin: true,
      role: basic
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const defaultRouter = '/login'
  const requiresAuth = to.matched.some(record => record.meta.appAdmin)
  const role = to.meta.role
  store.commit('preload', false)
  store.state.beforeRouter = from.name
  if (!((from.name === 'AdminColaboradoresForm' && to.name === 'AdminColaboradores') ||
    (from.name === 'AdminEspeciesForm' && to.name === 'AdminEspecies') ||
    (from.name === 'AdminProjetosForm' && to.name === 'AdminProjetos') ||
    (from.name === 'AdminOcorrenciasForm' && to.name === 'AdminOcorrencias') ||
    (from.name === 'AdminOcorrenciasAppForm' && to.name === 'AdminOcorrenciasApp') ||
    (from.name === 'AdminReferenciasForm' && to.name === 'AdminReferencias') ||
    (from.name === 'AdminUsuariosForm' && to.name === 'AdminUsuarios')) &&
    !((from.name === 'AdminColaboradores' && to.name === 'AdminColaboradoresForm') ||
    (from.name === 'AdminEspecies' && to.name === 'AdminEspeciesForm') ||
    (from.name === 'AdminProjetos' && to.name === 'AdminProjetosForm') ||
    (from.name === 'AdminOcorrencias' && to.name === 'AdminOcorrenciasForm') ||
    (from.name === 'AdminOcorrenciasApp' && to.name === 'AdminOcorrenciasAppForm') ||
    (from.name === 'AdminReferencias' && to.name === 'AdminReferenciasForm') ||
    (from.name === 'AdminUsuarios' && to.name === 'AdminUsuariosForm'))) {
    store.state.currentPage = 1
    store.state.perPage = 10
    store.commit('clearFormSearch')
  }
  if (requiresAuth) {
    if (!store.state.user || !store.state.user.token) {
      next(defaultRouter)
    } else if (role === admin && store.state.user && store.state.user.perfilUser === basic) {
      next(defaultRouter)
    } else {
      store.state.logged = true
      next()
    }
  } else next()
})

export default router
